import { createSlice } from '@reduxjs/toolkit';
import { adminLoginAction } from './AuthActions';

import { ADMIN_AUTH } from './AuthConstants';

import TInitAuthStates from './AuthTypes';

const initialState: TInitAuthStates = {
  isAuth: null,
};

const adminAuthSlice = createSlice({
  name: ADMIN_AUTH,
  initialState,
  reducers: {},
  extraReducers: {
    [adminLoginAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [adminLoginAction.fulfilled.type]: (state) => {
      state.isAuth = true;
    },
    [adminLoginAction.rejected.type]: (state) => {
      state.isAuth = false;
    },
  },
});

export default adminAuthSlice.reducer;
