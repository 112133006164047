import * as Yup from 'yup';

const validatePhoneNumber = (value: string | undefined) => {
  let correctFlag: boolean = false;

  if (value) {
    const isHyphenSymbol = value?.indexOf('-') > -1;

    if (isHyphenSymbol) {
      const splitValues = value.split('-');

      splitValues.forEach((spliter) => {
        correctFlag = true;
        if (spliter === '' || isNaN(+spliter)) correctFlag = false;
      });
    } else {
      if (!isNaN(+value)) correctFlag = true;
    }
  } else correctFlag = true;

  return correctFlag;
};

const ProfileSchema: any = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  industry: Yup.string().required('Industry is required'),
  email: Yup.string().required('Email is required'),
  phoneNumber: Yup.string().test((value) => validatePhoneNumber(value)),
  currentCompany: Yup.string().required('Company is required'),
  currentPosition: Yup.string().required('Job Title is required'),
});

export default ProfileSchema;
