import SearchRoot from './Search.styled';

import TSearch from './types';

const borderStyles = {
  border: '1px solid #000',
  borderRadius: '4px',
  outline: 'none',
  '&:hover, & .MuiOutlinedInput-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

const Search = ({
  value,
  onChange,
  name,
  placeholder,
  styles,
  border,
}: TSearch): JSX.Element => {
  const searchStyles = border ? { ...styles, ...borderStyles } : { ...styles };

  return (
    <SearchRoot
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder || ''}
      sx={searchStyles}
      fullWidth
    />
  );
};

export default Search;
