/* eslint-disable react-hooks/exhaustive-deps */
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';

import { LoadTripsModule } from './modules';

import FollowButton from 'features/home/components/followButton/FollowButton';
import { MediaNavbar, MediaTripActions } from './mediaComponents';
import { NewTripPopup, useMap, useSidebar } from 'features/home';
import Flexbox from 'components/flexbox/Flexbox';
import { ModalWindow } from 'components/window';
import { Heading } from 'components/typography';

import { USER_LOGIN_ROUTE } from 'routes/core/RouterConstant';
import LocaleStorage from 'api/LocaleStorage';
import { UNREAD } from 'core/roles';
import AppConfig from 'config';

import { useProfile } from 'features/profile';

interface IHomeLayout {
  children: JSX.Element | null;
  isFiltering: boolean;
  isTripMapping: boolean;
  isReturnHome?: boolean;
}

const IconStyles: CSSProperties = {
  width: '50px',
  height: '50px',
  color: '#2b669f',
};

const HomeLayout = ({
  children,
  isFiltering,
  isTripMapping,
  isReturnHome = false,
}: IHomeLayout) => {
  const {
    dispatcherHandleSidebar,
    dispatcherSetRequest,
    openAddTrip,
    dispatcherHandleAddTrip,
  } = useSidebar();
  const { dispatcherGetNotification, fetchNotifications } = useMap();
  const navigate: NavigateFunction = useNavigate();
  const [openTripWindow, setOpenTripWindow] = useState<boolean>(false);
  const [openSignWindowState, setOpenSignWindowState] =
    useState<boolean>(false);
  const { info } = useProfile();
  const [notifications, setNotifications] = useState([]);

  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const isGuest = isAuth === UNREAD;

  const shadowNavbarStyles: string =
    'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.4))';

  const handleTripListOpen = (): void => {
    dispatcherHandleSidebar(true);
    dispatcherSetRequest(true);
  };

  const handleSignWindow = () => {
    setOpenSignWindowState((prevState: boolean) => !prevState);
  };

  const handleAddTripWindow = (): void => {
    if (isGuest) {
      handleSignWindow();
    } else {
      dispatcherHandleAddTrip(false);
      setOpenTripWindow((prevState: boolean) => !prevState);
    }
  };

  const handleSignRedirect = () => {
    navigate(USER_LOGIN_ROUTE);
  };

  const fetchGetNotifications = (config: any) => {
    dispatcherGetNotification(config)
      .unwrap()
      .then((response: AxiosResponse) => {
        setNotifications(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const renderShareContent: JSX.Element = (
    <Flexbox
      flexDirection="column"
      alignItems="center"
      width="100%"
      gap="1rem 0"
    >
      <SentimentDissatisfiedIcon sx={IconStyles} />
      <Heading
        as="h3"
        content="Sorry! You must be logged in."
        textAlign="center"
        sx={{ color: '#fff' }}
      />
      <Heading
        as="h5"
        content="Please sign in to businesstrip.ai for continue"
        textAlign="center"
        sx={{ color: '#2b669f' }}
      />
    </Flexbox>
  );

  const renderSignButtons: JSX.Element = (
    <Fragment>
      <Flexbox flexDirection="column" gap="0.5rem 0" width="100%" mt="30px">
        <Flexbox justifyContent="center" gap="4rem">
          <FollowButton success label="Cancel" onClick={handleSignWindow} />
          <FollowButton onClick={handleSignRedirect} label="Sign In" />
        </Flexbox>
      </Flexbox>
    </Fragment>
  );

  const renderSignPopup: JSX.Element = (
    <ModalWindow
      title=""
      open={openSignWindowState}
      onClose={handleSignWindow}
      onSubmit={handleSignRedirect}
      fields={renderShareContent}
      buttons={renderSignButtons}
    />
  );

  const renderNewTripPopup: JSX.Element = (
    <NewTripPopup
      title="Create New Trip"
      open={openTripWindow || openAddTrip}
      onClose={handleAddTripWindow}
    />
  );

  useEffect(() => {
    if (openAddTrip) setOpenTripWindow(true);
  }, [openAddTrip]);

  useEffect(() => {
    if (info) {
      const config = {
        params: {
          userId: info?.data?.userId,
        },
      };

      fetchGetNotifications(config);
    }

    const fetchInterval = setInterval(() => {
      if (info) {
        const config = {
          params: {
            userId: info?.data?.userId,
          },
        };

        fetchGetNotifications(config);
      }
    }, 15000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, [info]);

  useEffect(() => {
    if (fetchNotifications) {
      const config = {
        params: {
          userId: info?.data?.userId,
        },
      };

      fetchGetNotifications(config);
    }
  }, [fetchNotifications]);

  return (
    <Fragment>
      <MediaNavbar
        filterStyle={shadowNavbarStyles}
        isReturnRender={isReturnHome}
        notifications={notifications}
        isFilterRender={isFiltering}
      />

      {isTripMapping && (
        <MediaTripActions
          onAddTrip={handleAddTripWindow}
          onOpenTrips={handleTripListOpen}
        />
      )}

      {children}

      {isTripMapping && <LoadTripsModule />}
      {renderNewTripPopup}
      {renderSignPopup}
    </Fragment>
  );
};

export default HomeLayout;
