import { createSlice } from '@reduxjs/toolkit';

import { SIDEBAR } from './SidebarContants';

import TInitState from './SidebarTypes';

const initialState: TInitState = {
  open: false,
  data: null,
  isRequest: false,
  openAddTrip: false,
};

const sidebarSlice = createSlice({
  name: SIDEBAR,
  initialState,
  reducers: {
    setOpenSidebar: (
      state: TInitState,
      action: { payload: boolean; type: string },
    ) => {
      state.open = action.payload;
    },
    setOpenAddTrip: (
      state: TInitState,
      action: { payload: boolean; type: string },
    ) => {
      state.openAddTrip = action.payload;
    },
    setDataSidebar: (
      state: TInitState,
      action: { payload: any; type: string },
    ) => {
      state.data = action.payload;
    },
    setIsRequest: (
      state: TInitState,
      action: { payload: boolean; type: string },
    ) => {
      state.isRequest = action.payload;
    },
  },
});

export const { setOpenSidebar, setDataSidebar, setIsRequest, setOpenAddTrip } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
