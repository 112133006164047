import React, { CSSProperties } from 'react';
import { alpha, Box } from '@mui/material';

import StatisticCardRoot, { StatisticBox } from './StatisticCard.styled';
import { Heading } from 'components/typography';

import TStatiscticCardProps from 'features/adminDashboard/models/StatisticCard';

const StatiscticCardComponent = ({ card }: TStatiscticCardProps) => {
  const { count, Icon, title, color } = card;

  const statisticBoxStyles: CSSProperties = {
    backgroundColor: alpha(color, 0.2),
  };

  const iconStyles: CSSProperties = {
    color,
  };

  return (
    <StatisticCardRoot>
      <StatisticBox sx={statisticBoxStyles}>
        <Icon sx={iconStyles}></Icon>
      </StatisticBox>

      <Box>
        <Heading
          as="h4"
          content={title}
          lineHeight="1.4"
          color="text.disabled"
        />
        <Heading as="h3" content={count} />
      </Box>
    </StatisticCardRoot>
  );
};

const StatiscticCard = React.memo(StatiscticCardComponent);
export default StatiscticCard;
