import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack } from '@mui/material';
import { useState } from 'react';

import { GridDataTablePagination } from 'components/dataTable/gridDataTable/GridDataTable.styled';
import DataSetItem from '../dataSetItem/DataSetItem';
import { Heading } from 'components/typography';
import Flexbox from 'components/flexbox/Flexbox';

interface IDataSetList {
  data: any;
  pageNo: number;
  pageCount: any;
  onPageChange: any;
  title: string;
}

const DataSetList = ({
  data,
  pageCount,
  pageNo,
  onPageChange,
  title,
}: IDataSetList): JSX.Element => {
  const [openMore, setOpenMore] = useState<boolean>(false);

  const handleChange = (_event: any, currentPageNo: any) => {
    onPageChange && onPageChange(currentPageNo - 1);
  };

  const handleMore = () => {
    setOpenMore((prevState: boolean) => !prevState);
  };

  const renderTablePagination: JSX.Element = (
    <Stack alignItems="right" marginY="1rem">
      <GridDataTablePagination
        shape="rounded"
        onChange={handleChange}
        count={pageCount}
        page={pageNo}
        defaultPage={1}
      />
    </Stack>
  );

  const renderContent = (
    <Box>
      {data?.map((item: any, index: number) => (
        <DataSetItem data={item} key={index} />
      ))}
      {renderTablePagination}
    </Box>
  );

  return (
    <Box mt="20px">
      <Flexbox alignItems="center" mb="10px">
        <Heading
          as="h4"
          content={title}
          fontWeight="400"
          fontSize="16px"
          color="#fff"
        />
        {openMore ? (
          <KeyboardArrowUpIcon
            sx={{ color: '#fff', cursor: 'pointer' }}
            onClick={handleMore}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={{ color: '#fff', cursor: 'pointer' }}
            onClick={handleMore}
          />
        )}
      </Flexbox>
      {openMore && renderContent}
    </Box>
  );
};

export default DataSetList;
