import { CSSProperties } from 'react';

import Flexbox from 'components/flexbox/Flexbox';
import { Heading, Small } from 'components/typography';

import TInfoProps from 'features/profile/models/Info';

const SmallLabelStyles: CSSProperties = {
  display: 'inline-block',
  marginLeft: '5px',
  fontWeight: 400,
  fontSize: '16px',
  color: '#96E6B3',
};

const SmallStyles: CSSProperties = {
  display: 'inline-block',
  marginLeft: '5px',
  paddingLeft: '5px',
  fontWeight: 400,
  fontSize: '16px',
};

const Info = ({
  label,
  value,
  iconComponent,
  link,
  overrideStyles,
  mt = '20px',
}: TInfoProps): JSX.Element => {
  const headingContent: JSX.Element = link ? (
    <>
      <Small sx={{ ...SmallLabelStyles, ...overrideStyles }}>{label}: </Small>
      {value && (
        <a
          href={`https://${value}`}
          style={{
            ...SmallStyles,
            textDecoration: 'underline',
            ...overrideStyles,
          }}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      )}
    </>
  ) : (
    <>
      <Small sx={{ ...SmallLabelStyles, ...overrideStyles }}>{label}: </Small>
      <Small sx={{ ...SmallStyles, ...overrideStyles }}>{value}</Small>
    </>
  );

  return (
    <Flexbox alignItems="center" mt={mt}>
      {iconComponent && iconComponent}
      <Heading as="h4" content={headingContent} />
    </Flexbox>
  );
};

export default Info;
