import Fontbox from '../fontbox/Fontbox';

import THeading from './types';

const Heading = ({ as, content, ...props }: THeading): JSX.Element => {
  if (as === 'h2') {
    return (
      <Fontbox component="h2" fontSize="1.5rem" fontWeight="600" {...props}>
        {content}
      </Fontbox>
    );
  } else if (as === 'h3') {
    return (
      <Fontbox component="h3" fontSize="1.25rem" fontWeight="600" {...props}>
        {content}
      </Fontbox>
    );
  } else if (as === 'h4') {
    return (
      <Fontbox component="h4" fontSize="1rem" fontWeight="600" {...props}>
        {content}
      </Fontbox>
    );
  } else if (as === 'h5') {
    return (
      <Fontbox component="h5" fontSize="0.75rem" fontWeight="600" {...props}>
        {content}
      </Fontbox>
    );
  }

  return (
    <Fontbox component="h1" fontSize="1.75rem" fontWeight="600" {...props}>
      {content}
    </Fontbox>
  );
};

export default Heading;
