import { StyledEngineProvider } from '@mui/material/styles';
import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import React from 'react';

import routes from 'routes/router';

const toasterOptions = {
  style: {
    fontWeight: 500,
    fontFamily: "'Montserrat', sans-serif",
    maxWidth: '100%',
  } as React.CSSProperties,
};

const App = (): JSX.Element => {
  const pages = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <Toaster toastOptions={toasterOptions} />
      {pages}
    </StyledEngineProvider>
  );
};

export default App;
