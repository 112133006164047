import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const API_URL: string = 'https://api.pexels.com/v1/search';
const API_KEY: string = 'ohgBD3ZIrYvugDBmGciwKzPuENveSctKBd9VpOfMCSePuXiCZzpksLyO';

const pexelInstance = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
  });

  pexelInstance.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
      const accessToken = API_KEY;
      if (config && config.headers ) {
        config.headers.Authorization = accessToken;
      }
      return config;
    },
    (error: any) => {
      const responseError = error.response;
      return Promise.reject(
        (responseError && responseError.data) || 'Something went wrong',
      );
    },
  );
  
  pexelInstance.interceptors.response.use(
    (response: AxiosResponse<any, any>) => {
      return response;
    },
    (error: any) => {
     
  
      return Promise.reject(error);
    })

    const apiGet = async (queryUrl: string) =>  {
        try {
          let response: AxiosResponse<any, any> | null = null;
          response = await pexelInstance.get(API_URL + queryUrl);
          return response;
        } catch (error: unknown) {
          return error;
        }
      }
      
export default apiGet