import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from 'store/configureStore';
import adminProfileAction, {
  adminChangeStatusActions,
  adminDownloadReportAction,
  adminGetTripsAction,
  adminGetUserProfile,
  adminHideTripAction,
  adminRemoveTripAction,
} from './AdminDashboardActions';
import { setTripsFetching } from './AdminDashboardSlice';

const useAdminDashboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const { adminInfo, fetchTrips } = useSelector(
    (state: RootState) => state.adminDashboard,
  );

  const dispatcherAdminProfile = (config: any) =>
    dispatch(adminProfileAction(config));

  const dispatcherAdminHideTrip = (config: any) =>
    dispatch(adminHideTripAction(config));

  const dispatcherAdminRemoveTrip = (config: any) =>
    dispatch(adminRemoveTripAction(config));

  const dispatcherAdminGetTrips = (config: any) =>
    dispatch(adminGetTripsAction(config));

  const dispatcherAdminReport = (config: any) =>
    dispatch(adminDownloadReportAction(config));

  const dispatcherGetUserProfile = (payload: any) =>
    dispatch(adminGetUserProfile(payload));

  const dispatcherSetTripsFetching = (state: boolean) =>
    dispatch(setTripsFetching(state));

  const dispatcherAdminChangeStatus = (config: any) =>
    dispatch(adminChangeStatusActions(config));

  return {
    dispatcherAdminProfile,
    dispatcherAdminHideTrip,
    dispatcherAdminRemoveTrip,
    dispatcherAdminGetTrips,
    dispatcherAdminReport,
    dispatcherGetUserProfile,
    dispatcherSetTripsFetching,
    dispatcherAdminChangeStatus,
    fetchTrips,
    adminInfo,
  };
};

export default useAdminDashboard;
