import * as Yup from 'yup';

const NewTripSchema = Yup.object().shape({
  name: Yup.string().required('Trip Name is required'),
  industries: Yup.mixed().required('Industry is required'),
  conferenceName: Yup.string().required('Activity Name is required'),
  dateFrom: Yup.string().required('Dates is required'),
  dateTo: Yup.string().required('Dates is required'),
});

export default NewTripSchema;
