import { styled } from '@mui/material';

const StyledNotificateCounter = styled('div')(() => ({
  position: 'absolute',
  backgroundColor: '#c72331',
  borderRadius: '50%',
  fontSize: '13px',
  fontWeight: 400,
  width: '17px',
  color: '#fff',
  height: '17px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '-4px',
  right: '-4px',
}));

export default StyledNotificateCounter;
