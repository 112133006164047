const ACTIVE_STATUS: string = 'ACTIVE';
const HIDE_STATUS: string = 'HIDE';
const DELETE_STATUS: string = 'DELETE';

const SORT_DICTIONARY = {
  newest: 'NEWEST',
  oldest: 'OLDEST',
  asc: 'ALPHABETICAL',
  desc: 'ALPHABETICAL_DESCENDING',
};

const TRIP_TYPE_DICTIONARY = {
  conference: 'CONFERENCE',
  other: 'OTHER_ACTIVITY',
};

export {
  ACTIVE_STATUS,
  HIDE_STATUS,
  DELETE_STATUS,
  SORT_DICTIONARY,
  TRIP_TYPE_DICTIONARY,
};
