/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError, AxiosResponse } from 'axios';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';

import { MediaListBar } from 'layouts/homeLayout/mediaComponents';
import { ListFilters } from 'layouts/homeLayout/components';

import useFilterFetching from 'hooks/useFilterFetching';
import useMediaMatches from 'hooks/useMediaMatches';
import { useMap, useSidebar } from 'features/home';
import useGeocoding from 'hooks/useGeocoding';

import { SORT_DICTIONARY, TRIP_TYPE_DICTIONARY } from 'core/status';
import INITIAL_TRIP_VALUES from 'features/home/core/initialValues';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

const LoadTripsModule = (): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const { open, data, dispatcherSetRequest, isRequest } = useSidebar();
  const { dispatcherGetTripBy, searchAddress } = useMap();

  const InitValues = {
    conferenceName: INITIAL_TRIP_VALUES.conferenceName,
    dateFrom: INITIAL_TRIP_VALUES.dateFrom,
    dateTo: INITIAL_TRIP_VALUES.dateTo,
    address: searchAddress?.address || INITIAL_TRIP_VALUES.address,
    monthCount: 1,
  };

  const [initFormik, setInitFormik] = useState<Record<string, any>>(InitValues);
  const [openFiltersState, setOpenFiltersState] = useState<boolean>(false);
  const [conferenceState, setConferenceState] = useState<string>(
    TRIP_TYPE_DICTIONARY.conference,
  );
  const [listState, setListState] = useState<any>(null);

  const addressRef: any = useRef(null);
  const { getCoord } = useGeocoding();

  const fetcherTripList = (parameters: any) => {
    return dispatcherGetTripBy(parameters)
      .unwrap()
      .then((response: AxiosResponse) => {
        const responseData = response?.data;
        const pagesCount: number = responseData?.pages;
        const currentPage: number = responseData?.page;
        const responseTrips: Array<Record<string, any>> = responseData?.trips;

        const notFirstPage: boolean = currentPage > 0;
        const isNotLastPage: boolean = currentPage < pagesCount;

        if (listState && isNotLastPage && notFirstPage)
          setListState([...listState, ...responseTrips]);
        else setListState(responseTrips);

        setListState(responseData);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error('Failed!');
        }
      });
  };

  const { fetcher, savesFilters, handleFiltering, pageNo, COUNT_LINE } =
    useFilterFetching(fetcherTripList);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initFormik,
    enableReinitialize: true,
    onSubmit: (values) => {
      const address = addressRef.current.value;
      const geoAddress = getCoord(address);

      geoAddress
        .then((geo: any) => {
          values.address = address;

          const formattedData = geo?.formatterAddress;
          let geoAddressValue = null;

          if (formattedData) {
            geoAddressValue = formattedData;
          }

          const payload = {
            ...values,
            industries: [],
            address: geoAddressValue || values.address,
            geoData: geo,
          };
          handleFiltering(0, payload, { sort: SORT_DICTIONARY.newest });
          setOpenFiltersState(false);
        })
        .catch(() => {
          const payload = {
            ...values,
            industries: [],
          };
          handleFiltering(0, payload, { sort: SORT_DICTIONARY.newest });
          setOpenFiltersState(false);
        });
    },
  });

  const handleLoadMore = (): void => {
    const searchObj = {
      sort: SORT_DICTIONARY.newest,
    };

    let addressFilter =
      typeof searchAddress === 'object'
        ? { ...savesFilters, geoData: searchAddress?.geo }
        : { ...savesFilters, geoData: null };
    fetcher(pageNo + 1, COUNT_LINE, addressFilter, searchObj);
  };

  const handleFilterState = (): void => {
    setOpenFiltersState((prevState: boolean) => !prevState);
  };

  const handleChangeConferenceState = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value;
    setConferenceState(value);
  };

  useEffect(() => {
    setInitFormik(InitValues);
  }, [open, searchAddress]);

  useEffect(() => {
    if (mobileSM) {
      dispatcherSetRequest(true);
    }
  }, []);

  useEffect(() => {
    const searchObj = {
      sort: SORT_DICTIONARY.newest,
    };

    let addressFilter =
      typeof searchAddress === 'object'
        ? { ...savesFilters, geoData: searchAddress?.geo }
        : { ...savesFilters, geoData: null };

    if (isRequest || mobileSM) {
      console.log('searchaddress: ', searchAddress);
      fetcher(0, COUNT_LINE, addressFilter, searchObj);
    }
  }, [isRequest, searchAddress]);

  return isRequest ? (
    <MediaListBar
      isListRequest={isRequest}
      data={listState?.trips}
      pageNumber={pageNo}
      onMore={handleLoadMore}
      onFiltering={handleFilterState}
      injectFilters={
        <ListFilters
          title="Filters"
          open={openFiltersState}
          onClose={handleFilterState}
          values={values}
          handleChange={handleChange}
          onSubmit={handleSubmit}
          addressRef={addressRef}
          setFieldValue={setFieldValue}
          conferenceState={conferenceState}
          handleChangeConferenceState={handleChangeConferenceState}
        />
      }
    />
  ) : (
    <MediaListBar
      isListRequest={isRequest}
      data={data?.trips}
      pageNumber={pageNo}
      onMore={handleLoadMore}
    />
  );
};

export default LoadTripsModule;
