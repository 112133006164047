import CallIcon from '@mui/icons-material/Call';
import { Mail } from '@mui/icons-material';
import React, { Fragment } from 'react';
import { Box } from '@mui/material';

import FollowButton from 'features/home/components/followButton/FollowButton';
import {
  DetailHeader,
  Text,
} from 'features/trip/components/tripLayout/TripLayout';
import ImageAvatar from '../imageAvatar/ImageAvatar';
import Flexbox from 'components/flexbox/Flexbox';
import HeroRoot from './Hero.styled';

import THeroProps from 'features/profile/models/Hero';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import AppConfig from 'config';

import useMediaMatches from 'hooks/useMediaMatches';

import LinkedIcon from 'assets/icons/LinkedIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import MobileImageAvatar from '../mobileImageAvatar/MobileImageAvatar';
import { useNavigate } from 'react-router-dom';
import ConnectButton from 'components/connectButton/ConnectButton';

const HeroComponent = ({
  data,
  onEditProfile,
  isRender,
  linked,
  isPreview = false,
  isUser,
  renderImage = true,
}: THeroProps): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const navigate = useNavigate();
  const isAdminProfile = data?.name === 'Admin';

  /**
   * *@Renders functions
   */
  const renderAvatar: JSX.Element = (
    <ImageAvatar
      image={data.photoUrl}
      name={data.name}
      isRender={isRender}
      isUser={isUser}
    />
  );

  const handleProfile = () => {
    const owner = data?.userId;
    const url = `/user/${owner}`;
    navigate(url);
  };

  const renderStatus: JSX.Element = (
    <Fragment>
      <Text
        sx={{
          fontSize: '20px',
          fontWeight: 500,
          marginTop: '16px',
        }}
      >
        {data?.name}
      </Text>
      <Text>{data?.currentPosition}</Text>
    </Fragment>
  );

  const handleLinkedin = () => {
    const url = `http://${data?.linkedProfileLink}` || '';
    window.open(url, '_blank');
  };

  const renderInformation: JSX.Element = !isAdminProfile ? (
    <Box mt={2}>
      <Flexbox alignItems="center" gap="6px" mb="5px">
        <InfoIcon />
        <Text>
          {AppConfig.PROFILE.FIELDS.INDUSTRY}: {data.industry}
        </Text>
      </Flexbox>

      <Flexbox alignItems="center" gap="6px" mb="5px">
        <Mail sx={{ width: '15px', height: '15px' }} />
        <Text>
          {AppConfig.PROFILE.FIELDS.EMAIL}: {data.email}
        </Text>
      </Flexbox>

      <Flexbox alignItems="center" gap="6px" mb="5px">
        <CallIcon sx={{ width: '15px', height: '15px' }} />
        <Text>
          {AppConfig.PROFILE.FIELDS.PHONE}: {data.phoneNumber}
        </Text>
      </Flexbox>

      <Flexbox alignItems="center" gap="6px" mb="5px">
        <InfoIcon />
        <Text>
          {AppConfig.PROFILE.FIELDS.COMPANY}: {data.currentCompany}
        </Text>
      </Flexbox>

      {data.linkedProfileLink && (
        <Flexbox alignItems="center" gap="10px">
          <div
            style={{ cursor: 'pointer', height: '26px' }}
            onClick={handleLinkedin}
          >
            <LinkedIcon />
          </div>
          <ConnectButton label="Connect" onClick={handleLinkedin} />
        </Flexbox>
      )}
    </Box>
  ) : (
    <Box mt="10px">
      <div style={{ cursor: 'pointer' }} onClick={handleLinkedin}>
        <LinkedIcon />
      </div>
    </Box>
  );

  const renderEditButton: JSX.Element | null = isRender ? (
    <FollowButton
      label="Edit"
      onClick={onEditProfile}
      overrideStyles={{
        width: '140px',
        backgroundColor: 'transparent',
        border: '2px solid #E8A442',
      }}
    />
  ) : null;

  const renderDesktopVersion: JSX.Element = (
    <HeroRoot>
      <Flexbox alignItems="flex-start" justifyContent="space-between">
        <Flexbox alignItems="flex-start">
          {renderImage && renderAvatar}
          <Box ml={6}>
            {renderStatus}
            {renderInformation}
          </Box>
        </Flexbox>
        {renderEditButton}
      </Flexbox>
    </HeroRoot>
  );

  const renderMobileVersion: JSX.Element = (
    <Flexbox flexDirection="column">
      {renderImage && (
        <MobileImageAvatar
          image={data.photoUrl}
          name={data.name}
          isRender={isRender || !isPreview}
          isUser={isUser}
        />
      )}

      <Flexbox justifyContent="space-between" alignItems="flex-start" mt="10px">
        <Box>
          <Text
            onClick={handleProfile}
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              textDecoration: 'underline',
            }}
          >
            {data?.name}
          </Text>
          <Text>{data?.currentPosition}</Text>
        </Box>

        <Flexbox alignItems="center" gap="10px">
          <div
            style={{ cursor: 'pointer', height: '26px' }}
            onClick={handleLinkedin}
          >
            <LinkedIcon />
          </div>
          <ConnectButton label="Connect" onClick={handleLinkedin} />
        </Flexbox>
      </Flexbox>

      <Box>
        <Flexbox justifyContent="center" mt="20px">
          <Flexbox
            alignItems="center"
            flexDirection="column"
            gap="6px"
            mb="5px"
            width="50%"
          >
            <DetailHeader>Industry</DetailHeader>
            <Text>{data.industry}</Text>
          </Flexbox>
          <Flexbox
            alignItems="center"
            flexDirection="column"
            gap="6px"
            mb="5px"
            width="50%"
          >
            <DetailHeader>Company</DetailHeader>
            <Text>{data.currentCompany}</Text>
          </Flexbox>
        </Flexbox>

        <Flexbox justifyContent="center" mt="20px">
          <Flexbox
            alignItems="center"
            flexDirection="column"
            gap="6px"
            mb="5px"
            width="50%"
          >
            <DetailHeader>Mail</DetailHeader>
            <Text>{data.email}</Text>
          </Flexbox>

          {data.phoneNumber && (
            <Flexbox
              alignItems="center"
              flexDirection="column"
              gap="6px"
              mb="5px"
              width="50%"
            >
              <DetailHeader>Phone</DetailHeader>
              <Text>{data.phoneNumber}</Text>
            </Flexbox>
          )}
        </Flexbox>
      </Box>
    </Flexbox>
  );

  return mobileSM ? renderMobileVersion : renderDesktopVersion;
};

const Hero = React.memo(HeroComponent);
export default Hero;
