import { SvgIcon } from '@mui/material';

const PeopleIcon = (props: any): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4482 15.3184C21.0466 16.4034 22.1666 17.8734 22.1666 19.8334V23.3334H25.6666C26.3082 23.3334 26.8332 22.8084 26.8332 22.1667V19.8334C26.8332 17.29 22.6682 15.785 19.4482 15.3184Z"
        fill="currentColor"
      />
      <path
        d="M10.4997 13.9993C13.077 13.9993 15.1663 11.91 15.1663 9.33268C15.1663 6.75535 13.077 4.66602 10.4997 4.66602C7.92235 4.66602 5.83301 6.75535 5.83301 9.33268C5.83301 11.91 7.92235 13.9993 10.4997 13.9993Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4999 13.9993C20.0782 13.9993 22.1666 11.911 22.1666 9.33268C22.1666 6.75435 20.0782 4.66602 17.4999 4.66602C16.9516 4.66602 16.4382 4.78268 15.9482 4.94602C16.9166 6.14768 17.4999 7.67602 17.4999 9.33268C17.4999 10.9893 16.9166 12.5177 15.9482 13.7193C16.4382 13.8827 16.9516 13.9993 17.4999 13.9993Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4998 15.166C7.38484 15.166 1.1665 16.7293 1.1665 19.8327V22.166C1.1665 22.8077 1.6915 23.3327 2.33317 23.3327H18.6665C19.3082 23.3327 19.8332 22.8077 19.8332 22.166V19.8327C19.8332 16.7293 13.6148 15.166 10.4998 15.166Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default PeopleIcon;
