const HOME_ROUTE = '/';

const USER_LOGIN_ROUTE = '/login';
const USER_CONFIRMATION_ROUTE = '/confirmation';
const USER_LINKEDIN_LOGIN_ROUTE = '/callback';

const ADMIN_LOGIN_ROUTE = '/admin/login';
const ADMIN_DASHBOARD_ROUTE = '/admin/dashboard';

const PROFILE_ROUTE = '/profile/user/:id';
const PROFILE_PREVIEW_ROUTE = '/user/:id';
const PROFILE_WID_ROUTE = '/profile/user/';

// const ATTENDEES_ROUTE = '/trip/:id';
const TRIP_ROUTE = '/trip/:id';

export {
  HOME_ROUTE,
  USER_LOGIN_ROUTE,
  USER_CONFIRMATION_ROUTE,
  USER_LINKEDIN_LOGIN_ROUTE,
  ADMIN_LOGIN_ROUTE,
  PROFILE_PREVIEW_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  PROFILE_ROUTE,
  PROFILE_WID_ROUTE,
  TRIP_ROUTE,
};
