import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { userConfirmationSlice } from 'features/userConfirmation';
import { adminDashboardSlice } from 'features/adminDashboard';
import { mapSlice, sidebarSlice } from 'features/home';
import { adminAuthSlice } from 'features/adminLogin';
import { userAuthSlice } from 'features/userLogin';
import { profileSlice } from 'features/profile';

const rootReducer = {
  userConfirmation: userConfirmationSlice,
  adminDashboard: adminDashboardSlice,
  adminAuth: adminAuthSlice,
  userAuth: userAuthSlice,
  sidebar: sidebarSlice,
  map: mapSlice,
  profile: profileSlice,
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
