import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from 'store/configureStore';
import userConfirmationAction from './ConfirmationActions';

const useUserConfirmation = () => {
  const dispatch: AppDispatch = useDispatch();
  const { success } = useSelector((state: RootState) => state.userConfirmation);

  const dispatcherConfirmation = (config: any) =>
    dispatch(userConfirmationAction(config));

  return {
    dispatcherConfirmation,
    success,
  };
};

export default useUserConfirmation;
