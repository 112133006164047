import { Box, Drawer, styled } from '@mui/material';
import { Clear } from '@mui/icons-material';
import ScrollBar from 'simplebar-react';

const ListSidebarRoot = styled(Drawer)(() => ({
  '& .MuiPaper-root': {
    width: 510,
    marginTop: '90px',
    backgroundColor: '#032620',
  },
})) as typeof Drawer;

const ClearIcon = styled(Clear)(() => ({
  width: '30px',
  height: '30px',
}));

const ScrollBarRoot = styled(ScrollBar)(() => ({
  overflow: 'auto',
  height: 'calc(100% - (90px + 80px))',
}));

const MobileListSidebarRoot = styled(Box)(() => ({
  backgroundColor: '#032620',
  padding: '30px 20px',
  position: 'absolute',
  minHeight: '50%',
  top: '62%',
  width: '100vw',
  bottom: 0,
}));

export { ClearIcon, ScrollBarRoot, MobileListSidebarRoot };
export default ListSidebarRoot;
