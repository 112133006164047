import { createSlice } from '@reduxjs/toolkit';

import { USER_AUTH } from './AuthConstants';

import TInitAuthStates from './AuthTypes';

const initialState: TInitAuthStates = {
  isAuth: null,
};

const userAuthSlice = createSlice({
  name: USER_AUTH,
  initialState,
  reducers: {},
  extraReducers: {},
});

export default userAuthSlice.reducer;
