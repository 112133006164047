import React from 'react';

import FollowButton from 'features/home/components/followButton/FollowButton';
import Flexbox from 'components/flexbox/Flexbox';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import IBaseMDLActions from './types';

import useMediaMatches from 'hooks/useMediaMatches';

const BaseModalActions = ({
  cancelLabel,
  submitLabel,
  cancelVariant = 'outlined',
  submitVariant = 'contained',
  cancelOnclick,
  submitOnclick,
}: IBaseMDLActions): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const renderMobileVersion = (
    <Flexbox flexDirection="column" gap="0.5rem 0" width="100%">
      <Flexbox flexDirection="column" justifyContent="space-between" gap="0px">
        <FollowButton
          className="submit-button"
          label={submitLabel}
          onClick={submitOnclick}
          overrideStyles={{ marginTop: '15px' }}
        />
        <FollowButton success label={cancelLabel} onClick={cancelOnclick} />
      </Flexbox>
    </Flexbox>
  );

  const renderDesktopVersion = (
    <Flexbox flexDirection="column" gap="0.5rem 0" width="100%">
      <Flexbox justifyContent="space-between" gap="20px">
        <FollowButton success label={cancelLabel} onClick={cancelOnclick} />
        <FollowButton
          className="submit-button"
          label={submitLabel}
          onClick={submitOnclick}
        />
      </Flexbox>
    </Flexbox>
  );

  return mobileSM ? renderMobileVersion : renderDesktopVersion;
};

export default BaseModalActions;
