import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useEffect, useState } from 'react';

import Flexbox from 'components/flexbox/Flexbox';

import apiGet from './api';
import { styled } from '@mui/material';

const StyledImage = styled('div')(({ img, active }) => ({
  borderRadius: '10px',
  border: active ? '1px solid yellow' : '1px solid transparent',
  width: '100%',
  cursor: 'pointer',
  height: '120px',
  transition: 'all .3s',
  backgroundImage: `url(${img})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  '&:hover': {
    border: '1px solid yellow',
  },
}));

export const ImagePexelModule = ({ location, onAddImage, currentImageUrl }) => {
  const [pexelImages, setPexelImages] = useState([]);
  const [page, setPage] = useState(1);

  const handleGetImages = () => {
    const currentPage = page + 1;
    apiGet(`?query=${location}&page=${currentPage}&per_page=3`).then(
      (response) => {
        const images = response?.data?.photos;
        setPexelImages(images);
        setPage(currentPage);
      },
    );
  };

  useEffect(() => {
    if (location) {
      apiGet(`?query=${location}&page=1&per_page=3`).then((response) => {
        const images = response?.data?.photos;
        setPexelImages(images);
      });
    }
  }, [location]);

  return (
    <Flexbox gap="10px" alignItems="center" mt="10px">
      {pexelImages.map((img) => (
        <StyledImage
          img={img?.src?.original}
          onClick={onAddImage(img)}
          active={currentImageUrl === img?.src?.original}
        />
      ))}
      {location && (
        <AddCircleOutlineIcon
          sx={{ fontSize: '36px', color: '#fff', cursor: 'pointer' }}
          onClick={handleGetImages}
        />
      )}
    </Flexbox>
  );
};
