import { API_URL, get_thunk } from 'api/API';

import { USER_LOGIN_ENDPOINT } from 'api/Endpoints';
import { USER_AUTH_LOGIN } from './AuthConstants';

const userLoginAction = get_thunk(
  USER_AUTH_LOGIN,
  API_URL + USER_LOGIN_ENDPOINT,
  true,
);

export { userLoginAction };
