import { Card, styled } from '@mui/material';
import ScrollBar from 'simplebar-react';

import palette from 'theme/theme';

const ModalCardRoot = styled(Card)(() => ({
  top: '50%',
  left: '50%',
  maxWidth: 600,
  minWidth: 200,
  position: 'absolute',
  padding: '1.5rem',
  boxShadow: palette.shadows.primary,
  transform: 'translate(-50%, -50%)',
  width: '100%',
  outline: 'none',
  backgroundColor: '#032620',
  borderRadius: '18px',
  zIndex: 3000,
})) as typeof Card;

const StyledScrollbar = styled(ScrollBar)(() => ({
  maxHeight: '700px',
}));

const SeeMoreLink = styled('div')(() => ({
  fontSize: '12px',
  fontStyle: 'italic',
  color: '#1976d2',
  cursor: 'pointer',
  textDecoration: 'underline',
}));

const FlexCell = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export { StyledScrollbar, SeeMoreLink, FlexCell };
export default ModalCardRoot;
