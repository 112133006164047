import { styled } from '@mui/material';

const AddButtonRoot = styled('button')(() => ({
  position: 'absolute',
  zIndex: 100,
  border: 'none',
  backgroundColor: '#032620',
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  top: '50%',
  right: '20px',
  svg: {
    color: '#fff',
    width: '28px',
    height: '28px',
  },
}));

export default AddButtonRoot;
