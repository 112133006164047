import { Button, styled } from '@mui/material';

import palette from 'theme/theme';

const SimpleButton = styled(Button)(() => ({
  fontFamily: 'Roboto',
  padding: '10px',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '1.5',
  textTransform: 'none',
  border: '2px solid #032620',
  color: '#fff',
  backgroundColor: '#032620',
  boxShadow: 'unset',
  minWidth: '64px',
  width: '160px',
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: palette.blue[200],
    opacity: 0.85,
    color: 'text.disabled',
    boxShadow: 'unset',
  },
})) as typeof Button;

const CancelButton = styled(Button)(() => ({
  padding: '0.4rem 1rem',
  fontSize: '0.875rem',
  lineHeight: '1.75',
  textTransform: 'none',
  color: palette.black[100],
  borderColor: palette.black[100],
  boxShadow: 'unset',
  minWidth: '64px',
  width: '160px',
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    borderColor: palette.black[100],
    opacity: 0.85,
    color: 'text.disabled',
    boxShadow: 'unset',
  },
})) as typeof Button;

export { CancelButton };
export default SimpleButton;
