import { FormHelperText, styled } from '@mui/material';

const ErrorTextRoot = styled(FormHelperText)(() => ({
  color: `#d32f2f`,
  fontFamily: `"Roboto","Helvetica","Arial",sans-serif;`,
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  textAlign: 'left',
  marginTop: '5px',
  marginLeft: '14px',
  marginRight: '14px',
}));

export default ErrorTextRoot;
