import { Drawer, styled } from '@mui/material';
import { Clear } from '@mui/icons-material';
import ScrollBar from 'simplebar-react';

const ListSidebarRoot = styled(Drawer)(() => ({
  zIndex: 1212,
  '& .MuiPaper-root': {
    width: 500,
  },
})) as typeof Drawer;

const ClearIcon = styled(Clear)(() => ({
  width: '30px',
  height: '30px',
}));

const ScrollBarRoot = styled(ScrollBar)(() => ({
  overflow: 'auto',
  height: 'calc(100% - (54px + 75px))',
}));

export { ClearIcon, ScrollBarRoot };
export default ListSidebarRoot;
