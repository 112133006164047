import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from 'store/configureStore';
import LocaleStorage from './LocaleStorage';
import { UNREAD } from 'core/roles';

// const API_URL: string = 'http://194.163.164.118:8082';
// const API_URL: string = 'http://194.163.164.118:8092';
// const API_URL: string = 'http://45.67.216.190:8082';
const API_URL: string = 'https://app.businesstrip.ai:8082';

const get_headers = (): Record<string, string> => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
};

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: get_headers(),
});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig<any>) => {
    const accessToken = LocaleStorage.get('accessToken');
    const role: string = LocaleStorage.get('role') || UNREAD;
    if (config && config.headers && role !== UNREAD) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: any) => {
    const responseError = error.response;
    return Promise.reject(
      (responseError && responseError.data) || 'Something went wrong',
    );
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    return response;
  },
  (error: any) => {
    if (error.response.status === 401) {
      LocaleStorage.clear();
      // window.location.href = USER_LOGIN_ROUTE;
    }

    return Promise.reject(error);
  },
);

const post_thunk = (prefix: string, endpoint: string, pure: boolean) => {
  return createAsyncThunk<any, any, { dispatch: AppDispatch }>(
    prefix,
    async (payload, thunkAPI) => {
      try {
        let response: AxiosResponse<any, any> | null = null;
        const config: Record<string, any> = payload.query ? payload.query : {};
        const data: Record<string, any> = payload.data ? payload.data : {};

        if (pure) response = await axios.post(endpoint, data, config);
        else response = await axiosInstance.post(endpoint, data, config);

        return response;
      } catch (error: unknown) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  );
};

const get_thunk = (prefix: string, endpoint: string, pure: boolean) => {
  return createAsyncThunk<any, any, { dispatch: any }>(
    prefix,
    async (config: Record<string, any> = {}, thunkAPI) => {
      try {
        let response: AxiosResponse<any, any> | null = null;
        if (pure) response = await axios.get(endpoint, config);
        else response = await axiosInstance.get(endpoint, config);

        return response;
      } catch (error: unknown) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  );
};

const delete_thunk = (prefix: string, endpoint: string, pure: boolean) => {
  return createAsyncThunk<any, any, { dispatch: any }>(
    prefix,
    async (config: Record<string, any> = {}, thunkAPI) => {
      try {
        let response: AxiosResponse<any, any> | null = null;
        if (pure) response = await axios.delete(endpoint, config);
        else response = await axiosInstance.delete(endpoint, config);

        return response;
      } catch (error: unknown) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  );
};

export { API_URL, post_thunk, get_thunk, delete_thunk };
