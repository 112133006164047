import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import { Textarea } from 'components/controls';
import PostBoxStyled from './styled';

interface ISharePostBox {
  postImage: File | null;
  postText: string;
  onTypeText: (variable?: any) => void;
  onRemoveImage: (variable?: any) => void;
}

const Image = React.memo(function Image({ src }: { src: any }) {
  return <img src={src} alt="img" />;
});

const SharePostBox = ({
  postImage,
  postText,
  onTypeText,
  onRemoveImage,
}: ISharePostBox): JSX.Element => {
  const urlPostImage = React.useMemo(() => {
    if (postImage) return URL.createObjectURL(postImage);
  }, [postImage]);

  const renderImage: JSX.Element | null = postImage && (
    <div className="image-preview">
      <Tooltip title="Remove Photo" onClick={onRemoveImage}>
        <IconButton className="image-preview-delete">
          <CloseIcon />
        </IconButton>
      </Tooltip>

      <Image src={urlPostImage} />
    </div>
  );

  return (
    <PostBoxStyled>
      <Textarea name="post" value={postText} onTextareaChange={onTypeText} />

      {renderImage}
    </PostBoxStyled>
  );
};

export default SharePostBox;
