import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';
import { CSSProperties, Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ProfilePopover from 'layouts/mainLayout/components/profilePopover/ProfilePopover';
import {
  FilterBar,
  Filters,
  NavbarRoot,
  NotificationPopover,
} from 'layouts/homeLayout/components';
import ArrowButton from 'components/arrowButton/ArrowButton';
import Flexbox from 'components/flexbox/Flexbox';

import useMediaMatches from 'hooks/useMediaMatches';

import {
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_LOGIN_ROUTE,
  HOME_ROUTE,
  PROFILE_WID_ROUTE,
  USER_LOGIN_ROUTE,
} from 'routes/core/RouterConstant';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import LocaleStorage from 'api/LocaleStorage';
import { ADMIN, UNREAD } from 'core/roles';

import logo from 'assets/img/logo.png';
import MobileMenu from './styled';
import { StyledSmallButton } from 'layouts/mainLayout/components/profilePopover/ProfilePopover.styled';
import { useProfile } from 'features/profile';
import AppConfig from 'config';

interface IMediaNavbar {
  filterStyle: string;
  isReturnRender: boolean;
  isFilterRender: boolean;
  notifications: any;
}

const HomeMenu: string = AppConfig.MENU.HOME;
const DashboardMenu: string = AppConfig.MENU.DASHBOARD;
const SignOutMenu: string = AppConfig.MENU.SIGN_OUT;

const linkStyles: CSSProperties = {
  color: '#fff',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 600,
};

const closeStyles: CSSProperties & Record<string, any> = {
  color: '#fff',
  marginRight: 'auto',
  svg: { width: '30px', height: '30px' },
};

const mobileLogoStyles: CSSProperties = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
};

const mobileMenuStyles: CSSProperties = {
  color: '#fff',
  marginRight: 'auto',
  height: '30px',
  width: '30px',
};

const MediaNavbar = ({
  filterStyle,
  isReturnRender,
  notifications,
  isFilterRender,
}: IMediaNavbar): JSX.Element => {
  const [mobileSM, laptopM] = useMediaMatches(
    MEDIA_QUERIES_DICTIONARY.MOBILE_SM,
    MEDIA_QUERIES_DICTIONARY.LAPTOP_M,
  );

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const role: string = LocaleStorage.get('role') || 'unread';
  const isGuest: boolean = isAuth === UNREAD;
  const isAdmin: boolean = role === ADMIN;

  const { info } = useProfile();
  const navigate = useNavigate();

  const handleBackToHome = (): void => {
    window.location.href = HOME_ROUTE;
  };

  const renderReturnBackButton: JSX.Element = (
    <ArrowButton
      label="Home Page"
      onClick={handleBackToHome}
      sx={{ marginRight: 'auto' }}
    />
  );

  const handleOpenMenu = () => {
    setOpenMenu((prevState: boolean) => !prevState);
  };

  const renderFilters: JSX.Element = (
    <Flexbox
      width={mobileSM ? '100%' : 'unset'}
      alignItems="center"
      gap="0 15px"
    >
      <Filters title="Filters" />
    </Flexbox>
  );

  const handleHome = (): void => {
    window.location.href = HOME_ROUTE;
    setOpenMenu(false);
  };

  const handleProfile = (): void => {
    const profileUrl = `${PROFILE_WID_ROUTE}${info?.data?.userId}`;
    navigate(profileUrl);
    setOpenMenu(false);
  };

  const handleAdminLogout = (): void => {
    LocaleStorage.clear();
    navigate(ADMIN_LOGIN_ROUTE);
  };

  const handleAdminDashboard = (): void => {
    navigate(ADMIN_DASHBOARD_ROUTE);
    setOpenMenu(false);
  };

  const handleLogout = (): void => {
    LocaleStorage.clear();
    navigate(USER_LOGIN_ROUTE);
    setOpenMenu(false);
  };

  const renderPopovers: JSX.Element = (
    <Flexbox
      alignItems="center"
      sx={{ marginLeft: 'auto' }}
      gap={mobileSM ? '0 10px' : '0 20px'}
    >
      {!isGuest && !isAdmin ? (
        <NotificationPopover
          popoverParameters={laptopM ? '24px' : '32px'}
          notifications={notifications}
        />
      ) : null}

      {!isGuest ? (
        <ProfilePopover
          popoverParameters={laptopM ? '40px' : mobileSM ? '30px' : '50px'}
          role={role}
          isClickable={mobileSM ? false : true}
          onClick={handleProfile}
        />
      ) : (
        <Link to={USER_LOGIN_ROUTE} style={linkStyles}>
          Sign In
        </Link>
      )}
      {!isGuest && mobileSM && (
        <IconButton sx={mobileMenuStyles} onClick={handleOpenMenu}>
          <MenuIcon />
        </IconButton>
      )}
    </Flexbox>
  );

  const renderAdminMenu: JSX.Element = (
    <Fragment>
      <Box pt={1}>
        <StyledSmallButton sx={{ color: '#fff' }} onClick={handleHome}>
          {HomeMenu}
        </StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton
          sx={{ color: '#fff' }}
          onClick={handleAdminDashboard}
        >
          {DashboardMenu}
        </StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton sx={{ color: '#fff' }} onClick={handleAdminLogout}>
          {SignOutMenu}
        </StyledSmallButton>
      </Box>
    </Fragment>
  );

  const renderUserMenu: JSX.Element = (
    <Fragment>
      <Box pt={1}>
        <StyledSmallButton sx={{ color: '#fff' }} onClick={handleHome}>
          {HomeMenu}
        </StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton sx={{ color: '#fff' }} onClick={handleProfile}>
          My Profile
        </StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton sx={{ color: '#fff' }} onClick={handleLogout}>
          {SignOutMenu}
        </StyledSmallButton>
      </Box>
    </Fragment>
  );

  const renderMobileNavbar = (
    <Box>
      <NavbarRoot
        sx={{ filter: filterStyle, height: '50px', padding: '0 20px' }}
      >
        <Box maxWidth="100px" pt="5px">
          <img src={logo} alt="logo" style={{ width: '90%' }} />
        </Box>

        {isFilterRender && renderPopovers}
      </NavbarRoot>
      {isFilterRender && <FilterBar>{renderFilters}</FilterBar>}

      <MobileMenu sx={{ right: openMenu ? 0 : '-100%' }}>
        <Box sx={{ textAlign: 'right' }}>
          <IconButton sx={closeStyles} onClick={handleOpenMenu}>
            <CloseIcon />
          </IconButton>
        </Box>
        {isAdmin ? renderAdminMenu : renderUserMenu}

        <img
          src={logo}
          alt="logo"
          style={{
            width: '100px',
            position: 'absolute',
            bottom: '20px',
            left: '20px',
          }}
        />
      </MobileMenu>
    </Box>
  );

  const renderDesktopNavbar = (
    <NavbarRoot sx={{ filter: filterStyle, height: '90px', padding: '0 40px' }}>
      {isReturnRender ? (
        renderReturnBackButton
      ) : (
        <Box maxWidth="300px" marginRight="auto">
          <img
            src={logo}
            alt="logo"
            style={{ width: laptopM ? '120px' : '180px' }}
          />
        </Box>
      )}

      {isFilterRender && renderFilters}
      {renderPopovers}
    </NavbarRoot>
  );

  return mobileSM ? renderMobileNavbar : renderDesktopNavbar;
};

export default MediaNavbar;
