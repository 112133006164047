const ADMIN_GET_USER_PROFILE: string = 'adminDahsboard/getUserProfile';
const ADMIN_CHANGE_STATUS: string = 'adminDahsboard/changeStatus';
const ADMIN_REMOVE_TRIP: string = 'adminDashboard/removeTrip';
const ADMIN_DOWNLOAD_REPORT: string = 'adminDahsboard/report';
const ADMIN_DASHBOARD_INFO: string = 'adminDashboard/info';
const ADMIN_HIDE_TRIP: string = 'adminDashboard/hideTrip';
const ADMIN_GET_TRIPS: string = 'adminDahsboard/getTrips';
const ADMIN_DASHBOARD: string = 'adminDashboard';

export {
  ADMIN_DASHBOARD,
  ADMIN_DASHBOARD_INFO,
  ADMIN_HIDE_TRIP,
  ADMIN_REMOVE_TRIP,
  ADMIN_GET_TRIPS,
  ADMIN_DOWNLOAD_REPORT,
  ADMIN_GET_USER_PROFILE,
  ADMIN_CHANGE_STATUS,
};
