import { Button, styled } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';

const CopyLinkStyled = styled(Button)(() => ({
  textTransform: 'capitalize',
  color: '#1b1b1b',
  backgroundColor: '#fff',
  width: '100%',
  padding: '10px',
  fontWeight: 600,
  fontSize: '15px',
  transition: 'all .3s',
  '&:hover': {
    color: '#1b1b1b',
    backgroundColor: '#fff',
    opacity: '0.8',
  },
}));

const CopyLinkButton = ({ label, link }: any): JSX.Element => {


  // const copyToClipboard = () => {
  //   if (navigator.clipboard && window.isSecureContext) {
  //     return navigator.clipboard.writeText(link);
  //   } else {
  //     let textArea = document.createElement('textarea');
  //     textArea.value = link;
  //     textArea.style.position = 'fixed';
  //     textArea.style.left = '-999999px';
  //     textArea.style.top = '-999999px';
  //     document.body.appendChild(textArea);
  //     textArea.focus();
  //     textArea.select();

  //     return new Promise<void>((res, rej) => {
  //       document.execCommand('copy') ? res() : rej();
  //       textArea.remove();
  //     });
  //   }
  // };

  const handleCopy = (value: any) => (_event: any) => {
    // copyToClipboard()
    //   .then(() => toast.success('Copied!'))
    //   .catch((e) => console.log('error: ', e));
    // get the container
    const element: any = document.querySelector('#copyInput');
    // Create a fake `textarea` and set the contents to the text
    // you want to copy
    const storage = document.createElement('textarea');
    storage.value = value;
    element.appendChild(storage);

    // Copy the text in the fake `textarea` and remove the `textarea`
    storage.focus();
    storage.select();
    storage.setSelectionRange(0, 99999);
    document.execCommand('copy');
    element.removeChild(storage);
    toast.success('Copied!');
  };

  return (
    <CopyLinkStyled onClick={handleCopy(link)} id="copyInput">
      {label}
    </CopyLinkStyled>
  );
};

export default CopyLinkButton;
