import Fontbox from '../fontbox/Fontbox';

import TSmall from './types';

const Small = ({ children, ...props }: TSmall): JSX.Element => {
  return (
    <Fontbox
      component="small"
      fontSize="0.75rem"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </Fontbox>
  );
};

export default Small;
