const palette = {
  white: {
    '100': '#E5EAF2',
    '200': '#E5F3FD',
  },
  grey: {
    '100': '#A798FF',
  },
  red: {
    100: '#ff5e6c',
  },
  blue: {
    '100': '#2499EF',
    '200': '#0e1016',
    '300': '#1976d2',
    '400': '#6EA2D5',
  },
  green: {
    100: '#46923c',
  },
  black: {
    100: '#0e1016',
  },
  shadows: {
    primary: '0px 7px 30px 3px rgb(0 0 0 / 5%)',
  },
};

export default palette;
