import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';

import { Form, FormButton, GridFieldSet } from 'components/controls';
import Textfield from 'components/controls/textfield/Textfield';

import useAdminAuth from 'features/adminLogin/redux/auth/AuthHook';
import LocaleStorage from 'api/LocaleStorage';

import TAdminLoginValues, {
  ILoginPayload,
} from 'features/adminLogin/models/initialValuesModel';

import INITIAL_LOGIN_VALUES from 'features/adminLogin/core/initialValues';
import AdminLoginSchema from 'features/adminLogin/validation/Schema';
import { ADMIN_DASHBOARD_ROUTE } from 'routes/core/RouterConstant';
import { ADMIN } from 'core/roles';
import AppConfig from 'config';

const LoginFormComponent = (): JSX.Element => {
  const [initFormik, setInitFormik] =
    useState<TAdminLoginValues>(INITIAL_LOGIN_VALUES);

  const { dispatcherAdminLogin } = useAdminAuth();
  const navigate: NavigateFunction = useNavigate();

  /**
   * *@Fetcher functions
   */
  const fetcherLogin = (formData: TAdminLoginValues) => {
    const payload: ILoginPayload = {
      params: {
        login: formData.login,
        password: formData.password,
      },
    };

    dispatcherAdminLogin(payload)
      .unwrap()
      .then((response) => {
        toast.success(AppConfig.NOTIFICATION.SUCCESS_LOGIN, { duration: 3000 });
        LocaleStorage.set('accessToken', response.data);
        LocaleStorage.set('role', ADMIN);
        LocaleStorage.set('auth', 'true');
        navigate(ADMIN_DASHBOARD_ROUTE);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: initFormik,
    validationSchema: AdminLoginSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      fetcherLogin(values);
    },
  });

  /**
   * ?Side Effect for immediately reinitialize
   */
  useEffect(() => {
    setInitFormik(INITIAL_LOGIN_VALUES);
  }, []);

  /**
   * *@Renders functions
   */
  const renderFields: JSX.Element = (
    <>
      <GridFieldSet label={AppConfig.PLACEHOLDERS.EMAIL} light>
        <Textfield
          name="login"
          value={values.login}
          onChange={handleChange}
          error={Boolean(errors.login && touched.login)}
          helperText={touched.login && errors.login}
        />
      </GridFieldSet>

      <GridFieldSet label={AppConfig.PLACEHOLDERS.PASSWORD} light>
        <Textfield
          name="password"
          value={values.password}
          type="password"
          onChange={handleChange}
          error={Boolean(errors.password && touched.password)}
          helperText={touched.password && errors.password}
        />
      </GridFieldSet>
    </>
  );

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {renderFields}
      <Box mt={4} width="100%">
        <FormButton
          label="Log in"
          as="submit"
          variant="contained"
          fullWidth
          sx={{
            width: '100%',
          }}
        />
      </Box>
    </Form>
  );
};

const LoginForm = React.memo(LoginFormComponent);
export default LoginForm;
