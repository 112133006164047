import { Box } from '@mui/material';

import TFlexbox from './types';

const Flexbox = ({ children, ...props }: TFlexbox): JSX.Element => {
  return (
    <Box display="flex" {...props}>
      {children}
    </Box>
  );
};

export default Flexbox;
