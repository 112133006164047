import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from 'store/configureStore';
import { userLoginAction } from './AuthActions';

const useUserAuth = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isAuth } = useSelector((state: RootState) => state.userAuth);

  return {
    dispatcherUserLogin: (config: Record<string, any>) =>
      dispatch(userLoginAction(config)),
    isAuth,
  };
};

export default useUserAuth;
