import { Fragment, useEffect, useMemo } from 'react';
import NProgress from 'nprogress';

import { Box, CircularProgress } from '@mui/material';

const BoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const LoadingScreen = ({ fullHeight = true }: { fullHeight?: boolean }) => {
  const renderLoadingBox = (
    <Box sx={{ ...BoxStyles, height: fullHeight ? '100vh' : '100%' }}>
      <CircularProgress />
    </Box>
  );

  NProgress.configure({
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Fragment>{renderLoadingBox}</Fragment>;
};

export default LoadingScreen;
