/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';

import BaseModalActions from 'components/BaseModalActions/BaseModalActions';
import SharePostBox from '../components/SharePostBox/SharePostBox';
import LoadButton from '../components/loadButton/LoadButton';
import Flexbox from 'components/flexbox/Flexbox';
import { ModalWindow } from 'components/window';
import { Heading } from 'components/typography';

import LoadingScreen from 'pages/LoadingScreen';

import useMediaMatches from 'hooks/useMediaMatches';
import useMap from '../redux/map/MapHook';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import { SHARE_TRIP_ENDPOINT } from 'api/Endpoints';
import { axiosInstance } from 'api/API';

import { disableElement } from 'utils/Generic';
import CopyLinkButton from 'components/copyLinkButton/CopyLinkButton';
import { getLinkFromInvite } from 'utils/Formatter';
import { Avatar } from '@mui/material';
import { Textarea } from 'components/controls';

interface ILinkedShareModule {
  onCancel: (variable?: any) => void;
  open: boolean;
  shareTripId?: string;
}

const LinkedinShareModule = ({
  onCancel,
  open,
  shareTripId,
}: ILinkedShareModule): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const { dispatcherGetSharePost } = useMap();

  const [postImageState, setPostImageState] = useState<null | FileList>(null);
  const [postTextState, setPostTextState] = useState<string>('');
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const localeUser = localStorage.getItem('user') || '{}';
  const user = JSON.parse(localeUser);
  const imageUser = user?.photoUrl;
  const userName = user?.name;
  const postImageVar: File | null =
    postImageState && postImageState[0] ? postImageState[0] : null;

  const isNullTextVar: boolean = postTextState === null;

  const getPostFetcher = (): void => {
    const requestConfig: Record<string, any> = { params: { id: shareTripId } };
    dispatcherGetSharePost(requestConfig)
      .unwrap()
      .then((response: AxiosResponse) => {
        setPostTextState(response.data);
      })
      .catch(() => {
        toast.error('Sorry, something went wrong with sharing!');
      });
  };

  const sharePostFetcher = async () => {
    const formData: FormData = new FormData();
    let contentType: string = 'multipart/form-data';
    formData.append('post', postTextState);

    if (postImageVar) {
      contentType = postImageVar?.type;
      formData.append('file', postImageVar);
    }

    const requestConfig: Record<string, any> = {
      headers: {
        Accept: '*/*',
        'Content-Type': contentType || 'multipart/form-data',
      },
    };

    await axiosInstance
      .post(SHARE_TRIP_ENDPOINT, formData, requestConfig)
      .then(() => {
        toast.success('Your post has been uploaded!', { duration: 3000 });
        setPostImageState(null);
        setOpenPreview(false);
        disableElement('.submit-button', false);
      })
      .catch((error: AxiosError) => {
        disableElement('.submit-button', false);
        if (error && error.response && error.response.data) {
          console.log(error);
          const message: any = error.response.data;
          const toastMessage: string = message.error
            ? message.error.toString()
            : message.toString();
          toast.error(toastMessage);
        } else {
          toast.error('Sorry, something went wrong with sharing!', {
            style: {
              width: '1000px !improtant',
              maxWidth: '1000px !improtant',
            },
          });
        }
      });
  };

  const handleOnUploadImage = (_event: React.SyntheticEvent): void => {
    _event.preventDefault();
    const target = _event.target as typeof _event.target & {
      files: any;
    };

    const files: FileList = target.files;
    setPostImageState(files);
  };

  const handleOnPostEditing = (_event: React.SyntheticEvent): void => {
    const target = _event.target as typeof _event.target & {
      value: string;
    };
    setPostTextState(target.value);
  };

  const handleRemoveImage = () => {
    setPostImageState(null);
  };

  const handleCancelSharing = () => {
    onCancel();
    setPostImageState(null);
  };

  const handleCancelSharingPreview = () => {
    setOpenPreview(false);
    onCancel();
  };

  const handleOnShare = (_event: React.SyntheticEvent): void => {
    _event.preventDefault();
    onCancel();
    setOpenPreview(true);
  };

  const handleShareConfirm = (_event: React.SyntheticEvent): void => {
    _event.preventDefault();
    setOpenPreview(false);
  };

  const handleOnSharePost = (_event: React.SyntheticEvent): void => {
    _event.preventDefault();
    disableElement('.submit-button', true);

    if (postImageState && postImageState.length > 0) {
      const sizeState = postImageState[0].size;
      const sizeInMb = (sizeState / (1024 * 1024)).toFixed(2);

      if (+sizeInMb < 10) sharePostFetcher();
      else {
        toast.error('Maximum upload file size: 10mb');
        disableElement('.submit-button', false);
      }
    } else sharePostFetcher();
  };

  useEffect(() => {
    if (open) getPostFetcher();
  }, [open]);

  const renderModalFields: JSX.Element = (
    <Flexbox
      flexDirection="column"
      alignItems="center"
      width="100%"
      gap="5px"
      mt={mobileSM ? '0px' : '-32px'}
    >
      <Heading
        as="h2"
        content="Your trip has been created!"
        textAlign="center"
        sx={{ color: '#fff', fontSize: mobileSM ? '16px' : '30px' }}
      />
      <Heading
        as="h5"
        content="You can share the trip in your linkedin profile"
        textAlign="center"
        sx={{
          color: '#fff',
          fontSize: mobileSM ? '14px' : '20px',
          marginBottom: mobileSM ? '10px' : '40px',
        }}
      />

      {!isNullTextVar ? (
        <SharePostBox
          postImage={postImageVar}
          postText={postTextState}
          onTypeText={handleOnPostEditing}
          onRemoveImage={handleRemoveImage}
        />
      ) : (
        <LoadingScreen fullHeight={false} />
      )}

      <LoadButton
        buttonLabel="Upload Post Image"
        onUpload={handleOnUploadImage}
        sx={{ marginTop: '20px', marginBottom: '5px' }}
      />

      <CopyLinkButton
        label="Copy Invitation Link"
        link={getLinkFromInvite(postTextState)}
      />
    </Flexbox>
  );

  const renderPreviewContent = (
    <Flexbox mt="-32px" flexDirection="column" width="100%">
      <Heading
        as="h5"
        content="Preview of LinkedIn post"
        sx={{
          color: '#fff',
          fontSize: mobileSM ? '14px' : '20px',
          marginBottom: mobileSM ? '10px' : '40px',
        }}
      />

      <Flexbox
        flexDirection="column"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          padding: '1.2rem 1.6rem 1.2rem',
          borderRadius: '10px',
        }}
      >
        <Flexbox gap="10px" mb="0.8rem">
          <Avatar src={imageUser} sx={{ width: '48px', height: '48px' }} />
          <Flexbox p="4px 0" flexDirection="column">
            <Flexbox gap="5px">
              <Heading
                as="h5"
                content={userName}
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.9)',
                  fontWeight: 500,
                }}
              />
              <Heading
                as="h5"
                content="• You"
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontWeight: 400,
                }}
              />
            </Flexbox>

            <Flexbox gap="5px">
              <Heading
                as="h5"
                content="now •"
                sx={{
                  fontFamily: 'Arial',
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontWeight: 400,
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                data-supported-dps="16x16"
                fill="rgba(0, 0, 0, 0.6)"
                width="16"
                height="16"
                focusable="false"
              >
                <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
              </svg>
            </Flexbox>
          </Flexbox>
        </Flexbox>

        <Textarea
          name="post"
          value={postTextState}
          readOnly
          sx={{
            fontFamily: 'Arial',
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.9)',
            fontWeight: 400,
            wordBreak: 'break-all',
            border: 'none',
            padding: '0',
            backgroundColor: 'transparent',
            borderRadius: '0',
          }}
        />

        {postImageVar && (
          <img
            src={URL.createObjectURL(postImageVar)}
            alt="post-img"
            style={{ maxWidth: '100%' }}
          />
        )}
      </Flexbox>
    </Flexbox>
  );

  const renderModalActions: JSX.Element = (
    <BaseModalActions
      cancelLabel="Cancel"
      submitLabel="Share on LinkedIn"
      cancelOnclick={handleCancelSharing}
      submitOnclick={handleOnShare}
    />
  );

  const renderModalPreviewActions: JSX.Element = (
    <BaseModalActions
      cancelLabel="Cancel"
      submitLabel="Share"
      cancelOnclick={handleCancelSharingPreview}
      submitOnclick={handleOnSharePost}
    />
  );

  return (
    <>
      <ModalWindow
        title=""
        open={open}
        onClose={handleCancelSharing}
        onSubmit={handleOnShare}
        fields={renderModalFields}
        buttons={renderModalActions}
      />
      <ModalWindow
        title=""
        open={openPreview}
        onClose={handleCancelSharingPreview}
        onSubmit={handleOnSharePost}
        fields={renderPreviewContent}
        buttons={renderModalPreviewActions}
      />
    </>
  );
};

export default LinkedinShareModule;
