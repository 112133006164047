import { Box, styled } from '@mui/material';

const MobileMenu = styled(Box)(() => ({
  backgroundColor: '#032620',
  padding: '10px',
  width: '70vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  zIndex: 3000,
  right: 0,
  transition: 'all .3s',
}));

export default MobileMenu;
