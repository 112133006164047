import { useSelector, useDispatch } from 'react-redux';

import userProfileAction, {
  userEditPhotoActions,
  userEditProfileAction,
  userEditTripAction,
  userGetPhotoActions,
  userGetPrevTripsAction,
  userGetTripsAction,
} from './ProfileActions';
import { AppDispatch, RootState } from 'store/configureStore';
import { setUserFetching } from './ProfileSlice';

const useProfile = () => {
  const dispatch: AppDispatch = useDispatch();
  const { info, fetchUser, trips } = useSelector(
    (state: RootState) => state.profile,
  );

  const dispatcherUserProfile = (config: any) =>
    dispatch(userProfileAction(config));

  const dispatcherEditUserProfile = (payload: any) =>
    dispatch(userEditProfileAction(payload));

  const dispatchSetFetcherUser = (state: boolean) =>
    dispatch(setUserFetching(state));

  const dispatcherUserGetTrips = (config: any) =>
    dispatch(userGetTripsAction(config));

  const dispatcherEditTrips = (payload: any) =>
    dispatch(userEditTripAction(payload));

  const dispatcherUserGetPrevTrips = (payload: any) =>
    dispatch(userGetPrevTripsAction(payload));

  const dispatchEditUserPhoto = (payload: any) =>
    dispatch(userEditPhotoActions(payload));

  const dispatchGetUserPhoto = (config: any) =>
    dispatch(userGetPhotoActions(config));

  return {
    dispatchGetUserPhoto,
    dispatcherUserProfile,
    dispatcherEditUserProfile,
    dispatchSetFetcherUser,
    dispatcherUserGetTrips,
    dispatcherUserGetPrevTrips,
    dispatcherEditTrips,
    dispatchEditUserPhoto,
    fetchUser,
    trips,
    info,
  };
};

export default useProfile;
