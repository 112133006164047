import { styled } from '@mui/material';

const UploadButton = styled('div')(() => ({
  marginTop: '10px',
  width: '100%',
  color: '#fff',
  backgroundColor: 'transparent',
  border: '1px solid #fff',
  padding: '12px 10px',
  cursor: 'pointer',
  textAlign: 'center',
  borderRadius: '8px',
}));

export default UploadButton;
