/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';

import { UserLogin } from '../components';

import useUserAuth from '../redux/AuthHook';

import LocaleStorage from 'api/LocaleStorage';
import { TokenType } from 'core/types';
import LINKEDIN_AUTORIZATION_URL, {
  LINKEDIN_REDIRECT,
  LINKEDIN_STATE,
} from 'api/LinkedInAPI';
import {
  HOME_ROUTE,
  USER_CONFIRMATION_ROUTE,
} from 'routes/core/RouterConstant';
import { USER } from 'core/roles';

const LinkedinLoginModule = () => {
  const accessToken: TokenType = LocaleStorage.get('accessToken');
  let windowPopup: Window | null = null;

  const navigate: NavigateFunction = useNavigate();
  const { dispatcherUserLogin } = useUserAuth();

  const loginFetcher = (token: string, state: string): void => {
    const config: Record<string, any> = {
      params: {
        code: token,
        redirect_uri: LINKEDIN_REDIRECT,
        state: state,
      },
    };
    dispatcherUserLogin(config)
      .unwrap()
      .then((response: AxiosResponse) => {
        const isRegistered: boolean = response.data?.isRegistered;
        const token: string = response.data?.token;

        toast.success('You Logged In Successfully!', { duration: 3000 });
        LocaleStorage.set('accessToken', token);
        LocaleStorage.set('role', USER);
        LocaleStorage.set('auth', 'true');

        isRegistered ? navigate(HOME_ROUTE) : navigate(USER_CONFIRMATION_ROUTE);
      })
      .catch((error: AxiosError) => {
        console.log('Linkedin Error: ', error);
        toast.error('Failed!');
      });
  };

  const receiveLinkedInMessage = (event: MessageEvent) => {
    const { origin } = event;
    const { state, code, error, ...rest } = event.data;
    const notSuccess: boolean =
      origin !== window.location.origin || state !== LINKEDIN_STATE;

    const linkedErrorArray: Array<string> = [
      'user_cancelled_login',
      'user_cancelled_authorize',
    ];

    if (notSuccess) return;

    if (code) {
      loginFetcher(code, state);
    } else if (error && !linkedErrorArray.includes(error)) {
      console.log('Receive Linked Error', { ...rest });
    }

    windowPopup && windowPopup.close();
  };

  const handleLinkedinSign = (): void => {
    windowPopup = window.open(
      LINKEDIN_AUTORIZATION_URL,
      '_blank',
      'width=600,height=600',
    );
  };

  useEffect(() => {
    window.addEventListener('message', receiveLinkedInMessage);

    return () => {
      window.removeEventListener('message', receiveLinkedInMessage);
      windowPopup && windowPopup.close();
    };
  }, [windowPopup]);

  return accessToken ? (
    <Navigate to={HOME_ROUTE} replace />
  ) : (
    <UserLogin title="Login" onSign={handleLinkedinSign} />
  );
};

export default LinkedinLoginModule;
