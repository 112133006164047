import { ButtonBase, styled } from '@mui/material';

import palette from 'theme/theme';

const ArrowButtonRoot = styled(ButtonBase)(() => ({
  height: '40px',
  width: '40px',
  border: `1px solid #fff`,
  borderRadius: '50%',
  color: '#fff',
}));

export default ArrowButtonRoot;
