import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useState } from 'react';

// en

interface ICoord {
  lat: number;
  lng: number;
}

const useGeocoding = () => {
  const [coord, setCoord] = useState<any | null>(null);
  const [coordArray, setCoordArray] = useState<any>([]);

  const geocoder = new google.maps.Geocoder();

  const onGeocodeByAddress = async (address: string) => {
    let city = '';
    let country = '';

    const geocodeResult: any = await geocodeByAddress(address);
    const addressComponents: any = geocodeResult[0]?.address_components;
    const formatterAddress: any = geocodeResult[0]?.formatted_address;
    const coord: ICoord = await getLatLng(geocodeResult[0]);
    setCoord(coord);

    addressComponents.forEach((item: any) => {
      if (item.types[0] === 'locality') {
        city = item.long_name;
      }

      if (item.types[0] === 'country') {
        country = item.long_name;
      } else if (
        item.types[0] === 'natural_feature' ||
        item.types[1] === 'natural_feature'
      ) {
        country = item.long_name;
      }
    });


    return { coord, city, country, formatterAddress, addressComponents, geocodeResult };
  };

  const getCoord = async (address: string) => {
    return await onGeocodeByAddress(address).then((response) => response);
  };

  return {
    onGeocodeByAddress,
    coordArray,
    getCoord,
    coord,
  };
};

export type { ICoord };
export default useGeocoding;
