import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { Fragment, useRef, useState } from 'react';
import { Box, ButtonBase } from '@mui/material';

import { ProfileAvatar, StyledSmallButton } from './ProfilePopover.styled';
import PopoverLayout from '../popoverLayout/PopoverLayout';
import { Heading, Paragraph } from 'components/typography';
import Flexbox from 'components/flexbox/Flexbox';

import {
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_LOGIN_ROUTE,
  HOME_ROUTE,
  PROFILE_WID_ROUTE,
  USER_LOGIN_ROUTE,
} from 'routes/core/RouterConstant';

import { useProfile } from 'features/profile';
import LocaleStorage from 'api/LocaleStorage';

import { TPopoverProps } from 'layouts/mainLayout/types';

import { ADMIN } from 'core/roles';
import { API_URL } from 'api/API';
import AppConfig from 'config';

import { isSubstring } from 'utils/Validation';

const HomeMenu: string = AppConfig.MENU.HOME;
const DashboardMenu: string = AppConfig.MENU.DASHBOARD;
const SignOutMenu: string = AppConfig.MENU.SIGN_OUT;

const ProfilePopoverComponent = ({
  role,
  popoverParameters = '50px',
  isClickable = true,
  onClick,
}: TPopoverProps) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate: NavigateFunction = useNavigate();
  const { info } = useProfile();

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const isAdmin: boolean = role === ADMIN;
  const user: null = isAdmin ? null : info?.data?.email;
  const userName: string = isAdmin ? 'Admin' : info?.data?.name;
  const photo = info?.data?.photoUrl;
  const imageApiUrl: string = isSubstring(photo, 'http')
    ? `${photo}`
    : `${API_URL}${photo}`;
  const photoSrc: string = isAdmin ? '' : imageApiUrl;

  /**
   * *@Handler functions
   */
  const handleAdminLogout = (): void => {
    LocaleStorage.clear();
    navigate(ADMIN_LOGIN_ROUTE);
  };

  const handlePopover = (): void => {
    setOpenPopover((state) => !state);
  };

  const handleHome = (): void => {
    window.location.href = HOME_ROUTE;
    handlePopover();
  };

  // ?todo Fix id
  const handleProfile = (): void => {
    const profileUrl = `${PROFILE_WID_ROUTE}${info?.data?.userId}`;
    navigate(profileUrl);
    handlePopover();
  };

  const handleAdminDashboard = (): void => {
    navigate(ADMIN_DASHBOARD_ROUTE);
    handlePopover();
  };

  const handleLogout = (): void => {
    LocaleStorage.clear();
    navigate(USER_LOGIN_ROUTE);
    handlePopover();
  };

  const renderPopoverTitle: JSX.Element = (
    <Flexbox alignItems="center">
      <Box ml={0} p={1}>
        <Heading as="h4" content={userName} />
        {user ? (
          <Paragraph
            fontWeight={500}
            fontSize="0.9rem"
            lineHeight="1.7"
            color="#fff"
          >
            {user}
          </Paragraph>
        ) : null}
      </Box>
    </Flexbox>
  );

  const renderAdminMenu: JSX.Element = (
    <Fragment>
      <Box pt={1}>
        <StyledSmallButton onClick={handleHome}>{HomeMenu}</StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton onClick={handleAdminDashboard}>
          {DashboardMenu}
        </StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton onClick={handleAdminLogout}>
          {SignOutMenu}
        </StyledSmallButton>
      </Box>
    </Fragment>
  );

  const renderUserMenu: JSX.Element = (
    <Fragment>
      <Box pt={1}>
        <StyledSmallButton onClick={handleHome}>{HomeMenu}</StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton onClick={handleProfile}>
          Profile Page
        </StyledSmallButton>
      </Box>

      <Box pt={1}>
        <StyledSmallButton onClick={handleLogout}>
          {SignOutMenu}
        </StyledSmallButton>
      </Box>
    </Fragment>
  );

  const renderPopoverMenu: JSX.Element = isAdmin
    ? renderAdminMenu
    : renderUserMenu;

  return (
    <Fragment>
      <ButtonBase
        ref={anchorRef}
        onClick={isClickable ? handlePopover : onClick}
        disableRipple
        sx={{ marginLeft: 'auto' }}
      >
        <ProfileAvatar
          src={photoSrc}
          sx={{ width: popoverParameters, height: popoverParameters }}
        />
      </ButtonBase>

      <PopoverLayout
        open={openPopover}
        onClose={handlePopover}
        anchorRef={anchorRef}
        maxWidth={220}
        minWidth={180}
        title={renderPopoverTitle}
      >
        {renderPopoverMenu}
      </PopoverLayout>
    </Fragment>
  );
};

const ProfilePopover = React.memo(ProfilePopoverComponent);
export default ProfilePopover;
