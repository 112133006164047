/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Navigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { toast } from 'react-hot-toast';

import { GridDataTable } from 'components/dataTable';
import Flexbox from 'components/flexbox/Flexbox';
import { FormButton } from 'components/controls';
import { Heading } from 'components/typography';
import LoadingScreen from './LoadingScreen';
import {
  columns,
  StatiscticCard,
  useAdminDashboard,
} from 'features/adminDashboard';

import EarningIcon from 'assets/icons/EarningIcon';
import PeopleIcon from 'assets/icons/PeopleIcon';

import { TCard } from 'features/adminDashboard/models/StatisticCard';
import LocaleStorage from 'api/LocaleStorage';

import usePaginationFetching from 'hooks/usePaginationFetching';
import useMediaMatches from 'hooks/useMediaMatches';

import { ADMIN, UNREAD } from 'core/roles';
import palette from 'theme/theme';
import AppConfig from 'config';
import { ADMIN_LOGIN_ROUTE, HOME_ROUTE } from 'routes/core/RouterConstant';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

import { isNull } from 'utils/Validation';
import FollowButton from 'features/home/components/followButton/FollowButton';
import ArrowButton from 'components/arrowButton/ArrowButton';

const TitleStyles: CSSProperties = {
  color: '#fff',
  fontSize: '18px',
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
};

const INITIAL_CARD_LIST: Array<TCard> = [
  {
    count: '0',
    Icon: PeopleIcon,
    title: AppConfig.DASHBOARD.STATISCTICS.USERS,
    color: palette.blue[100],
  },
  {
    count: '0',
    Icon: EarningIcon,
    title: AppConfig.DASHBOARD.STATISCTICS.TRIPS,
    color: palette.grey[100],
  },
];

const AdminDashboard = (): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const [cardList, setCardList] = useState<Array<TCard>>(INITIAL_CARD_LIST);
  const [tripData, setTripData] = useState<Record<string, any> | null>(null);
  const {
    adminInfo,
    dispatcherAdminGetTrips,
    dispatcherAdminReport,
    dispatcherAdminProfile,
    fetchTrips,
  } = useAdminDashboard();

  const role: string = LocaleStorage.get('role') || UNREAD;
  const isAdmin: boolean = role === ADMIN;

  /**
   * *@Fetcher functions
   */
  const fetchAdminProfile = () => {
    dispatcherAdminProfile({})
      .unwrap()
      .then((response: any) => {
        const responseData: any = response.data;
        setCardList([
          {
            count: responseData?.usersCount,
            Icon: PeopleIcon,
            title: AppConfig.DASHBOARD.STATISCTICS.USERS,
            color: palette.blue[100],
          },
          {
            count: responseData?.tripsCount,
            Icon: EarningIcon,
            title: AppConfig.DASHBOARD.STATISCTICS.TRIPS,
            color: palette.grey[100],
          },
        ]);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        localStorage.clear();
        window.location.href = ADMIN_LOGIN_ROUTE;
      });
  };

  const fetcherTrips = (parameters: any) => {
    return dispatcherAdminGetTrips(parameters)
      .unwrap()
      .then((response: AxiosResponse) => {
        setTripData(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherDownloadReport = (parameters: any) => {
    dispatcherAdminReport(parameters)
      .unwrap()
      .then((response: any) => {
        const dateNow = new Date();
        const yyyy = dateNow.getFullYear();
        let mm = dateNow.getMonth() + 1; // Months start at 0!
        let dd = dateNow.getDate();

        let month = '';

        if (mm < 10) month = '0' + mm;

        const formattedToday = dd + '/' + month + '/' + yyyy;

        const data = response.data;
        const url: string = window.URL.createObjectURL(new Blob([data]));
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formattedToday}.xls`);
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) link.parentNode.removeChild(link);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { fetcher, pageNo, countLine } = usePaginationFetching(fetcherTrips);

  /**
   * *@Handler functions
   */
  const handleDownload = (): void => {
    fetcherDownloadReport({
      headers: {
        'Content-Type': 'blob',
      },
      responseType: 'arraybuffer',
    });
  };

  const handleBackToHome = (): void => {
    window.location.href = HOME_ROUTE;
  };

  /**
   * *@Side Effects
   */
  useEffect(() => {
    setCardList([
      {
        count: adminInfo?.data?.usersCount,
        Icon: PeopleIcon,
        title: AppConfig.DASHBOARD.STATISCTICS.USERS,
        color: palette.blue[100],
      },
      {
        count: adminInfo?.data?.tripsCount,
        Icon: EarningIcon,
        title: AppConfig.DASHBOARD.STATISCTICS.TRIPS,
        color: palette.grey[100],
      },
    ]);
  }, [fetchTrips]);

  useEffect(() => {
    fetchAdminProfile();
    const fetchInterval = setInterval(() => {
      fetchAdminProfile();
    }, 10000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  useEffect(() => {
    fetcher(pageNo, countLine);
  }, [fetchTrips]);

  /**
   * *@Renders functions
   */
  const renderStatistics: JSX.Element = (
    <Grid spacing={4} container>
      {cardList.map((card: TCard, index: number) => (
        <Grid xs={mobileSM ? 12 : 6} key={index} item>
          <StatiscticCard card={card} />
        </Grid>
      ))}
    </Grid>
  );

  const renderDownloadButton: JSX.Element = (
    <FollowButton
      label="Download"
      onClick={handleDownload}
      overrideStyles={{
        width: mobileSM ? '150px' : '200px',
        fontSize: mobileSM ? '13px' : '16px',
        margin: 0,
      }}
    />
  );

  if (isNull(tripData)) return <LoadingScreen />;

  if (isAdmin)
    return (
      <Box
        maxWidth="1380px"
        padding="0 15px"
        margin="0 auto"
        mt={mobileSM ? '70px' : '100px'}
      >
        {mobileSM && (
          <Flexbox alignItems="center" mb="20px">
            <ArrowButton
              label=""
              onClick={handleBackToHome}
              sx={{ marginRight: 'auto' }}
            />
            <Heading
              sx={TitleStyles}
              textAlign="center"
              content="DASHBOARD"
              as="h1"
            />
          </Flexbox>
        )}

        <Flexbox alignItems="center" justifyContent="space-between">
          <Heading
            as="h3"
            fontWeight="500"
            content={AppConfig.NAMES.STATISCTICS}
            sx={{ color: '#fff' }}
          />

          <Flexbox gap="0 1rem">{renderDownloadButton}</Flexbox>
        </Flexbox>

        <Box pt={3} pb={4}>
          {renderStatistics}
        </Box>

        <Box pt={1} pb={4}>
          <Heading
            as="h3"
            fontWeight="500"
            content={AppConfig.NAMES.USERS}
            sx={{ color: '#fff' }}
          />
          <GridDataTable
            data={tripData?.trips}
            columns={columns}
            pageCount={tripData?.pages}
            pageNo={pageNo + 1}
            onPageChange={fetcher}
          />
        </Box>
      </Box>
    );
  else return <Navigate to={ADMIN_LOGIN_ROUTE} />;
};

export default AdminDashboard;
