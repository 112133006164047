import { Fragment } from 'react';

import MobileTripDetails from 'features/home/components/mobileTripDetail/MobileTripDetails';
import Flexbox from 'components/flexbox/Flexbox';
import LoadingScreen from 'pages/LoadingScreen';
import { Heading } from 'components/typography';
import { TripDetail } from 'features/home';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

import { isEmptyArray, isNull } from 'utils/Validation';

interface ILoadTripList {
  data: any;
  isOpened: boolean;
}

const LoadTripList = ({ data, isOpened }: ILoadTripList): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const isLoading: boolean = isOpened && isNull(data);
  const isEmptyData: boolean = data && isEmptyArray(data);

  const renderEmptyTripList: JSX.Element = (
    <Flexbox
      justifyContent="center"
      alignItems="center"
      height="70vh"
      width="100%"
    >
      <Heading as="h2" content="Trips not found!" />
    </Flexbox>
  );

  const renderTrips: JSX.Element = isEmptyData ? (
    renderEmptyTripList
  ) : (
    <Fragment>
      {data?.map((trip: Record<string, any>, index: number) =>
        mobileSM ? (
          <MobileTripDetails data={trip} key={index} />
        ) : (
          <TripDetail data={trip} key={index} />
        ),
      )}
    </Fragment>
  );

  return <Fragment>{isLoading ? <LoadingScreen /> : renderTrips}</Fragment>;
};

export default LoadTripList;
