import React from 'react';

import FollowButton from 'features/home/components/followButton/FollowButton';
import {
  ListSidebar,
  LoadTripList,
  MobileListSidebarRoot,
  SidebarHeader,
} from 'layouts/homeLayout/components';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

interface IMediaListBar {
  isListRequest: boolean;
  data: any;
  pageNumber: number;
  onMore?: (event?: any) => void;
  onFiltering?: (event?: any) => void;
  injectFilters?: JSX.Element;
}

const MediaListBar = ({
  isListRequest,
  data,
  pageNumber,
  onMore,
  injectFilters,
  onFiltering,
}: IMediaListBar) => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const renderMoreButton: JSX.Element | null =
    pageNumber < data?.pages - 1 && isListRequest ? (
      <FollowButton
        label="Load More"
        success
        onClick={onMore}
        overrideStyles={{
          width: mobileSM ? '100%' : '180px',
          fontSize: mobileSM ? '12px' : '14px',
        }}
      />
    ) : null;

  const renderMobileList = (
    <MobileListSidebarRoot>
      <SidebarHeader
        isRenderFilters={isListRequest}
        onOpenFilters={onFiltering}
      />
      <LoadTripList data={data} isOpened />
      {injectFilters}
      {renderMoreButton}
    </MobileListSidebarRoot>
  );

  return mobileSM ? renderMobileList : <ListSidebar />;
};

export default MediaListBar;
