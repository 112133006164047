import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CSSProperties } from 'react';
import { Box } from '@mui/material';

import IndicatorRoot, { MobileNotificationBox } from './styled';
import NotificationIcon from 'assets/icons/NotificationIcon';
import Flexbox from 'components/flexbox/Flexbox';

import convertNotification from '../notificationPopover/converter';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

interface INotificationItem {
  notification: Record<string, any>;
}

const ItemStyles: CSSProperties & Record<string, any> = {
  borderBottom: 1,
  cursor: 'pointer',
  borderColor: 'divider',
  '&:hover': {
    opacity: '0.6',
  },
};

const DescBoxStyles: CSSProperties = {
  fontSize: '12.5px',
  maxWidth: '90%',
  color: '#fff',
  paddingLeft: '10px',
};

const DescMobileBoxStyles: CSSProperties = {
  fontSize: '12.5px',
  maxWidth: '90%',
  color: '#fff',
  paddingLeft: '10px',
  flex: '1 1 0%',
};

const NotificationItem = ({ notification }: INotificationItem): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const indicatorColor: string = notification.isRead ? '#aaa' : 'primary.main';

  const handleNotificationClick = (): void => {
    window.open(notification.tripLink, '_blank');
  };

  const renderDesktopVersion: JSX.Element = (
    <Flexbox
      onClick={handleNotificationClick}
      alignItems="center"
      sx={ItemStyles}
      p={2}
    >
      <Flexbox alignItems="center">
        <IndicatorRoot
          sx={{
            backgroundColor: indicatorColor,
          }}
        />
      </Flexbox>

      <Box sx={DescBoxStyles}>
        {convertNotification(notification.description)}
      </Box>
    </Flexbox>
  );

  const renderMobileVersion: JSX.Element = (
    <>
      <MobileNotificationBox>
        <NotificationIcon />
        <Box sx={DescMobileBoxStyles}>
          {convertNotification(notification.description)}
        </Box>
        <ChevronRightIcon
          sx={{ color: '#fff', marginLeft: 'auto' }}
          onClick={handleNotificationClick}
        />
      </MobileNotificationBox>
    </>
  );

  return mobileSM ? renderMobileVersion : renderDesktopVersion;
};

export default NotificationItem;
