import { createSlice } from '@reduxjs/toolkit';

import adminProfileAction, {
  adminGetTripsAction,
} from './AdminDashboardActions';

import { ADMIN_DASHBOARD } from './AdminDashboardConstants';

import TInitAdminProfile from './AdminDashboardTypes';

const initialState: TInitAdminProfile = {
  adminInfo: null,
  fetchTrips: false,
  trips: null,
};

const adminDashboardSlice = createSlice({
  name: ADMIN_DASHBOARD,
  initialState,
  reducers: {
    setTripsFetching: (
      state: TInitAdminProfile,
      action: { payload: boolean; type: string },
    ) => {
      state.fetchTrips = action.payload;
    },
  },
  extraReducers: {
    [adminProfileAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [adminProfileAction.fulfilled.type]: (state, action) => {
      state.adminInfo = action.payload;
    },
    [adminProfileAction.rejected.type]: (state) => {
      state.adminInfo = null;
    },
    [adminGetTripsAction.pending.type]: (state) => {
      state.fetchTrips = false;
    },
    [adminGetTripsAction.fulfilled.type]: (state, action) => {
      state.trips = action.payload;
    },
    [adminGetTripsAction.rejected.type]: (state) => {
      state.trips = null;
    },
  },
});

export const { setTripsFetching } = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;
