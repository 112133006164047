import { Box, styled } from '@mui/material';

import Flexbox from 'components/flexbox/Flexbox';

const IndicatorRoot = styled(Box)(() => ({
  width: 8,
  height: 8,
  marginRight: 2,
  borderRadius: '50%',
}));

const MobileNotificationBox = styled(Flexbox)(() => ({
  width: '100%',
  padding: '8px 10px',
  borderRadius: '12px',
  backgroundColor: '#000000',
  alignItems: 'center',
  marginBottom: '10px',
}));

export { MobileNotificationBox };
export default IndicatorRoot;
