import { Card, styled } from '@mui/material';

const TripDetailRoot = styled(Card)(() => ({
  backgroundColor: '#000000 !important',
  borderRadius: '18px',
  padding: '14px',
  height: '100%',
  width: '85% !important',
  margin: '40px auto !important',
  color: '#ffffff',
  boxShadow: 'unset',
  filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.4))',
})) as typeof Card;

export default TripDetailRoot;
