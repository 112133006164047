import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import { Fragment } from 'react';

import { FormButton } from 'components/controls';
import { ModalWindow } from 'components/window';

import useAdminDashboard from 'features/adminDashboard/redux/AdminDashboardHook';

import TPopupProps from 'features/adminDashboard/models/Popup';

import AppConfig from 'config';
import { ACTIVE_STATUS, DELETE_STATUS, HIDE_STATUS } from 'core/status';
import FollowButton from 'features/home/components/followButton/FollowButton';

const UsersPopup = ({
  title,
  data,
  open,
  onClose,
}: TPopupProps): JSX.Element => {
  const { dispatcherSetTripsFetching, dispatcherAdminChangeStatus } =
    useAdminDashboard();

  const isHideTrip: boolean = data?.isStatusHide;

  /**
   * *@Fetcher functions
   */

  const fetcherChangeStatusTrip = (config: any) => {
    dispatcherAdminChangeStatus(config)
      .unwrap()
      .then((response) => {
        toast.success(response.data, { duration: 3000 });
        onClose();
        dispatcherSetTripsFetching(true);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  /**
   * *@Handler functions
   */
  const handleHideTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
        status: HIDE_STATUS,
      },
    };
    fetcherChangeStatusTrip(config);
  };

  const handleRemoveTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
        status: DELETE_STATUS,
      },
    };
    fetcherChangeStatusTrip(config);
  };

  const handleShowTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
        status: ACTIVE_STATUS,
      },
    };
    fetcherChangeStatusTrip(config);
  };

  /**
   * *@Renders functions
   */
  const renderFormButtons: JSX.Element = (
    <Fragment>
      {!isHideTrip ? (
        <FollowButton success label="Hide Trip" onClick={handleHideTrip} />
      ) : null}

      {isHideTrip ? (
        <FollowButton label="Show Trip" onClick={handleShowTrip} />
      ) : null}

      <FollowButton label="Remove Trip" onClick={handleRemoveTrip} active />
    </Fragment>
  );

  return (
    <ModalWindow
      title={title}
      open={open}
      onSubmit={() => {}}
      onClose={onClose}
      fields={null}
      buttons={renderFormButtons}
    />
  );
};

export default UsersPopup;
