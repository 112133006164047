import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { useProfile } from 'features/profile';
import { useMap } from 'features/home';

const NotificationModule = (): Record<string, any> => {
  const [open, setOpen] = useState<boolean>(false);
  const { info } = useProfile();
  const {
    dispatcherMarkNotification,
    dispatchDeleteNotifications,
    dispatchSetFetchNotifications,
    fetchNotifications,
  } = useMap();

  const userId = info?.data?.userId;

  const handleOpenNotification = () => {
    setOpen(true);
  };

  const fetcherNotifications = (config: any) => {
    dispatcherMarkNotification(config)
      .unwrap()
      .then((response: any) => {})
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error('Failed!');
        }
      });
  };

  const deleteNotificationsFetcher = (config: any): void => {
    dispatchDeleteNotifications(config)
      .unwrap()
      .then(() => {
        setOpen(false);
        dispatchSetFetchNotifications(true);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error('Failed!');
        }
      });
  };

  const handleClearAll = (): void => {
    const config = {
      params: {
        userId: userId,
      },
    };
    deleteNotificationsFetcher(config);
    if (fetchNotifications) {
      dispatchSetFetchNotifications(false);
      setTimeout(() => {
        dispatchSetFetchNotifications(true);
      }, 1000);
    } else {
      setTimeout(() => dispatchSetFetchNotifications(true), 1000);
    }
  };

  const handleCloseNotification = (): void => {
    const config = {
      params: {
        userId: userId,
      },
    };
    fetcherNotifications(config);
    if (fetchNotifications) {
      dispatchSetFetchNotifications(false);
      setTimeout(() => {
        dispatchSetFetchNotifications(true);
      }, 1000);
    } else dispatchSetFetchNotifications(true);
    setOpen(false);
  };

  return {
    open,
    onOpenNotificate: handleOpenNotification,
    onClearAll: handleClearAll,
    onCloseNotificate: handleCloseNotification,
  };
};

export default NotificationModule;
