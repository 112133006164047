import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';

import { Text } from 'features/trip/components/tripLayout/TripLayout';
import FollowersIcon from 'assets/icons/FollowersIcon';
import Flexbox from 'components/flexbox/Flexbox';

import { useMap } from 'features/home';

interface IFollowersModule {
  onShowMore?: (_event?: any) => void;
  tripId: string;
}

const FollowersModule = ({
  onShowMore,
  tripId,
}: IFollowersModule): JSX.Element => {
  const [tripDataState, setTripDataState] = useState<any>(null);

  const { dispatcherGetTrip } = useMap();

  const fetcherFollowersTrip = (config: any) => {
    dispatcherGetTrip(config)
      .unwrap()
      .then((response: any) => {
        const data = response?.data;
        setTripDataState(data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error('Failed');
        }
      });
  };

  useEffect(() => {
    const getParams = {
      params: {
        id: tripId,
      },
    };
    fetcherFollowersTrip(getParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flexbox alignItems="center" gap="6px" mb="10px">
      <FollowersIcon />
      <Text
        sx={{ color: '#E8A442', cursor: onShowMore ? 'pointer' : 'unset' }}
        onClick={onShowMore}
      >
        ({tripDataState?.followersCount})
      </Text>
    </Flexbox>
  );
};

export default FollowersModule;
