import { styled, TextField } from '@mui/material';

import palette from 'theme/theme';

const TextfieldRoot = styled(TextField)(({ theme }) => ({
  marginTop: '0rem',
  '&:hover, & .MuiOutlinedInput-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  'input::placeholder': {
    fontWeight: '500',
    fontFamily: 'Roboto',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 14,
    minHeight: 40,
    fontWeight: 400,
    padding: '0px 1rem',
    color: palette.black[100],
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderWidth: '1px !important',
  },
  '& .MuiInputBase-root': {
    backgroundColor: '#EBEBF0',
    borderRadius: '6px',
  },
})) as typeof TextField;

export default TextfieldRoot;
