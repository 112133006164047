const PROFILE: string = 'profile';
const USER_PROFILE: string = 'profile/getUser';
const EDIT_USER_PROFILE: string = 'profile/editUser';
const GET_USER_TRIPS: string = 'profile/getTrips';
const GET_USER_PREV_TRIPS: string = 'profile/userCancelledTrips';
const USER_EDIT_TRIP: string = 'map/editTrip';
const USER_EDIT_PHOTO: string = 'profile/photoEdit';
const GET_USER_PHOTO: string = 'profile/photo';

export {
  PROFILE,
  USER_PROFILE,
  EDIT_USER_PROFILE,
  GET_USER_PREV_TRIPS,
  GET_USER_TRIPS,
  USER_EDIT_TRIP,
  USER_EDIT_PHOTO,
  GET_USER_PHOTO,
};
