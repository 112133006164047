import Fontbox from '../fontbox/Fontbox';

import TParagraph from './types';

const Paragraph = ({ children, ...props }: TParagraph): JSX.Element => {
  return (
    <Fontbox component="p" fontSize="1rem" {...props}>
      {children}
    </Fontbox>
  );
};

export default Paragraph;
