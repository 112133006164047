import { Popover, Divider, Box } from '@mui/material';

import { TPopoverLayoutProps } from 'layouts/mainLayout/types';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';
import TriangleIcon from 'assets/icons/TriangleIcon';

const PopoverLayout = ({
  children,
  anchorRef,
  title,
  open,
  onClose,
  maxWidth,
  minWidth,
  sx,
  injectIcon,
  isNotification,
}: TPopoverLayoutProps) => {
  const PaperProps: Record<string, unknown> = {
    sx: {
      minWidth: minWidth || 250,
      maxWidth: maxWidth || 375,
      minHeight: '180px',
      width: '100%',
      padding: '0.5rem 0rem',
      marginTop: '45px',
      borderRadius: '6px',
      backgroundColor: '#032620',
      color: '#fff',
      ...sx,
    },
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      PaperProps={PaperProps}
    >
      {isNotification && (
        <div className="triangle">
          <TriangleIcon />
        </div>
      )}
      <Flexbox alignItems="center" justifyContent="space-between">
        <Heading as="h4" content={title} />
        {injectIcon && <Box p="10px 16px">{injectIcon}</Box>}
      </Flexbox>
      <Divider
        sx={{
          width: isNotification ? '89%' : '100%',
          borderColor: '#fff',
          margin: '0 auto',
        }}
      />
      {children}
    </Popover>
  );
};

export default PopoverLayout;
