import { Outlet } from 'react-router-dom';

import Flexbox from 'components/flexbox/Flexbox';

const AuthLayout = (): JSX.Element => {
  return (
    <Flexbox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      p="0 30px"
      sx={{ backgroundColor: '#032620' }}
    >
      <Outlet />
    </Flexbox>
  );
};

export default AuthLayout;
