/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';

import LoadingScreen from 'pages/LoadingScreen';

import useSidebar from 'features/home/redux/sidebar/SidebarHook';
import EmptyScreen from 'components/emptyScreen/EmptyScreen';
import useFilterFetching from 'hooks/useFilterFetching';
import useMap from 'features/home/redux/map/MapHook';
import MapRoot from './Map.styled';

import { ADMIN_USER } from 'core/roles';
import AppConfig from 'config';

import { isEmptyArray, isEmptyObject, isNull } from 'utils/Validation';
import { SORT_DICTIONARY } from 'core/status';
import useMediaMatches from 'hooks/useMediaMatches';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import { sameCoordinates } from 'utils/Generic';

const MAP_KEY: string = 'AIzaSyDOtfqd9NKCDOlyyLJo6GJSQh7AM97oU4Q';
// const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;
const center: { lat: number; lng: number } = { lat: 20.5234, lng: 52.4501 };

const MapStyles: CSSProperties = {
  width: '100%',
  height: '100%',
};

var maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;

type TCoord = { lat: number; lng: number };

const MapComponent = (): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const [coord, setCoord] = useState<Array<TCoord> | null | any>(null);
  const [activeCoordinates, setActiveCoordinates] = useState({
    lng: '',
    lat: '',
  });
  const [mapConfigState, setMapConfigState] = useState<any>({
    filterCoordinates: null,
    filterZooming: null,
    fetching: false,
  });

  const isMobileFetchZooming: boolean = mobileSM && mapConfigState?.fetching;
  const mobileMinZoom = isMobileFetchZooming ? 3 : 1;
  const zoom: number = mobileSM ? 1 : 3;

  const MapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    minZoom: mobileSM ? mobileMinZoom : 3,
    restriction: {
      latLngBounds: { north: maxLat, south: -maxLat, west: -180, east: 180 },
      strictBounds: true,
    },
  };

  const {
    dispatcherGetTrips,
    fetchCoords,
    dispatcherGetTripBy,
    filters,
    mapState,
  } = useMap();
  const {
    open,
    dispatcherHandleSidebar,
    dispatcherSetListData,
    dispatcherSetRequest,
  } = useSidebar();

  const isEmptyCoord = coord !== null && isEmptyArray(coord);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    id: 'script-loader',
    libraries: ['places'],
    language: 'en',
    region: 'United States',
  });

  const fetcherTrips = (parameters: any) => {
    return dispatcherGetTrips(parameters)
      .unwrap()
      .then((response: any) => {
        const coordinatesResponse = response?.data;
        setCoord(coordinatesResponse);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherTripInfo = (parameters: any, address: any = '') => {
    let industriesLocale = [''];

    const localeIndustry: any = JSON.parse(
      localStorage.getItem('industry') || '',
    );

    if (localeIndustry && localeIndustry.length > 0) {
      industriesLocale = [...localeIndustry];
    }

    const payload = {
      query: {
        ...parameters,
      },
      data: {
        address: address || '',
        conferenceName: '',
        industries: industriesLocale,
      },
    };
    dispatcherGetTripBy(payload)
      .unwrap()
      .then((response: any) => {
        dispatcherSetListData(response?.data);
        dispatcherSetRequest(false);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const handleMarkerClick = (event: any) => {
    const latlng = { lng: event.latLng.lng(), lat: event.latLng.lat() };
    const coordinates = { lng: event.latLng.lng(), lat: event.latLng.lat() };

    const checkCoord: any = coord?.filter((item: any) => {
      return (
        item?.coordinates?.lat === latlng.lat &&
        item?.coordinates?.lng === latlng.lng
      );
    });

    const addressQuery =
      checkCoord.length > 0 && checkCoord[0].address
        ? `${checkCoord[0]?.address}`
        : null;

    let fetchParameters = {};

    if (
      coordinates.lng === activeCoordinates?.lng &&
      coordinates.lat === activeCoordinates?.lat
    ) {
      const nullCoordinates = { lng: '', lat: '' };
      setActiveCoordinates(nullCoordinates);
      fetchParameters = {
        address: addressQuery,
        page: 1,
        size: 50,
        sort: SORT_DICTIONARY.newest,
      };
    } else {
      setActiveCoordinates(coordinates);
      fetchParameters = {
        lng: event.latLng.lng(),
        lat: event.latLng.lat(),
        address: addressQuery,
        page: 1,
        size: 50,
        sort: SORT_DICTIONARY.newest,
      };
    }

    fetcherTripInfo(
      {
        params: { ...fetchParameters },
      },
      addressQuery,
    );
    dispatcherHandleSidebar(true);
  };

  const { fetcher, handleFiltering, pageNo, COUNT_LINE } =
    useFilterFetching(fetcherTrips);

  useEffect(() => {
    fetcher(pageNo, COUNT_LINE, filters);
    const fetchInterval = setInterval(() => {
      fetcher(pageNo, COUNT_LINE, filters, {
        sort: SORT_DICTIONARY.newest,
      });
    }, 30000);
    return () => {
      clearInterval(fetchInterval);
    };
  }, [filters, isLoaded]);

  useEffect(() => {
    if (fetchCoords) {
      fetcher(pageNo, COUNT_LINE, filters, {
        sort: SORT_DICTIONARY.newest,
      });
    }
  }, [isLoaded, fetchCoords]);

  useEffect(() => {
    handleFiltering(0, filters, {
      sort: SORT_DICTIONARY.newest,
    });
  }, [filters]);

  useEffect(() => {
    if (!open) {
      const nullCoordinates = { lng: '', lat: '' };
      setActiveCoordinates(nullCoordinates);
    }
  }, [open]);

  useEffect(() => {
    setMapConfigState(mapState);
  }, [mapState]);

  if (!isLoaded || isNull(coord)) return <LoadingScreen />;

  return (
    <MapRoot>
      <GoogleMap
        center={mapConfigState?.filterCoordinates || center}
        zoom={mapConfigState?.filterZooming || zoom}
        mapContainerStyle={MapStyles}
        options={{ ...MapOptions }}
      >
        {isEmptyCoord ? <EmptyScreen /> : null}
        {coord?.map((item: any, index: number) => {
          const count = item?.count;
          const stringCount = `${count}`;
          return !isEmptyObject(item?.coordinates) ? (
            <Fragment>
              {!item?.isOwner ? (
                <Marker
                  position={item?.coordinates}
                  onClick={handleMarkerClick}
                  label={stringCount}
                  zIndex={1 + index}
                  opacity={1}
                  icon={{
                    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                    fillColor: '#1976d2',
                    fillOpacity: 1,
                    scale: sameCoordinates(item?.coordinates, activeCoordinates)
                      ? 0.85
                      : 0.7,
                    scaledSize: new google.maps.Size(20, 20),
                    origin: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(0, -25),
                  }}
                  key={index}
                />
              ) : (
                <Marker
                  position={item?.coordinates}
                  onClick={handleMarkerClick}
                  zIndex={1 + index}
                  opacity={1}
                  label={stringCount}
                  icon={{
                    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                    fillColor: '#D22B2B',
                    fillOpacity: 1,
                    scale: sameCoordinates(item?.coordinates, activeCoordinates)
                      ? 0.85
                      : 0.7,
                    scaledSize: new google.maps.Size(20, 20),
                    origin: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(0, -25),
                  }}
                  key={index}
                />
              )}
            </Fragment>
          ) : null;
        })}
      </GoogleMap>
    </MapRoot>
  );
};

const Map = React.memo(MapComponent);
export default Map;
