import { Button, styled } from '@mui/material';

const FollowButtonRoot = styled(Button)(() => ({
  padding: '10px',
  marginTop: '20px',
  fontSize: '16px',
  lineHeight: '1.75',
  textTransform: 'none',
  color: '#fff',
  backgroundColor: '#E8A442',
  border: '1px solid transparent',
  boxShadow: 'unset',
  minWidth: '64px',
  width: '100%',
  opacity: 1,
  transition: 'all .3s',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#E8A442',
    opacity: 0.85,
    color: 'text.disabled',
    boxShadow: 'unset',
  },
})) as typeof Button;

export default FollowButtonRoot;
