import { Box } from '@mui/material';
import React from 'react';

import SimpleButton, { CancelButton } from './FormButton.styled';

import TFormButton from './types';

const FormButtonComponent = ({
  as,
  label,
  variant,
  color,
  onClick,
  fullWidth,
  acceptFiles,
  className,
  ...props
}: TFormButton): JSX.Element => {
  if (as === 'submit')
    return (
      <SimpleButton
        variant={variant}
        color={color}
        type="submit"
        className={className}
        onClick={onClick && onClick}
        fullWidth={fullWidth}
        {...props}
      >
        {label}
      </SimpleButton>
    );
  else if (as === 'upload') {
    return (
      <Box>
        <input
          accept={acceptFiles}
          id="button-file"
          type="file"
          style={{
            display: 'none',
          }}
        />
        <label htmlFor="button-file">
          <SimpleButton
            variant={variant}
            color={color}
            type="button"
            onClick={onClick && onClick}
            fullWidth={fullWidth}
            {...props}
          >
            {label}
          </SimpleButton>
        </label>
      </Box>
    );
  } else if (as === 'cancel') {
    return (
      <CancelButton
        variant={variant}
        color={color}
        type="button"
        onClick={onClick && onClick}
        fullWidth={fullWidth}
        {...props}
      >
        {label}
      </CancelButton>
    );
  }

  return (
    <SimpleButton
      variant={variant}
      color={color}
      type="button"
      onClick={onClick && onClick}
      fullWidth={fullWidth}
      {...props}
    >
      {label}
    </SimpleButton>
  );
};

const FormButton = React.memo(FormButtonComponent);
export default FormButton;
