import { CSSProperties, Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box } from '@mui/material';
import { toast } from 'react-hot-toast';
import EllipsisText from 'react-ellipsis-text';
import { AxiosError } from 'axios';

import Info from 'features/profile/components/info/Info';
import FollowButton from '../followButton/FollowButton';
import NewTripPopup from '../popups/NewTripPopup';
import Flexbox from 'components/flexbox/Flexbox';
import TripDetailRoot from './TripDetail.styled';
import { Heading } from 'components/typography';
import { ModalWindow } from 'components/window';

import useSidebar from 'features/home/redux/sidebar/SidebarHook';
import useMap from 'features/home/redux/map/MapHook';
import { useProfile } from 'features/profile';

import { TRIP_TYPE_DICTIONARY } from 'core/status';
import { UNREAD } from 'core/roles';
import AppConfig from 'config';

import LocaleStorage from 'api/LocaleStorage';
import { isSubstring } from 'utils/Validation';
import { API_URL } from 'api/API';

type TTripDetail = {
  data: Record<string, any>;
};

const linkStyles: CSSProperties = {
  fontSize: '16px',
  marginTop: '18px',
  fontWeight: 400,
  color: '#E8A442',
  lineHeight: 1.5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

const showContentStyles: CSSProperties = {
  fontSize: '14px',
  paddingRight: '3px',
  color: '#E8A442',
  lineHeight: 1.5,
  cursor: 'pointer',
};

const TripDetail = ({ data }: TTripDetail): JSX.Element => {
  const dateRange: string = `${data?.dateFrom} - ${data?.dateTo}`;
  const joinedTrip: boolean = data.isCurrentUserFollowing;

  const { dispatcherJoinTrip, dispatcherLeaveTrip, dispatcherFollowersTrip } =
    useMap();
  const { dispatcherHandleSidebar } = useSidebar();
  const { info } = useProfile();
  const navigate = useNavigate();

  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const role: string = LocaleStorage.get('role') || UNREAD;
  const isGuest = isAuth === UNREAD;
  const userId = info?.data?.userId || null;
  const tripOwnerId = data?.ownerId || null;
  const owner = data?.ownerRole || null;
  const ownerId = data.ownerId;
  const isActiveUserTrip =
    userId === tripOwnerId || role.toUpperCase() === owner;
  const tripId = data.id;

  const [joinedTripState, setJoinedTripState] = useState<boolean>(joinedTrip);
  const [newFollower, setNewFollower] = useState<number>(+data?.followersCount);
  const [openConfirmState, setOpenConfirmState] = useState<boolean>(false);
  const [openEditPopupState, setOpenEditPopupState] = useState<boolean>(false);
  const [showMoreState, setShowMoreState] = useState<boolean>(false);
  const [followersState, setFollowersState] = useState<any>([]);

  const imageApiUrl: string = isSubstring(data?.ownerProfileLink, 'http')
    ? `${data?.ownerProfileLink}`
    : `${API_URL}${data?.ownerProfileLink}`;

  // Fetchers
  const fetcherJoinTrip = (config: any) => {
    dispatcherJoinTrip(config)
      .unwrap()
      .then((response: any) => {
        setJoinedTripState((prevState: boolean) => !prevState);
        setNewFollower((prevState: number) => prevState + 1);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherLeaveTrip = (config: any) => {
    dispatcherLeaveTrip(config)
      .unwrap()
      .then((response: any) => {
        setOpenConfirmState(false);
        setJoinedTripState(false);
        setNewFollower((prevState: number) => prevState - 1);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherFollowersTrip = (config: any) => {
    dispatcherFollowersTrip(config)
      .unwrap()
      .then((response: any) => {
        const followers = response?.data?.followers;
        setFollowersState(followers);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const handleJoinTrip = (): void => {
    const config = {
      params: {
        tripId: data?.id,
      },
    };

    const followerConfig = {
      params: {
        tripId,
      },
    };

    if (joinedTripState) {
      setOpenConfirmState(true);
    } else {
      fetcherJoinTrip(config);
      setTimeout(() => fetcherFollowersTrip(followerConfig), 200);
    }
  };

  const handleLeaveTrip = () => {
    const config = {
      params: {
        tripId: data?.id,
      },
    };

    const followerConfig = {
      params: {
        tripId,
      },
    };

    fetcherLeaveTrip(config);
    setTimeout(() => fetcherFollowersTrip(followerConfig), 200);
  };

  const handleNavigateLink = (link: any) => (_event: any) => {
    const isLink = link.includes('http');
    if (isLink) window.location.href = link;
  };

  const handleCloseConfirm = (): void => {
    setOpenConfirmState(false);
  };

  const handleEditPopupState = (): void => {
    setOpenEditPopupState((prevState: boolean) => !prevState);
  };

  const handleShowMore = (): void => {
    setShowMoreState((prevState: boolean) => !prevState);

    if (!showMoreState) {
      const config = {
        params: {
          tripId,
        },
      };
      fetcherFollowersTrip(config);
    }
  };

  const handleNavigateToUser = (): void => {
    const url = `/user/${ownerId}`;
    navigate(url);
  };

  const handleNavigateToTrip = (): void => {
    const url = `/trip/${tripId}`;
    dispatcherHandleSidebar(false);
    navigate(url);
  };

  const handleFollowLink = (follower: any) => (_event: any) => {
    const urlLinkState = follower?.userProfileUrl;
    const url2 = urlLinkState.replace(/\/$/, '');
    const linkId = url2.split('/')[url2.split('/').length - 1];
    const link = `/user/${linkId}`;
    navigate(link);
    dispatcherHandleSidebar(false);
  };

  const renderConfirmModalButtons: JSX.Element = (
    <Fragment>
      <FollowButton label="No" onClick={handleCloseConfirm} success />
      <FollowButton label="Yes" onClick={handleLeaveTrip} />
    </Fragment>
  );

  const renderConfirmContent: JSX.Element = (
    <Flexbox
      flexDirection="column"
      alignItems="center"
      width="100%"
      gap="1rem 0"
    >
      <Heading
        as="h2"
        content="Are you sure?"
        textAlign="center"
        sx={{ color: '#fff' }}
      />
    </Flexbox>
  );

  const renderIndustry = data?.industries?.map((item: any, index: number) => {
    const lgth = data?.industries.length;
    if (lgth > 0 && index < lgth - 1) return <Fragment>{item}, </Fragment>;
    return <Fragment>{item}</Fragment>;
  });

  const renderName =
    data?.type === TRIP_TYPE_DICTIONARY.conference ? (
      <Info
        label="Conference Name"
        value={
          <EllipsisText
            title={data?.name}
            text={data?.conferenceName}
            length="30"
          />
        }
      />
    ) : (
      <Info
        label="Activity Name"
        value={
          <EllipsisText
            title={data?.name}
            text={data?.conferenceName}
            length="30"
          />
        }
      />
    );

  const renderTitle = (
    <Flexbox alignItems="center" justifyContent="space-between" ml="15px">
      <Heading
        as="h4"
        content={
          <EllipsisText title={data?.name} text={data?.name} length="20" />
        }
        fontWeight="400"
        fontSize="24px"
        ml="0.2rem"
      />
      <div onClick={handleNavigateToUser} style={{ cursor: 'pointer' }}>
        <Avatar src={imageApiUrl} sx={{ width: '60px', height: '60px' }} />
      </div>
    </Flexbox>
  );

  const renderShowMoreButton: JSX.Element | null =
    !isGuest && newFollower > 0 ? (
      <div onClick={handleShowMore} style={linkStyles}>
        (Show more)
      </div>
    ) : null;

  const renderContent: JSX.Element = (
    <Box mt="24px" ml="15px">
      <Info label="Owner" value={data?.ownerName} />
      <Info label="Company" value={data?.ownerCompany} />
      <Info label="Job Title" value={data?.ownerPosition} />
      <Info label="Industry" value={renderIndustry} />
      <Info
        label="Linkedin Profile"
        value={
          <EllipsisText
            title={data?.ownerLinkedProfileLink}
            text={data?.ownerLinkedProfileLink}
            length="30"
          />
        }
        link
      />
      {data?.eventLink && (
        <Info
          label="Event Link"
          value={
            <div
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleNavigateLink(data?.eventLink)}
            >
              <EllipsisText
                title={data?.eventLink}
                text={data?.eventLink}
                length="30"
              />
            </div>
          }
        />
      )}
      <Info label="Trip Dates" value={dateRange} />
      <Info label="Destination" value={data?.address} />
      {renderName}
      <Flexbox alignItems="center" gap="0 20px">
        <Info label="Followers" value={newFollower} />
        {renderShowMoreButton}
      </Flexbox>
    </Box>
  );

  const renderShowMoreContent: JSX.Element | null = showMoreState ? (
    <Flexbox gap="0 2px" mt="5px" flexWrap="wrap" ml="20px">
      {followersState?.map((follower: any, index: number) =>
        followersState.length === 1 || index === followersState.length - 1 ? (
          <div style={showContentStyles} onClick={handleFollowLink(follower)}>
            {follower?.name}
          </div>
        ) : (
          <div style={showContentStyles} onClick={handleFollowLink(follower)}>
            {follower?.name},
          </div>
        ),
      )}
    </Flexbox>
  ) : null;

  const renderButtons: JSX.Element = isActiveUserTrip ? (
    <Flexbox ml="20px" mt="40px" gap="20px" mb="15px">
      <FollowButton label="Edit Trip" success onClick={handleEditPopupState} />
      {!isGuest ? (
        <FollowButton label="Go to trip" onClick={handleNavigateToTrip} />
      ) : null}
    </Flexbox>
  ) : (
    <Flexbox ml="20px" mt="40px" gap="20px" mb="15px">
      <FollowButton label="Go to trip" success onClick={handleNavigateToTrip} />
      {!isGuest ? (
        <FollowButton
          label={joinedTripState ? 'Unfollow Trip' : 'Follow Trip'}
          active={joinedTripState}
          onClick={handleJoinTrip}
        />
      ) : null}
    </Flexbox>
  );

  return (
    <TripDetailRoot>
      {renderTitle}
      {renderContent}
      {renderShowMoreContent}
      {renderButtons}

      <ModalWindow
        title="Leave Trip Confirmation"
        onSubmit={handleLeaveTrip}
        onClose={handleCloseConfirm}
        open={openConfirmState}
        fields={renderConfirmContent}
        buttons={renderConfirmModalButtons}
      />

      <NewTripPopup
        title="Edit Trip"
        open={openEditPopupState}
        onClose={handleEditPopupState}
        data={data}
        edit
      />
    </TripDetailRoot>
  );
};

export default TripDetail;
