import React, { ReactNode } from 'react';

import ErrorTextRoot from './ErrorText.styled';

type TErrorText = {
  children: ReactNode | string | null;
};

const ErrorText = ({ children }: TErrorText) => {
  return <ErrorTextRoot>{children}</ErrorTextRoot>;
};

export default ErrorText;
