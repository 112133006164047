const convertNotification = (str: any) => {
  const symbol = 'See';

  const indexOfSymbol: any = str.indexOf(symbol);
  const newString: any = str.slice(0, indexOfSymbol);

  return newString;
};

export default convertNotification;
