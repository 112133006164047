import React from 'react';

import FollowButtonRoot from './FollowButton.styled';

import TFollowButtonProps from './types';
import palette from 'theme/theme';

const followButtonActive = {
  backgroundColor: 'transparent',
  color: '#E8A442',
  border: `1px solid #E8A442`,
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.85,
    boxShadow: 'unset',
    color: palette.red[100],
    border: `1px solid ${palette.red[100]}`,
  },
};

const successButton = {
  backgroundColor: 'transparent',
  color: '#ffffff',
  border: `1px solid #ffffff`,
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.85,
    boxShadow: 'unset',
    color: '#ffffff',
    border: `1px solid #ffffff`,
  },
};

const FollowButton = ({
  label,
  onClick,
  active,
  success,
  className,
  overrideStyles,
}: TFollowButtonProps) => {
  const activeStyles = active ? followButtonActive : {};
  const successStyles = success ? successButton : {};

  return (
    <FollowButtonRoot
      className={className}
      type="button"
      onClick={onClick}
      sx={{ ...activeStyles, ...successStyles, ...overrideStyles }}
    >
      {label}
    </FollowButtonRoot>
  );
};

export default FollowButton;
