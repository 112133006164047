import { Button, styled } from '@mui/material';
import React from 'react';

const StyledButton = styled(Button)(() => ({
  border: '1px solid #96E6B3',
  fontSize: '11px',
  textTransform: 'capitalize',
  color: '#fff',
  width: '110px',
  padding: '4px',
}));

const ConnectButton = ({ label, onClick }: any): JSX.Element => {
  return <StyledButton onClick={onClick}>{label}</StyledButton>;
};

export default ConnectButton;
