const MAP: string = 'map';
const MAP_CREATE_TRIP: string = 'map/createTrip';
const MAP_GET_TRIPS: string = 'map/getTrips';
const MAP_GET_TRIP_BY: string = 'map/getTripBy';
const MAP_GET_INDUSTRY: string = 'map/getIndustries';
const MAP_SHARE_TRIP: string = 'map/shareTrip';
const MAP_SHARE_POST: string = 'map/sharePost';
const MAP_JOIN_TRIP: string = 'map/joinTrip';
const MAP_LEAVE_TRIP: string = 'map/leaveTrip';
const MAP_GET_FOLLOWERS: string = 'map/getFollowers';
const MAP_GET_TRIP: string = 'map/getTrip';
const MAP_ADD_INDUSTRY: string = 'map/addIndustry';
const MAP_GET_NOTIFICATION: string = 'map/getNotification';
const MAP_MARK_NOTIFICATION: string = 'map/markNotification';
const MAP_DELETE_NOTIFICATIONS: string = 'map/deleteNotifications';

export {
  MAP,
  MAP_CREATE_TRIP,
  MAP_GET_TRIPS,
  MAP_GET_TRIP_BY,
  MAP_GET_INDUSTRY,
  MAP_SHARE_TRIP,
  MAP_SHARE_POST,
  MAP_JOIN_TRIP,
  MAP_LEAVE_TRIP,
  MAP_GET_FOLLOWERS,
  MAP_ADD_INDUSTRY,
  MAP_GET_TRIP,
  MAP_GET_NOTIFICATION,
  MAP_MARK_NOTIFICATION,
  MAP_DELETE_NOTIFICATIONS,
};
