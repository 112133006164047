import { alpha, Box, Card, styled } from '@mui/material';

import palette from 'theme/theme';

const StatisticCardRoot = styled(Card)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0 1rem',
  padding: '1rem 1.5rem',
  height: '100%',
  width: '100%',
  color: palette.blue[200],
  boxShadow: 'unset',
  border: `1px solid ${alpha(palette.blue[200], 0.1)}`,
})) as typeof Card;

const StatisticBox = styled(Box)(() => ({
  width: 60,
  height: 60,
  marginRight: 2,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
})) as typeof Box;

export { StatisticBox };
export default StatisticCardRoot;
