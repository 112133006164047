/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Box } from '@mui/material';

import { useAdminDashboard } from 'features/adminDashboard';
import LoadingScreen from './LoadingScreen';
import { Hero, useProfile } from 'features/profile';

import AppConfig from 'config';

import { isNull } from 'utils/Validation';
import { Heading } from 'components/typography';
import { GridDataTable } from 'components/dataTable';
import usePaginationFetching from 'hooks/usePaginationFetching';
import { conferencePreviewColumns } from 'features/profile/components/columns/Columns';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import useMediaMatches from 'hooks/useMediaMatches';
import Flexbox from 'components/flexbox/Flexbox';
import ArrowButton from 'components/arrowButton/ArrowButton';
import { HOME_ROUTE } from 'routes/core/RouterConstant';
import DataSetList from 'features/profile/components/dataSetList/DataSetList';

const TitleStyles: CSSProperties = {
  color: '#fff',
  fontSize: '18px',
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
};

const PrevGridTable = ({ id, isMobile }: { id: any; isMobile: boolean }) => {
  const [userTrips, setUserTrips] = useState<any>(null);
  const { dispatcherUserGetPrevTrips, fetchUser } = useProfile();

  const fetchUserPrevTrips = (parameters: any) => {
    return dispatcherUserGetPrevTrips(parameters)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserTrips(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { fetcher, pageNo, countLine } =
    usePaginationFetching(fetchUserPrevTrips);

  useEffect(() => {
    fetcher(pageNo, countLine, { id });
  }, [fetchUser]);

  if (isNull(userTrips)) return <LoadingScreen />;

  return isMobile ? (
    <DataSetList
      data={userTrips?.trips}
      pageNo={pageNo + 1}
      pageCount={userTrips?.pages}
      onPageChange={fetcher}
      title={`Previous Trips (${userTrips?.total})`}
    />
  ) : (
    <>
      <Heading
        as="h3"
        fontWeight="500"
        content={`Previous Trips (${userTrips?.total})`}
        sx={{ color: '#fff' }}
      />
      <GridDataTable
        data={userTrips?.trips}
        columns={conferencePreviewColumns(id, false)}
        pageNo={pageNo + 1}
        pageCount={userTrips?.pages}
        onPageChange={fetcher}
      />
    </>
  );
};

const PreviewProfile = () => {
  const [userProfileData, setUserProfileData] = useState<any>(null);
  const [userTrips, setUserTrips] = useState<any>(null);
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const { dispatcherGetUserProfile, adminInfo } = useAdminDashboard();
  const { fetchUser, dispatcherUserGetTrips, info } = useProfile();
  const { id } = useParams();

  /**
   * *@Fetcher functions
   */
  const fetchUserProfileForAdmin = (userId: string) => {
    const query = {
      params: {
        id: userId,
      },
    };

    dispatcherGetUserProfile(query)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserProfileData(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        // window.location.href = USER_LOGIN_ROUTE;
      });
  };

  const fetchUserTrips = (parameters: any) => {
    return dispatcherUserGetTrips(parameters)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserTrips(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { fetcher, pageNo, countLine } = usePaginationFetching(fetchUserTrips);

  /**
   * *@Side Effects
   */
  useEffect(() => {
    if (id) {
      fetchUserProfileForAdmin(id);
      fetcher(pageNo, countLine, { id });
    }
  }, [id, fetchUser]);

  const handlePageChange = (pageNumber: any) => {
    fetcher(pageNumber, countLine, { id });
  };

  const handleBackToHome = (): void => {
    window.location.href = HOME_ROUTE;
  };

  const getBoolId = (): boolean => {
    const numId = id && +id;
    return id === info?.data?.userId || numId === adminInfo?.data?.userId;
  };

  const renderDesktopVersion: JSX.Element = (
    <Box maxWidth="1380px" padding="0 15px" margin="0 auto" mt="100px">
      <Hero data={userProfileData} isRender={false} linked isPreview isUser />

      <Box pt={1} pb={4}>
        <Heading
          as="h3"
          fontWeight="500"
          content={`Active Trips (${userTrips?.total})`}
          sx={{ color: '#fff' }}
        />
        <GridDataTable
          data={userTrips?.trips}
          columns={conferencePreviewColumns(id, getBoolId())}
          pageNo={pageNo + 1}
          pageCount={userTrips?.pages}
          onPageChange={handlePageChange}
        />
      </Box>

      <Box pt={1} pb={4}>
        <PrevGridTable id={id} isMobile={false} />
      </Box>
    </Box>
  );

  const renderMobileVersion: JSX.Element = (
    <Box maxWidth="1380px" padding="0 15px" margin="0 auto" mt="70px">
      <Flexbox alignItems="center" mb="20px">
        <ArrowButton
          label=""
          onClick={handleBackToHome}
          sx={{ marginRight: 'auto' }}
        />
        <Heading
          sx={TitleStyles}
          textAlign="center"
          content="PROFILE"
          as="h1"
        />
      </Flexbox>

      <Hero data={userProfileData} isRender={false} linked isPreview isUser />

      <DataSetList
        data={userTrips?.trips}
        pageNo={pageNo + 1}
        pageCount={userTrips?.pages}
        onPageChange={fetcher}
        title={`Active Trips (${userTrips?.total})`}
      />

      <PrevGridTable id={id} isMobile />
    </Box>
  );

  /**
   * *@Renders functions
   */
  if (isNull(userProfileData) || isNull(userTrips)) return <LoadingScreen />;

  return mobileSM ? renderMobileVersion : renderDesktopVersion;
};

export default PreviewProfile;
