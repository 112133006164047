import FollowButton from 'features/home/components/followButton/FollowButton';
import EmptyScreenRoot from './EmptyScreen.styled';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';

import { useMap } from 'features/home';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import useMediaMatches from 'hooks/useMediaMatches';

const EmptyScreen = () => {
  const { dispatchSetClearFilter } = useMap();
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const handleClearClick = () => {
    dispatchSetClearFilter(true);
  };

  return (
    <EmptyScreenRoot sx={{ height: mobileSM ? '80vh' : '100vh' }}>
      <Flexbox flexDirection="column">
        <Heading as="h1" content="Trips not found!" />
        <FollowButton label="Clear filters" onClick={handleClearClick} />
      </Flexbox>
    </EmptyScreenRoot>
  );
};

export default EmptyScreen;
