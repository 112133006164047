import { delete_thunk, get_thunk, post_thunk } from 'api/API';

import {
  ADD_INDUSTRY_ENDPOINT,
  CREATE_TRIP_ENDPOINT,
  DELETE_NOTIFICATION_ENDPOINT,
  FOLLOWERS_TRIP_ENDPOINT,
  GET_INDUSTRY_ENDPOINT,
  GET_NOTIFICATION_ENDPOINT,
  GET_TRIPS_ENDPOINT,
  GET_TRIP_BY_ENDPOINT,
  GET_TRIP_ENDPOINT,
  JOIN_TRIP_ENDPOINT,
  LEAVE_TRIP_ENDPOINT,
  READ_NOTIFICATION_ENDPOINT,
  SHARE_TRIP_ENDPOINT,
  USER_SHARE_POST_ENDPOINT,
} from 'api/Endpoints';
import {
  MAP_ADD_INDUSTRY,
  MAP_CREATE_TRIP,
  MAP_DELETE_NOTIFICATIONS,
  MAP_GET_FOLLOWERS,
  MAP_GET_INDUSTRY,
  MAP_GET_NOTIFICATION,
  MAP_GET_TRIP,
  MAP_GET_TRIPS,
  MAP_GET_TRIP_BY,
  MAP_JOIN_TRIP,
  MAP_LEAVE_TRIP,
  MAP_MARK_NOTIFICATION,
  MAP_SHARE_POST,
  MAP_SHARE_TRIP,
} from './MapConstants';

const createTripAction = post_thunk(
  MAP_CREATE_TRIP,
  CREATE_TRIP_ENDPOINT,
  false,
);

const mapGetIndustryAction = get_thunk(
  MAP_GET_INDUSTRY,
  GET_INDUSTRY_ENDPOINT,
  false,
);

const mapGetTripsAction = post_thunk(MAP_GET_TRIPS, GET_TRIPS_ENDPOINT, false);

const mapGetTripByAction = post_thunk(
  MAP_GET_TRIP_BY,
  GET_TRIP_BY_ENDPOINT,
  false,
);

const mapShareTripAction = post_thunk(
  MAP_SHARE_TRIP,
  SHARE_TRIP_ENDPOINT,
  false,
);

const mapGetSharePostAction = get_thunk(
  MAP_SHARE_POST,
  USER_SHARE_POST_ENDPOINT,
  false,
);

const mapJoinTripAction = get_thunk(MAP_JOIN_TRIP, JOIN_TRIP_ENDPOINT, false);

const mapLeaveTripAction = get_thunk(
  MAP_LEAVE_TRIP,
  LEAVE_TRIP_ENDPOINT,
  false,
);

const mapFollowersTripAction = get_thunk(
  MAP_GET_FOLLOWERS,
  FOLLOWERS_TRIP_ENDPOINT,
  false,
);

const mapAddIndustryAction = post_thunk(
  MAP_ADD_INDUSTRY,
  ADD_INDUSTRY_ENDPOINT,
  false,
);

const mapGetNotificationAction = get_thunk(
  MAP_GET_NOTIFICATION,
  GET_NOTIFICATION_ENDPOINT,
  false,
);

const mapReadNotificationAction = get_thunk(
  MAP_MARK_NOTIFICATION,
  READ_NOTIFICATION_ENDPOINT,
  false,
);

const mapGetTripAction = get_thunk(MAP_GET_TRIP, GET_TRIP_ENDPOINT, false);

const mapDeleteNotifications = delete_thunk(
  MAP_DELETE_NOTIFICATIONS,
  DELETE_NOTIFICATION_ENDPOINT,
  false,
);

export {
  mapReadNotificationAction,
  mapGetNotificationAction,
  mapAddIndustryAction,
  mapGetSharePostAction,
  mapGetTripsAction,
  mapGetTripByAction,
  mapGetIndustryAction,
  mapShareTripAction,
  mapJoinTripAction,
  mapFollowersTripAction,
  mapLeaveTripAction,
  mapGetTripAction,
  mapDeleteNotifications,
};
export default createTripAction;
