import { Box, styled } from '@mui/material';

const PostBoxStyled = styled(Box)(() => ({
  padding: '10px',
  borderRadius: '6px',
  backgroundColor: '#EBEBF0',
  width: '100%',
  textarea: {
    padding: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    margin: '0',
    '&:hover': {
      border: 'none',
    },
  },
}));

export default PostBoxStyled;
