import { InputBase, styled } from '@mui/material';

const SelectInputRoot = styled(InputBase)(() => ({
  height: 35,
  width: '100%',
  maxWidth: '100%',
  fontSize: 12,
  fontWeight: 400,
  padding: '0 4px 0 16px',
  marginTop: '10px',
  marginBottom: '5px',
  borderRadius: '4px',
  color: 'text.primary',
  backgroundColor: '#ECEFF5',
  '& .MuiPopover-paper': {
    boxShadow: 'none',
  },
  '& .MuiInputBase-inputAdornedEnd': {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: '2px !important',
  },
  '& .MuiSelect-outlined.MuiInputBase-input': {
    minHeight: 'unset',
    height: 'unset',
    padding: 'unset',
  },
  '& > .MuiSelect-select': {
    paddingRight: '0 !important',
  },
}));

export default SelectInputRoot;
