const MEDIA_QUERIES_DICTIONARY = {
  MOBILE: '(max-width:400px)',
  MOBILE_SM: '(max-width:680px)',
  MOBILE_L: '(max-width:700px)',
  TABLE_SM: '(max-width:768px)',
  LAPTOP_M: '(max-width:1100px)',
  LAPTOP_HEIGHT: '(max-height: 800px)',
  DESKTOP_HEIGHT: '(max-height: 1000px)',
};

export default MEDIA_QUERIES_DICTIONARY;
