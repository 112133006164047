import { Fragment, useState } from 'react';
import { IconButton } from '@mui/material';

import TIteractiveCellProps from './types';

const IteractiveCell = ({
  row,
  IconComponent,
  ModalComponent,
  label,
  onLaunch,
  edit,
}: TIteractiveCellProps): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModal = (): void => {
    setOpenModal((prev: boolean) => !prev);
  };

  const bindIconButtonClick = edit ? handleModal : onLaunch;

  const renderModal: JSX.Element | null =
    edit && ModalComponent ? (
      <ModalComponent
        title={label}
        open={openModal}
        onClose={handleModal}
        data={row}
        edit={edit}
      />
    ) : null;

  return (
    <Fragment>
      <IconButton onClick={bindIconButtonClick}>
        <IconComponent />
      </IconButton>

      {renderModal}
    </Fragment>
  );
};

export default IteractiveCell;
