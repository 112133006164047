// import { UNREAD } from 'core/roles';

import { urlWithQueryFormatter } from 'utils/Formatter';

const LINKEDIN_URL: string = 'https://www.linkedin.com/oauth/v2/authorization';
const LINKEDIN_SCOPE: string =
  process.env.REACT_APP_LINKED_SCOPE ||
  'r_1st_connections_size r_ads r_ads_reporting r_basicprofile r_emailaddress r_liteprofile r_organization_admin r_organization_social rw_ads rw_organization_admin w_member_social w_organization_social';
const LINKEDIN_REDIRECT: string = `${window.location.origin}/callback`;
const LINKEDIN_CLIENT_ID: string =
  process.env.REACT_APP_CLIENT_ID || '77n9798ssazp8r';
const LINKEDIN_STATE: string = process.env.REACT_APP_LINKED_STATE || '123456';

const LINKEDIN_AUTORIZATION_URL: string = urlWithQueryFormatter(LINKEDIN_URL, {
  response_type: 'code',
  client_id: LINKEDIN_CLIENT_ID,
  redirect_uri: LINKEDIN_REDIRECT,
  state: LINKEDIN_STATE,
  scope: LINKEDIN_SCOPE,
});

export {
  LINKEDIN_URL,
  LINKEDIN_SCOPE,
  LINKEDIN_REDIRECT,
  LINKEDIN_CLIENT_ID,
  LINKEDIN_STATE,
};
export default LINKEDIN_AUTORIZATION_URL;
