/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Box } from '@mui/material';

import DataSetList from 'features/profile/components/dataSetList/DataSetList';
import ArrowButton from 'components/arrowButton/ArrowButton';
import { useAdminDashboard } from 'features/adminDashboard';
import { GridDataTable } from 'components/dataTable';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';
import LoadingScreen from './LoadingScreen';
import {
  conferenceColumns,
  Hero,
  InfoPopups,
  prevConferenceColumns,
  useProfile,
} from 'features/profile';

import usePaginationFetching from 'hooks/usePaginationFetching';
import useMediaMatches from 'hooks/useMediaMatches';

import LocaleStorage from 'api/LocaleStorage';
import { ADMIN, UNREAD } from 'core/roles';
import AppConfig from 'config';

import { ADMIN_DASHBOARD_ROUTE, HOME_ROUTE } from 'routes/core/RouterConstant';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

import { isNull } from 'utils/Validation';

const TitleStyles: CSSProperties = {
  color: '#fff',
  fontSize: '18px',
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
};

const PrevGridTable = ({ id, isMobile }: { id: any; isMobile: boolean }) => {
  const [userTrips, setUserTrips] = useState<any>(null);
  const role: string = LocaleStorage.get('role') || 'unread';
  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const isAdmin = role === ADMIN;
  const isGuest = isAuth === UNREAD;

  const { dispatcherUserGetPrevTrips, fetchUser } = useProfile();

  const fetchUserPrevTrips = (parameters: any) => {
    return dispatcherUserGetPrevTrips(parameters)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserTrips(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { fetcher, pageNo, countLine } =
    usePaginationFetching(fetchUserPrevTrips);

  useEffect(() => {
    if (!isAdmin && !isGuest) fetcher(pageNo, countLine);
  }, [fetchUser]);

  if (isNull(userTrips)) return <LoadingScreen />;

  return isMobile ? (
    <DataSetList
      data={userTrips?.trips}
      pageNo={pageNo + 1}
      pageCount={userTrips?.pages}
      onPageChange={fetcher}
      title={`Previous Trips (${userTrips?.total})`}
    />
  ) : (
    <>
      <Heading
        as="h3"
        fontWeight="500"
        content={`Previous Trips (${userTrips?.total})`}
        sx={{ color: '#fff' }}
      />
      <GridDataTable
        data={userTrips?.trips}
        columns={prevConferenceColumns(id)}
        pageNo={pageNo + 1}
        pageCount={userTrips?.pages}
        onPageChange={fetcher}
      />
    </>
  );
};

const Profile = (): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [userProfileData, setUserProfileData] = useState<any>(null);
  const [userTrips, setUserTrips] = useState<any>(null);

  const { dispatcherGetUserProfile } = useAdminDashboard();
  const {
    dispatcherUserProfile,
    dispatchSetFetcherUser,
    fetchUser,
    dispatcherUserGetTrips,
  } = useProfile();
  const { id } = useParams();

  const role: string = LocaleStorage.get('role') || 'unread';
  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const isAdmin = role === ADMIN;
  const isGuest = isAuth === UNREAD;

  /**
   * *@Fetcher functions
   */
  const fetchUserProfileForAdmin = (userId: string) => {
    const payload = {
      query: {
        params: {
          id: userId,
        },
      },
    };

    dispatcherGetUserProfile(payload)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserProfileData(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        window.location.href = ADMIN_DASHBOARD_ROUTE;
      });
  };

  const fetchUserProfile = () => {
    dispatcherUserProfile({})
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserProfileData(response.data);
        dispatchSetFetcherUser(false);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        localStorage.clear();
        // window.location.href = USER_LOGIN_ROUTE;
      });
  };

  const fetchUserTrips = (parameters: any) => {
    return dispatcherUserGetTrips(parameters)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserTrips(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { fetcher, pageNo, countLine } = usePaginationFetching(fetchUserTrips);

  /**
   * *@Handler functions
   */
  const handleEditModal = (): void => {
    setOpenEditModal((prevState: boolean) => !prevState);
  };

  const handleBackToHome = (): void => {
    window.location.href = HOME_ROUTE;
  };

  /**
   * *@Side Effects
   */
  useEffect(() => {
    if ((isAdmin && id) || (isGuest && id)) {
      fetchUserProfileForAdmin(id);
    } else {
      fetchUserProfile();
      fetcher(pageNo, countLine);
    }
  }, [id, fetchUser]);

  /**
   * *@Renders functions
   */
  if (!isAdmin && !isGuest && isNull(userTrips)) return <LoadingScreen />;
  if (isNull(userProfileData)) return <LoadingScreen />;

  const renderTables: JSX.Element | null =
    !isAdmin && !isGuest ? (
      <Fragment>
        <Box pt={1} pb={4}>
          <Heading
            as="h3"
            fontWeight="500"
            content={`Active Trips (${userTrips?.total})`}
            sx={{ color: '#fff' }}
          />
          <GridDataTable
            data={userTrips?.trips}
            columns={conferenceColumns(id)}
            pageNo={pageNo + 1}
            pageCount={userTrips?.pages}
            onPageChange={fetcher}
          />
        </Box>

        <Box pt={1} pb={4}>
          <PrevGridTable id={id} isMobile={false} />
        </Box>
      </Fragment>
    ) : null;

  const renderDesktopVersion: JSX.Element = (
    <Box maxWidth="1380px" padding="0 15px" margin="0 auto" mt="100px">
      <Hero
        data={userProfileData}
        onEditProfile={handleEditModal}
        isRender={!isAdmin && !isGuest}
      />

      {renderTables}

      <InfoPopups
        title="Profile Information"
        data={userProfileData}
        open={openEditModal}
        onClose={handleEditModal}
      />
    </Box>
  );

  const renderMobileVersion: JSX.Element = (
    <Box maxWidth="1380px" padding="0 15px" margin="0 auto" mt="70px">
      <Flexbox alignItems="center" mb="20px">
        <ArrowButton
          label=""
          onClick={handleBackToHome}
          sx={{ marginRight: 'auto' }}
        />
        <Heading
          sx={TitleStyles}
          textAlign="center"
          content="PROFILE"
          as="h1"
        />
        <SettingsIcon
          onClick={handleEditModal}
          sx={{
            color: '#fff',
            cursor: 'pointer',
            width: '30px',
            height: '30px',
          }}
        />
      </Flexbox>
      <Hero
        data={userProfileData}
        onEditProfile={handleEditModal}
        isRender={!isAdmin && !isGuest}
      />

      {!isAdmin && !isGuest && (
        <DataSetList
          data={userTrips?.trips}
          pageNo={pageNo + 1}
          pageCount={userTrips?.pages}
          onPageChange={fetcher}
          title={`Active Trips (${userTrips?.total})`}
        />
      )}

      {!isAdmin && !isGuest && <PrevGridTable id={id} isMobile />}

      <InfoPopups
        title="Profile Information"
        data={userProfileData}
        open={openEditModal}
        onClose={handleEditModal}
      />
    </Box>
  );

  return mobileSM ? renderMobileVersion : renderDesktopVersion;
};

export default Profile;
