import { CSSProperties } from 'react';

import { AddButton } from 'layouts/homeLayout/components';
import Flexbox from 'components/flexbox/Flexbox';
import { FormButton } from 'components/controls';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

interface IMediaTripActions {
  onAddTrip: (event?: any) => void;
  onOpenTrips: (event?: any) => void;
}

const flexboxStyles: CSSProperties = {
  position: 'absolute',
  top: '130px',
  right: '40px',
  zIndex: 1000,
};

const MediaTripActions = ({
  onAddTrip,
  onOpenTrips,
}: IMediaTripActions): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const renderAddTripButton: JSX.Element = (
    <FormButton
      as="simple"
      variant="contained"
      label="Create a Trip"
      onClick={onAddTrip}
      fullWidth
      sx={{ minWidth: '170px' }}
    />
  );

  const renderShowListButton: JSX.Element = (
    <FormButton
      as="simple"
      variant="contained"
      label="Find Trip"
      onClick={onOpenTrips}
      fullWidth
      sx={{ minWidth: '170px' }}
    />
  );

  return !mobileSM ? (
    <Flexbox flexDirection="column" gap="1rem 0" sx={flexboxStyles}>
      {renderAddTripButton}
      {renderShowListButton}
    </Flexbox>
  ) : (
    <AddButton onClick={onAddTrip} />
  );
};

export default MediaTripActions;
