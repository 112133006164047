import AddIcon from '@mui/icons-material/Add';

import AddButtonRoot from './styled';

interface IAddButton {
  onClick: (event?: any) => void;
}

const AddButton = ({ onClick }: IAddButton): JSX.Element => {
  return (
    <AddButtonRoot onClick={onClick}>
      <AddIcon />
    </AddButtonRoot>
  );
};

export default AddButton;
