/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box } from '@mui/material';
import { toast } from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';
import EllipsisText from 'react-ellipsis-text';

import FollowButton from 'features/home/components/followButton/FollowButton';
import { NewTripPopup, useMap, useSidebar } from 'features/home';
import {
  DetailHeader,
  ImageBox,
  Text,
  TextBox,
} from 'features/trip/components/tripLayout/TripLayout';
import Flexbox from 'components/flexbox/Flexbox';
import { TripLayoutRoot } from 'features/trip';
import LoadingScreen from './LoadingScreen';

import { Hero, useProfile } from 'features/profile';

import LocaleStorage from 'api/LocaleStorage';
import { ADMIN, UNREAD } from 'core/roles';
import AppConfig from 'config';

import FollowersIcon from 'assets/icons/FollowersIcon';
import LinkedIcon from 'assets/icons/LinkedIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import { API_URL } from 'api/API';
import { isSubstring } from 'utils/Validation';
import useMediaMatches from 'hooks/useMediaMatches';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import ArrowButton from 'components/arrowButton/ArrowButton';
import { Heading } from 'components/typography';
import { HOME_ROUTE, USER_LOGIN_ROUTE } from 'routes/core/RouterConstant';
import { useAdminDashboard } from 'features/adminDashboard';
import { ModalWindow } from 'components/window';
import UnauthorizedContent from 'components/unauthorizedContent/UnauthorizedContent';
import ConnectButton from 'components/connectButton/ConnectButton';

const showContentStyles: CSSProperties = {
  fontSize: '14px',
  color: '#E8A442',
  lineHeight: 1.5,
  cursor: 'pointer',
  paddingRight: '4px',
};

const descStyles = { fontSize: '18px', fontWeight: 500 };
const descMobileStyles = { fontSize: '14px', fontWeight: 500 };

const subDescStyles = {
  fontSize: '24px',
  fontWeight: 500,
  color: '#fff',
  maxWidth: '400px',
  wordBreak: 'break-word',
};
const subMopbileDescStyles = {
  fontSize: '14px',
  fontWeight: 500,
  color: '#fff',
};

const TitleStyles: CSSProperties = {
  color: '#fff',
  fontSize: '18px',
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
};

const Trip = () => {
  const { id } = useParams();
  const [mobileSM, laptopM] = useMediaMatches(
    MEDIA_QUERIES_DICTIONARY.MOBILE_SM,
    MEDIA_QUERIES_DICTIONARY.LAPTOP_M,
  );

  const {
    dispatcherGetTrip,
    dispatcherFollowersTrip,
    dispatcherJoinTrip,
    dispatcherLeaveTrip,
  } = useMap();
  const navigate = useNavigate();
  const { isRequest } = useSidebar();

  const [tripDataState, setTripDataState] = useState<any>(null);
  const [showMoreState, setShowMoreState] = useState<boolean>(false);
  const [joinedTripState, setJoinedTripState] = useState<boolean>(false);
  const [openConfirmState, setOpenConfirmState] = useState<boolean>(false);
  const [userProfileData, setUserProfileData] = useState<any>(null);
  const [followersState, setFollowersState] = useState<any>([]);
  const { info, fetchUser } = useProfile();
  const { dispatcherGetUserProfile } = useAdminDashboard();
  const [openSignWindowState, setOpenSignWindowState] =
    useState<boolean>(false);

  const role: string = LocaleStorage.get('role') || UNREAD;
  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const isGuest = isAuth === UNREAD;
  const tripOwnerId = tripDataState?.ownerId || null;
  const userId = info?.data?.userId || null;
  const owner = tripDataState?.ownerRole || null;
  const onwerId = tripDataState?.ownerId;
  const tripId = tripDataState?.id;

  const imageApiUrl: string = isSubstring(
    tripDataState?.ownerProfileLink,
    'http',
  )
    ? `${tripDataState?.ownerProfileLink}`
    : `${API_URL}${tripDataState?.ownerProfileLink}`;

  const isActiveUserTrip =
    userId === tripOwnerId || role.toUpperCase() === owner;

  const [openEditPopupState, setOpenEditPopupState] = useState<boolean>(false);

  const getParams = {
    params: {
      id,
    },
  };

  const fetcherFollowersTrip = (config: any) => {
    dispatcherGetTrip(config)
      .unwrap()
      .then((response: any) => {
        const data = response?.data;
        setTripDataState(data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherFollowers = (config: any) => {
    dispatcherFollowersTrip(config)
      .unwrap()
      .then((response: any) => {
        const followers = response?.data?.followers;
        setFollowersState(followers);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherJoinTrip = (config: any) => {
    dispatcherJoinTrip(config)
      .unwrap()
      .then((response: any) => {
        setJoinedTripState((prevState: boolean) => !prevState);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherLeaveTrip = (config: any) => {
    dispatcherLeaveTrip(config)
      .unwrap()
      .then((response: any) => {
        setOpenConfirmState(false);
        setJoinedTripState(false);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetchUserProfileForAdmin = (id: string) => {
    const payload = {
      params: {
        id,
      },
    };

    dispatcherGetUserProfile(payload)
      .unwrap()
      .then((response: AxiosResponse) => {
        setUserProfileData(response.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        window.location.href = USER_LOGIN_ROUTE;
      });
  };

  const handleLinkOwner = () => {
    const owner = tripDataState?.ownerId;
    const url = `/user/${owner}`;
    navigate(url);
  };

  const handleEditPopupState = (): void => {
    setOpenEditPopupState((prevState: boolean) => !prevState);
  };

  const handleFollowLink = (follower: any) => (_event: any) => {
    const urlLinkState = follower?.userProfileUrl;
    const url2 = urlLinkState.replace(/\/$/, '');
    const linkId = url2.split('/')[url2.split('/').length - 1];
    const link = `/user/${linkId}`;
    navigate(link);
  };

  const handleShowMore = (): void => {
    setShowMoreState((prevState: boolean) => !prevState);

    if (!showMoreState) {
      const config = {
        params: {
          tripId,
        },
      };
      fetcherFollowers(config);
    }
  };

  const handleSignWindow = () => {
    setOpenSignWindowState((prevState: boolean) => !prevState);
  };

  const handleEventNavigate = (link: any) => (_event: any) => {
    const isLink = link.includes('http');
    if (isLink) window.location.href = link;
  };

  const handleSignRedirect = () => {
    navigate(USER_LOGIN_ROUTE);
  };

  const handleBackToHome = (): void => {
    window.location.href = HOME_ROUTE;
  };

  const handleJoinTrip = (): void => {
    if (isGuest) {
      handleSignWindow();
    } else {
      const config = {
        params: {
          tripId: tripDataState?.id,
        },
      };

      const followerConfig = {
        params: {
          id: tripDataState?.id,
        },
      };

      if (tripDataState?.isCurrentUserFollowing || joinedTripState) {
        fetcherLeaveTrip(config);
        setTimeout(() => fetcherFollowersTrip(followerConfig), 200);
      } else {
        fetcherJoinTrip(config);
        setTimeout(() => fetcherFollowersTrip(followerConfig), 200);
      }
    }
  };

  const handleLinkedin = () => {
    const url = `http://${tripDataState?.ownerLinkedProfileLink}` || '';
    window.open(url, '_blank');
  };

  const renderShowMoreContent: JSX.Element | null = showMoreState ? (
    <Flexbox gap="0 2px" mt="5px" mb="10px" flexWrap="wrap">
      {followersState?.map((follower: any, index: number) =>
        followersState.length === 1 || index === followersState.length - 1 ? (
          <div style={showContentStyles} onClick={handleFollowLink(follower)}>
            {follower?.name}
          </div>
        ) : (
          <div style={showContentStyles} onClick={handleFollowLink(follower)}>
            {follower?.name},{' '}
          </div>
        ),
      )}
    </Flexbox>
  ) : null;

  const renderIndustry = tripDataState?.industries?.map(
    (item: any, index: number) => {
      const lgth = tripDataState?.industries.length;
      if (lgth > 0 && index < lgth - 1) return <Fragment>{item}, </Fragment>;
      return <Fragment>{item}</Fragment>;
    },
  );

  // --------------------------------------------------------------------------------------------------------------
  const renderTitle = (
    <Box
      textAlign="center"
      position="relative"
      sx={{
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageBox sx={{ backgroundImage: `url(${tripDataState?.image})` }} />
      <Box zIndex={10} position="relative">
        <Text sx={{ fontSize: '24px', color: '#96E6B3' }}>Trip Name</Text>
        <Text sx={{ fontSize: '40px', fontWeight: 600 }}>
          <EllipsisText
            title={tripDataState?.name}
            text={tripDataState?.name}
            length="30"
          />
        </Text>
      </Box>
    </Box>
  );

  const renderOwnerInfo = (
    <Flexbox gap="20px">
      <Box mt="20px">
        <Avatar sx={{ width: '80px', height: '80px' }} src={imageApiUrl} />
      </Box>

      <Box>
        <DetailHeader>Trip owner</DetailHeader>
        <Text
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            marginTop: '16px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleLinkOwner}
        >
          {tripDataState?.ownerName}
        </Text>
        <Text>{tripDataState?.ownerPosition}</Text>

        <Box mt="10px">
          <Flexbox alignItems="center" gap="6px">
            <InfoIcon />
            <Text>Industry: {tripDataState?.ownerIndustry}</Text>
          </Flexbox>

          <Flexbox alignItems="center" gap="6px">
            <InfoIcon />
            <Text>Company: {tripDataState?.ownerCompany}</Text>
          </Flexbox>
        </Box>

        <Flexbox mt="10px" alignItems="center" gap="10px">
          <div style={{ cursor: 'pointer' }} onClick={handleLinkOwner}>
            <LinkedIcon />
          </div>
          <ConnectButton label="Connect" onClick={handleLinkedin} />
        </Flexbox>
      </Box>
    </Flexbox>
  );

  const renderEditOrFollowButton = isActiveUserTrip ? (
    <FollowButton
      label={mobileSM ? 'Edit Trip' : 'Edit'}
      active={!mobileSM}
      onClick={handleEditPopupState}
      overrideStyles={{ marginLeft: 'auto', marginTop: 0 }}
    />
  ) : (
    <FollowButton
      label={
        tripDataState?.isCurrentUserFollowing || joinedTripState
          ? 'Unfollow Trip'
          : 'Follow Trip'
      }
      active={tripDataState?.isCurrentUserFollowing || joinedTripState}
      onClick={handleJoinTrip}
    />
  );

  const renderSignButtons: JSX.Element = (
    <Fragment>
      <Flexbox flexDirection="column" gap="0.5rem 0" width="100%" mt="30px">
        <Flexbox justifyContent="center" gap="4rem">
          <FollowButton success label="Cancel" onClick={handleSignWindow} />
          <FollowButton onClick={handleSignRedirect} label="Sign In" />
        </Flexbox>
      </Flexbox>
    </Fragment>
  );

  const renderSignPopup: JSX.Element = (
    <ModalWindow
      title=""
      open={openSignWindowState}
      onClose={handleSignWindow}
      onSubmit={handleSignRedirect}
      fields={<UnauthorizedContent />}
      buttons={renderSignButtons}
    />
  );

  const renderTripInfo = (
    <Box mt="100px">
      <Flexbox alignItems="center" gap="6px" mb="10px">
        <FollowersIcon />
        <Text
          sx={{ color: '#E8A442', cursor: 'pointer' }}
          onClick={handleShowMore}
        >
          ({tripDataState?.followersCount})
        </Text>
      </Flexbox>

      {renderShowMoreContent}
      <TextBox>
        <Flexbox gap="30px" flexDirection="column">
          <Box>
            <DetailHeader sx={descStyles}>Destination</DetailHeader>
            <DetailHeader sx={subDescStyles}>
              {tripDataState?.address}
            </DetailHeader>
          </Box>

          <Box>
            <DetailHeader sx={descStyles}>Industry</DetailHeader>
            <DetailHeader sx={subDescStyles}>{renderIndustry}</DetailHeader>
          </Box>

          <Box>
            <DetailHeader sx={descStyles}>Trip Dates</DetailHeader>
            <DetailHeader sx={subDescStyles}>
              {tripDataState?.dateFrom} - {tripDataState?.dateTo}
            </DetailHeader>
          </Box>

          <Box>
            <DetailHeader sx={descStyles}>
              {tripDataState?.type === 'OTHER_ACTIVITY'
                ? 'Activity'
                : 'Conference'}
            </DetailHeader>
            <DetailHeader sx={subDescStyles}>
              {tripDataState?.conferenceName}
            </DetailHeader>
          </Box>
        </Flexbox>

        <Box>
          {tripDataState?.eventLink && (
            <Box mb="30px">
              <DetailHeader sx={descStyles}>Link to Event</DetailHeader>
              <DetailHeader
                sx={{
                  ...subDescStyles,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleEventNavigate(tripDataState?.eventLink)}
              >
                {tripDataState?.eventLink}
              </DetailHeader>
            </Box>
          )}

          {tripDataState?.description && (
            <Box sx={{ flex: '1 1 0%' }}>
              <DetailHeader sx={descStyles}>Description</DetailHeader>
              <DetailHeader sx={subDescStyles}>
                {tripDataState?.description}
              </DetailHeader>
            </Box>
          )}
        </Box>
      </TextBox>
    </Box>
  );

  const renderDesktopVersion: JSX.Element = (
    <TripLayoutRoot>
      {renderTitle}
      <Flexbox justifyContent="space-between" mt="40px">
        {renderOwnerInfo}
        <Box width="200px" mt="-20px">
          {renderEditOrFollowButton}
        </Box>
      </Flexbox>
      {renderTripInfo}
      <NewTripPopup
        title="Edit Trip"
        open={openEditPopupState}
        onClose={handleEditPopupState}
        data={tripDataState}
        edit
      />
      {renderSignPopup}
    </TripLayoutRoot>
  );

  const renderMobileVersion: JSX.Element = (
    <Box maxWidth="1380px" padding="0 15px" margin="0 auto" mt="70px">
      <Flexbox alignItems="center" mb="20px">
        <ArrowButton
          label=""
          onClick={handleBackToHome}
          sx={{ marginRight: 'auto' }}
        />
        <Heading
          sx={TitleStyles}
          textAlign="center"
          content={
            <EllipsisText
              title={tripDataState?.name}
              text={tripDataState?.name}
              length="30"
            />
          }
          as="h1"
        />
      </Flexbox>
      <Box
        textAlign="center"
        position="relative"
        sx={{
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageBox
          sx={{
            opacity: '100%',
            backgroundImage: `url(${tripDataState?.image})`,
            height: '200px',
          }}
        />
      </Box>

      <Hero
        data={userProfileData || []}
        isPreview
        isRender={false}
        renderImage={false}
        isUser
      />

      <Box mt="10px">
        <Flexbox alignItems="center" gap="6px" mb="10px">
          <FollowersIcon />
          <Text
            sx={{ color: '#E8A442', cursor: 'pointer' }}
            onClick={handleShowMore}
          >
            ({tripDataState?.followersCount})
          </Text>
        </Flexbox>

        {renderShowMoreContent}
        <TextBox sx={{ padding: '20px' }}>
          <Flexbox
            gap="20px"
            flexDirection="column"
            textAlign="center"
            width="100%"
          >
            <Box>
              <DetailHeader sx={descMobileStyles}>Destination</DetailHeader>
              <DetailHeader sx={subMopbileDescStyles}>
                {tripDataState?.address}
              </DetailHeader>
            </Box>

            <Box>
              <DetailHeader sx={descMobileStyles}>Industry</DetailHeader>
              <DetailHeader sx={subMopbileDescStyles}>
                {renderIndustry}
              </DetailHeader>
            </Box>

            <Box>
              <DetailHeader sx={descMobileStyles}>Trip Dates</DetailHeader>
              <DetailHeader sx={subMopbileDescStyles}>
                {tripDataState?.dateFrom} - {tripDataState?.dateTo}
              </DetailHeader>
            </Box>

            <Box>
              <DetailHeader sx={descMobileStyles}>
                {tripDataState?.type === 'OTHER_ACTIVITY'
                  ? 'Activity'
                  : 'Conference'}
              </DetailHeader>
              <DetailHeader sx={subMopbileDescStyles}>
                {tripDataState?.conferenceName}
              </DetailHeader>
            </Box>

            {tripDataState?.eventLink && (
              <Box>
                <DetailHeader sx={descMobileStyles}>Link to Event</DetailHeader>
                <DetailHeader
                  sx={{
                    ...subMopbileDescStyles,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={handleEventNavigate(tripDataState?.eventLink)}
                >
                  {tripDataState?.eventLink}
                </DetailHeader>
              </Box>
            )}

            {tripDataState?.description && (
              <Box sx={{ flex: '1 1 0%' }}>
                <DetailHeader sx={descMobileStyles}>Description</DetailHeader>
                <DetailHeader sx={subMopbileDescStyles}>
                  {tripDataState?.description}
                </DetailHeader>
              </Box>
            )}
          </Flexbox>
        </TextBox>
      </Box>

      <Box width="100%" mt="10px" mb="20px">
        {renderEditOrFollowButton}
      </Box>

      <NewTripPopup
        title="Edit Trip"
        open={openEditPopupState}
        onClose={handleEditPopupState}
        data={tripDataState}
        edit
      />
      {renderSignPopup}
    </Box>
  );

  useEffect(() => {
    fetcherFollowersTrip(getParams);
  }, [isRequest]);

  useEffect(() => {
    if (onwerId) {
      fetchUserProfileForAdmin(onwerId);
    }
  }, [onwerId, fetchUser]);

  if (tripDataState === null) return <LoadingScreen />;

  return mobileSM ? renderMobileVersion : renderDesktopVersion;
};

export default Trip;
