import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ClearIcon from '@mui/icons-material/Clear';
import { FormHelperText } from '@mui/material';
import { CSSProperties } from 'react';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import TDatePickerProps from './types';

import useMediaMatches from 'hooks/useMediaMatches';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';

const borderStyles = {
  border: '1px solid #000',
  borderRadius: '4px',
  '& .MuiOutlinedInput-root': {
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
};

const ClearIconStyles: CSSProperties = {
  opacity: 0.38,
  cursor: 'pointer',
  zIndex: 100,
  position: 'absolute',
  right: '15px',
};

const DatePick = ({
  value,
  onChange,
  error,
  helperText,
  pickerStyles,
  border,
  startLabel = 'Starting Date',
  endLabel = 'Ending Date',
}: TDatePickerProps): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const dateInputStyles = border
    ? { ...pickerStyles, ...borderStyles }
    : { ...pickerStyles };

  const handleClearClick = (): void => {
    onChange(['', '']);
  };

  const renderEndAdornment: JSX.Element | null = value ? (
    <ClearIcon sx={ClearIconStyles} onClick={handleClearClick} />
  ) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateRangePicker
        value={value}
        onChange={onChange}
        calendars={mobileSM ? 1 : 2}
        sx={{
          marginTop: mobileSM ? '10px' : '12px',
          width: '100%',
          label: {
            color: '#fff !important',
            fontSize: mobileSM ? '13px' : '16px',
            transform: 'unset !important',
            fontWeight: 500,
            top: '-30px',
          },
          input: {
            textAlign: 'center',
            color: value[0] && value[1] ? '#000' : '#8E8E93',
            padding: '20px',
            fontSize: mobileSM ? '13px' : '14px',
            fontWeight: '400',
            backgroundColor: '#EBEBF0',
            border: 'none',
            borderRadius: '6px',
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#EBEBF0',
          },
          '& .MuiInputLabel-root': {
            zIndex: 1,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: '6px',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: '#fff',
            fontSize: mobileSM ? '13px' : '16px',
            transform: 'unset !important',
            fontWeight: 500,
            top: mobileSM ? '-25px' : '-30px',
          },
          ...dateInputStyles,
        }}
        format="DD/MM/YYYY"
        slotProps={{
          textField: ({ position }) => ({
            InputProps: {
              endAdornment: renderEndAdornment,
              error: error,
              inputMode: 'none',
            },
            label: position === 'start' ? startLabel : endLabel,
          }),
          desktopPaper: () => ({
            style: mobileSM
              ? {
                  position: 'fixed',
                  top: '0px',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }
              : {},
          }),
        }}
      />

      <FormHelperText error={error} sx={{ marginLeft: '16px' }}>
        {helperText}
      </FormHelperText>
    </LocalizationProvider>
  );
};

export default DatePick;
