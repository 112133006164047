import React from 'react';

import TextfieldRoot from './Textfield.styled';

import TField from './types';

const TextfieldComponent = ({
  name,
  value,
  onChange,
  error,
  helperText,
  type = 'text',
  reference,
  placeholder,
  sx,
}: TField): JSX.Element => {
  return (
    <TextfieldRoot
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      margin="normal"
      error={error}
      helperText={helperText}
      type={type}
      ref={reference}
      fullWidth
      sx={sx}
    />
  );
};

const Textfield = React.memo(TextfieldComponent);
export default Textfield;
