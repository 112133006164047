import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from 'store/configureStore';
import {
  setDataSidebar,
  setIsRequest,
  setOpenAddTrip,
  setOpenSidebar,
} from './SidebarSlice';

const useSidebar = () => {
  const dispatch: AppDispatch = useDispatch();
  const { open, data, isRequest, openAddTrip } = useSelector(
    (state: RootState) => state.sidebar,
  );

  const dispatcherHandleSidebar = (state: boolean) =>
    dispatch(setOpenSidebar(state));

  const dispatcherHandleAddTrip = (state: boolean) =>
    dispatch(setOpenAddTrip(state));

  const dispatcherSetListData = (data: any) => dispatch(setDataSidebar(data));

  const dispatcherSetRequest = (state: boolean) =>
    dispatch(setIsRequest(state));

  return {
    dispatcherHandleSidebar,
    dispatcherSetListData,
    dispatcherHandleAddTrip,
    dispatcherSetRequest,
    isRequest,
    data,
    open,
    openAddTrip,
  };
};

export default useSidebar;
