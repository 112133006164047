/* eslint-disable no-loop-func */
const IsHavePath = (pathname: string, url: string): boolean => {
  return pathname.indexOf(url) > -1;
};

const isEqualPath = (pathname: string, url: string): boolean => {
  return pathname === url;
};

const isNull = (data: any): boolean => {
  return data === null;
};

const isEmptyArray = (array: Array<any>) => {
  return array.length === 0;
};

const isEmptyObject = (object: any) => {
  if (object === null || object === undefined) return false;
  return Object.keys(object).length === 0;
};

const isEmptyKeys = (object: Record<string, any>) => {
  let emptyFlag = false;

  for (let key in object) {
    if (object[key] === '') emptyFlag = true;
  }

  return emptyFlag;
};

const isNotEqualObjects = (
  object_1: Record<string, any>,
  object_2: Record<string, any>,
): boolean => {
  let flag = false;

  for (let key in object_1) {
    if (key === 'industries') {
      object_1[key].forEach((item: any, index: any) => {
        if (item !== object_2[key][index]) {
          flag = true;
        }
      });
    } else if (object_1[key] !== object_2[key] && key !== 'address') {
      flag = true;
    }
  }

  return flag;
};

const isSubstring = (string: any, substring: string): boolean => {
  return string?.indexOf(substring) > -1;
};

export {
  isSubstring,
  IsHavePath,
  isEqualPath,
  isNull,
  isEmptyArray,
  isEmptyObject,
  isEmptyKeys,
  isNotEqualObjects,
};
