import TInitialTripValues from '../models/initialValues';

const INITIAL_TRIP_VALUES: TInitialTripValues = {
  name: ``,
  industries: '',
  conferenceName: ``,
  dateFrom: ``,
  dateTo: ``,
  address: ``,
  coordinates: null,
  description: ``,
  eventLink: ``,
};

const INITIAL_FILTER_VALUES: any = {
  name: ``,
  industries: [''],
  conferenceName: ``,
  dateFrom: ``,
  dateTo: ``,
  address: ``,
  coordinates: null,
  description: ``,
};

export { INITIAL_FILTER_VALUES };
export default INITIAL_TRIP_VALUES;
