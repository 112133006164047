import { USER_CONFIRMATION_DATA } from './ConfirmationConstants';
import { USER_CONFIRMATION_ENDPOINT } from 'api/Endpoints';
import { get_thunk } from 'api/API';

const userConfirmationAction = get_thunk(
  USER_CONFIRMATION_DATA,
  USER_CONFIRMATION_ENDPOINT,
  false,
);

export default userConfirmationAction;
