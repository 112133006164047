import { API_URL, get_thunk } from 'api/API';

import { ADMIN_LOGIN_ENDPOINT } from 'api/Endpoints';
import { ADMIN_AUTH_LOGIN } from './AuthConstants';

const adminLoginAction = get_thunk(
  ADMIN_AUTH_LOGIN,
  API_URL + ADMIN_LOGIN_ENDPOINT,
  true,
);

export { adminLoginAction };
