import { Box, styled } from '@mui/material';

const MapRoot = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  height: '100vh',
  width: '100vw',
  backgroundColor: 'darkslategrey',
})) as typeof Box;

export default MapRoot;
