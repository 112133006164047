import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { CSSProperties } from 'react';

import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';

const IconStyles: CSSProperties = {
  width: '50px',
  height: '50px',
  color: '#2b669f',
};

const UnauthorizedContent = () => {
  return (
    <Flexbox
      flexDirection="column"
      alignItems="center"
      width="100%"
      gap="1rem 0"
    >
      <SentimentDissatisfiedIcon sx={IconStyles} />
      <Heading
        as="h3"
        content="Sorry! You must be logged in."
        textAlign="center"
        sx={{ color: '#fff' }}
      />
      <Heading
        as="h5"
        content="Please sign in to businesstrip.ai for continue"
        textAlign="center"
        sx={{ color: '#2b669f' }}
      />
    </Flexbox>
  );
};

export default UnauthorizedContent;
