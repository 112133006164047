import { Card, styled } from '@mui/material';

const HeroRoot = styled(Card)(() => ({
  padding: '28px 24px',
  color: '#ffffff',
  boxShadow: 'unset',
  borderRadius: '12px',
  filter: 'drop-shadow(4px 4px 16px rgba(0,0,0, 0.4))',
  backgroundColor: 'rgba(0,0,0,0.15)',
  paddingBottom: '100px',
  marginBottom: '55px',
})) as typeof Card;

export default HeroRoot;
