import Radio from '@mui/material/Radio';
import React from 'react';

import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import { TRIP_TYPE_DICTIONARY } from 'core/status';

import useMediaMatches from 'hooks/useMediaMatches';

interface IActivityRadio {
  activeState: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const radioStyles = {
  width: '20px',
  height: '20px',
  marginRight: '8px',
  backgroundColor: '#fff',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
  '&.Mui-checked': {
    color: '#E8A442',
    backgroundColor: 'transparent',
  },
};

const ActivityRadioGroup = ({
  activeState,
  onChange,
}: IActivityRadio): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  return (
    <Flexbox gap="30px" mb="8px">
      <Flexbox alignItems="center">
        <Radio
          onChange={onChange}
          value={TRIP_TYPE_DICTIONARY.conference}
          checked={activeState === TRIP_TYPE_DICTIONARY.conference}
          sx={radioStyles}
        />
        <Heading
          as="h4"
          content="Its Conference"
          fontWeight="500"
          fontSize={mobileSM ? '14px' : '16px'}
          color="#ffffff"
        />
      </Flexbox>

      <Flexbox alignItems="center">
        <Radio
          onChange={onChange}
          value={TRIP_TYPE_DICTIONARY.other}
          checked={activeState === TRIP_TYPE_DICTIONARY.other}
          sx={radioStyles}
        />
        <Heading
          as="h4"
          content="Other Activity"
          fontWeight="500"
          fontSize={mobileSM ? '14px' : '16px'}
          color="#ffffff"
        />
      </Flexbox>
    </Flexbox>
  );
};

export default ActivityRadioGroup;
