import TInfoValues from '../models/initialValues';

const INITIAL_INFO_VALUES: TInfoValues = {
  name: ``,
  currentPosition: ``,
  industry: ``,
  linkedLink: ``,
  email: ``,
  phoneNumber: ``,
  currentCompany: ``,
};

export default INITIAL_INFO_VALUES;
