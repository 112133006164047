import React from 'react';

const InfoIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.625 0.875C5.12772 0.875 4.65081 1.07254 4.29917 1.42417C3.94754 1.77581 3.75 2.25272 3.75 2.75V3.375H1.875C1.37772 3.375 0.900806 3.57254 0.549175 3.92417C0.197544 4.27581 0 4.75272 0 5.25V10.25C0 10.7473 0.197544 11.2242 0.549175 11.5758C0.900806 11.9275 1.37772 12.125 1.875 12.125H10.625C11.1223 12.125 11.5992 11.9275 11.9508 11.5758C12.3025 11.2242 12.5 10.7473 12.5 10.25V5.25C12.5 4.75272 12.3025 4.27581 11.9508 3.92417C11.5992 3.57254 11.1223 3.375 10.625 3.375H8.75V2.75C8.75 2.25272 8.55246 1.77581 8.20083 1.42417C7.84919 1.07254 7.37228 0.875 6.875 0.875H5.625ZM7.5 3.375H5V2.75C5 2.58424 5.06585 2.42527 5.18306 2.30806C5.30027 2.19085 5.45924 2.125 5.625 2.125H6.875C7.04076 2.125 7.19973 2.19085 7.31694 2.30806C7.43415 2.42527 7.5 2.58424 7.5 2.75V3.375Z"
        fill="white"
      />
    </svg>
  );
};

export default InfoIcon;
