/* eslint-disable react-hooks/exhaustive-deps */
import { Location, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useJsApiLoader } from '@react-google-maps/api';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';

import InnerWrapper from './components/innerWrapper/InnerWrapper';
import LoadingScreen from 'pages/LoadingScreen';

import { useProfile } from 'features/profile';

import LocaleStorage from 'api/LocaleStorage';

import { ADMIN, UNREAD, USER } from 'core/roles';
import {
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_LOGIN_ROUTE,
  USER_LOGIN_ROUTE,
} from 'routes/core/RouterConstant';
import AppConfig from 'config';

import { isEqualPath } from 'utils/Validation';
import { useAdminDashboard } from 'features/adminDashboard';

Userpilot.initialize('NX-d501292b');

const MainLayout = ({ children }: any): JSX.Element => {
  const { dispatcherUserProfile, fetchUser } = useProfile();
  const { dispatcherAdminProfile } = useAdminDashboard();

  const location: Location = useLocation();

  const MAP_KEY: string = 'AIzaSyDOtfqd9NKCDOlyyLJo6GJSQh7AM97oU4Q';

  const isAuth: string = LocaleStorage.get('auth') || UNREAD;
  const role: string = LocaleStorage.get('role') || 'unread';
  const isAdminDashboard: boolean = isEqualPath(
    location.pathname,
    ADMIN_DASHBOARD_ROUTE,
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    id: 'script-loader',
    libraries: ['places'],
    language: 'en',
    region: 'United States',
  });

  /**
   * *@Fetcher functions
   */
  const fetchUserProfile = () => {
    dispatcherUserProfile({})
      .unwrap()
      .then((response: AxiosResponse) => {
        const user = response?.data;
        localStorage.setItem('user', JSON.stringify(user));
        const now = new Date(Date.now());
        Userpilot.identify(user.userId, {
          name: user.name,
          email: user.email,
          created_at: now.toISOString(),
        });
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        localStorage.clear();
        window.location.href = USER_LOGIN_ROUTE;
      });
  };

  const fetchAdminProfile = () => {
    dispatcherAdminProfile({})
      .unwrap()
      .then((response: any) => {})
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
        localStorage.clear();
        window.location.href = ADMIN_LOGIN_ROUTE;
      });
  };

  /**
   * *@Side Effects
   */
  useEffect(() => {
    if (role === USER) fetchUserProfile();
    if (role === ADMIN) fetchAdminProfile();
  }, [fetchUser]);

  // useEffect(() => {
  //   Userpilot.identify('456987', {
  //     name: 'John Doe',
  //     email: 'john@site-domain.com',
  //     created_at: '2018-07-11',
  //   });
  // }, []);

  if (isAuth === UNREAD && isAdminDashboard)
    return <Navigate to={ADMIN_LOGIN_ROUTE} />;

  if (!isLoaded) return <LoadingScreen />;

  return <InnerWrapper>{children || <Outlet />}</InnerWrapper>;
};

export default MainLayout;
