import { Box } from '@mui/material';

import TLinkedinAuth from './types';

import linkedinIcon from 'assets/img/linkedin-64.png';

import Flexbox from 'components/flexbox/Flexbox';

const LinkedinAuth = ({ onClick }: TLinkedinAuth): JSX.Element => {
  return (
    <Box mt={3} mb={2}>
      <button onClick={onClick} className="button-linked">
        <Flexbox alignItems="center" gap="15px">
          <img
            width="40px"
            height="40px"
            src={linkedinIcon}
            alt="Log in with Linked In"
          />
          <span>Sign in with Linkedin</span>
        </Flexbox>
      </button>
    </Box>
  );
};

export default LinkedinAuth;
