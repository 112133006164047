import { AxiosError, AxiosResponse } from 'axios';
import { CameraAlt } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import React from 'react';

import ImageAvatarRoot, {
  AvatarBadge,
  AvatarIconButton,
} from './ImageAvatar.styled';

import useProfile from 'features/profile/redux/ProfileHook';

import TImageAvatarProps from 'features/profile/models/Avatar';

import { EDIT_USER_PHOTO_EDNPOINT } from 'api/Endpoints';
import { API_URL, axiosInstance } from 'api/API';
import AppConfig from 'config';
import { isSubstring } from 'utils/Validation';
import { ADMIN, UNREAD } from 'core/roles';
import LocaleStorage from 'api/LocaleStorage';

const ImageAvatarComponent = ({
  image,
  name,
  isRender,
  isUser,
}: TImageAvatarProps): JSX.Element => {
  const { dispatchSetFetcherUser, fetchUser, info } = useProfile();
  const role: string = LocaleStorage.get('role') || UNREAD;
  const isAdmin: boolean = role === ADMIN;
  const photo = info?.data?.photoUrl;
  const imageApiUrl: string = isSubstring(photo, 'http')
    ? `${photo}`
    : `${API_URL}${photo}`;
  const photoSrc: string = isAdmin ? '' : imageApiUrl;

  const imageUrl: string = `${API_URL}${image}`;
  const isNewPhoto = isSubstring(image, '/photo');
  const defaultUrl: string = `${image}`;
  const defaultAvatar: string | null = image ? null : name;

  const fetchEditPhoto = async (data: FormData, type: string) => {
    await axiosInstance
      .post(EDIT_USER_PHOTO_EDNPOINT, data, {
        headers: {
          'Content-Type': type,
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          toast.success('Photo successfuly updated!', { duration: 2000 });
          if (fetchUser) {
            dispatchSetFetcherUser(false);
            setTimeout(() => {
              dispatchSetFetcherUser(true);
            }, 1000);
          } else dispatchSetFetcherUser(true);
        }
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const handleChangeAvatar = (event: any): void => {
    event.preventDefault();
    const files: any = event.target.files;
    const contentType: string = files[0].type;
    const formData: FormData = new FormData();

    formData.append('file', files[0]);
    fetchEditPhoto(formData, contentType);
  };

  const renderBadgeContent: JSX.Element = (
    <label htmlFor="icon-button-file">
      <input
        type="file"
        accept="image/*"
        id="icon-button-file"
        onChange={handleChangeAvatar}
        style={{
          display: 'none',
        }}
      />

      <AvatarIconButton
        aria-label="upload picture"
        component="span"
        sx={{
          backgroundColor: '#fff',
          width: '40px',
          height: '40px',
          '&:hover': {
            backgroundColor: '#fff',
          },
        }}
      >
        <CameraAlt
          sx={{
            fontSize: 26,
            color: '#000',
            '&:hover': {
              color: '#000',
            },
          }}
        />
      </AvatarIconButton>
    </label>
  );

  return isRender ? (
    <AvatarBadge
      overlap="circular"
      badgeContent={renderBadgeContent}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ImageAvatarRoot src={photoSrc}>{defaultAvatar}</ImageAvatarRoot>
    </AvatarBadge>
  ) : !isUser ? (
    <ImageAvatarRoot src={photoSrc}>{defaultAvatar}</ImageAvatarRoot>
  ) : (
    <ImageAvatarRoot src={isNewPhoto ? imageUrl : defaultUrl}>
      {defaultAvatar}
    </ImageAvatarRoot>
  );
};

const ImageAvatar = React.memo(ImageAvatarComponent);
export default ImageAvatar;
