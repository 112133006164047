import { createSlice } from '@reduxjs/toolkit';

import { USER_CONFIRMATION } from './ConfirmationConstants';

import TInitConfirmationStates from './ConfirmationTypes';

const initialState: TInitConfirmationStates = {
  success: null,
};

const userConfirmationSlice = createSlice({
  name: USER_CONFIRMATION,
  initialState,
  reducers: {},
  extraReducers: {},
});

export default userConfirmationSlice.reducer;
