/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { LINKEDIN_STATE } from 'api/LinkedInAPI';
import { UNREAD } from 'core/roles';

const LinkedInCallback = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const code: string = searchParams.get('code') || UNREAD;
  const state: string = searchParams.get('state') || UNREAD;

  useEffect(() => {
    if (state === LINKEDIN_STATE && window.opener) {
      window.opener.postMessage({ code, state });
    }
  }, [code, state]);

  return <div>LinkedInCallback</div>;
};

export default LinkedInCallback;
