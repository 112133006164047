import { useState } from 'react';

const usePaginationFetching = (
  getFunction: (config: any) => void,
  parameters = {},
) => {
  const [countLine, setCountLine] = useState<number>(10);
  const [pageNo, setPageNo] = useState<number>(0);

  const fetcher = (
    pageNumber: number = pageNo,
    count: number = countLine,
    customParams?: any,
  ) => {
    const page = pageNumber;

    let getParams = {
      params: {
        ...parameters,
        ...customParams,
        page,
        size: count,
      },
    };
    setPageNo(pageNumber);
    getFunction(getParams);
  };

  const handleChangeCountlines = (event: any) => {
    const value = event.target.value;
    setCountLine(value);

    fetcher(pageNo, value);
  };

  return {
    fetcher,
    handleChangeCountlines,
    pageNo,
    countLine,
  };
};

export default usePaginationFetching;
