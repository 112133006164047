import { styled } from '@mui/material';

import Flexbox from 'components/flexbox/Flexbox';

const NavbarRoot = styled(Flexbox)(() => ({
  width: '100%',
  backgroundColor: '#032620',
  position: 'fixed',
  zIndex: 1200,
  top: 0,
  left: 0,
  right: 0,
  alignItems: 'center',
  justifyContent: 'center',
}));

const FilterBar = styled(Flexbox)(() => ({
  position: 'fixed',
  zIndex: 1200,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: '#032620',
  padding: '20px',
  top: '50px',
}));

export { FilterBar };
export default NavbarRoot;
