const addClass = (htmlElement: any, htmlClass: string): void => {
  htmlElement.classList.add(htmlClass);
};

const removeClass = (htmlElement: any, htmlClass: string): void => {
  htmlElement.classList.remove(htmlClass);
};

async function asyncForEach(array: any, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index]);
  }
}

const createOption = (name: any) => ({
  label: name,
  value: name.toLowerCase().replace(/\W/g, ''),
});

const getDropdownValues = (initOptions: any): any => {
  const optionResult = initOptions.map((item: string) => createOption(item));
  return optionResult;
};

const disableElement = (elementClass: string, disableState: boolean): void => {
  const element: any = document.querySelector(elementClass);
  element.disabled = disableState;
};

const sameCoordinates = (
  currentItem: Record<string, any>,
  item: any,
): boolean => {
  return currentItem.lng === item.lng && currentItem.lat === item.lat;
};

export {
  addClass,
  removeClass,
  asyncForEach,
  getDropdownValues,
  disableElement,
  sameCoordinates,
};
