import { useState } from 'react';
import { payloadFilterFormatter } from 'utils/Formatter';

const COUNT_LINE = 10;

const useFilterFetching = (
  postFunction: (parameters: any) => void,
  customBody?: any,
) => {
  const [savesFilters, setSavesFilters] = useState<Record<string, any>>({
    monthCount: 1,
    industries: [],
  });
  const [pageNo, setPageNo] = useState<number>(0);

  const fetcher = (
    pageNumber: number = pageNo,
    count: number = COUNT_LINE,
    filters: Record<string, any> = savesFilters,
    customParams?: any,
  ) => {
    const page = pageNumber;

    const filterParameters = payloadFilterFormatter(filters);
    const bodyParams = customBody ? payloadFilterFormatter(customBody) : {};

    const payload = {
      data: { ...filterParameters, ...bodyParams },
      query: {
        params: {
          page,
          size: count,
          ...customParams,
        },
      },
    };

    setPageNo(pageNumber);
    setSavesFilters(filters);
    postFunction(payload);
  };

  const handleFiltering = (
    page: number = pageNo,
    filters: Record<string, any>,
    customParams?: any,
  ) => {
    fetcher(page, COUNT_LINE, filters, customParams);
    setSavesFilters(filters);
  };

  return {
    fetcher,
    pageNo,
    savesFilters,
    COUNT_LINE,
    handleFiltering,
  };
};

export default useFilterFetching;
