const ADMIN_PROFILE_ENDPOINT: string = '/adminProfile';
const ADMIN_LOGIN_ENDPOINT: string = '/loginAdmin';
const ADMIN_TRIPS_ENDPOINT: string = '/tripStats';
const ADMIN_REPORT_ENDPOINT: string = '/download';

const USER_GET_PREV_TRIPS_ENDPOINT: string = '/userCancelledTrips';
const USER_CONFIRMATION_ENDPOINT: string = '/registration';
const USER_PROFILE_FOR_ADMIN_ENDPOINT: string = '/getUser';
const EDIT_USER_PHOTO_EDNPOINT: string = '/photoUpload';
const POST_TRIP_IMAGE_ENDPOINT: string = '/image/upload';
const USER_GET_TRIPS_ENDPOINT: string = '/userTrips';
const GET_USER_PHOTO_ENDPOINT: string = '/photo';
const USER_PROFILE_ENDPOINT: string = '/profile';
const USER_LOGIN_ENDPOINT: string = '/callback';
const EDIT_USER_ENDPOINT: string = '/editUser';
const USER_SHARE_POST_ENDPOINT: string = '/createTripPost';

const DELETE_TRIP_ENDPOINT: string = '/deleteTrip';
const SHARE_TRIP_ENDPOINT: string = '/shareTripPost';
const GET_TRIP_BY_ENDPOINT: string = '/findTrip';
const CREATE_TRIP_ENDPOINT: string = '/addTrip';
const EDIT_TRIP_ENDPOINT: string = '/editTrip';
const HIDE_TRIP_ENDPOINT: string = '/hideTrip';
const GET_TRIPS_ENDPOINT: string = '/getTrips';
const GET_TRIP_ENDPOINT: string = '/getTrip';
const JOIN_TRIP_ENDPOINT: string = '/joinTrip';
const LEAVE_TRIP_ENDPOINT: string = '/leaveTrip';
const FOLLOWERS_TRIP_ENDPOINT: string = '/getTripFollowers';
const ADD_TRIP_FROM_FILE_ENDPOINT: string = '/addTripFromFile';
const ADD_INDUSTRY_ENDPOINT: string = '/addIndustry';
const GET_NOTIFICATION_ENDPOINT: string = '/getNotifications';
const READ_NOTIFICATION_ENDPOINT: string = '/markAsRead';
const DELETE_NOTIFICATION_ENDPOINT: string = '/notifications';

const CHANGE_TRIP_STATUS_ENDPOINT: string = '/changeTripStatus';

const GET_INDUSTRY_ENDPOINT: string = '/getIndustries';

export {
  GET_NOTIFICATION_ENDPOINT,
  READ_NOTIFICATION_ENDPOINT,
  ADD_INDUSTRY_ENDPOINT,
  ADMIN_LOGIN_ENDPOINT,
  ADMIN_PROFILE_ENDPOINT,
  ADMIN_TRIPS_ENDPOINT,
  ADMIN_REPORT_ENDPOINT,
  USER_LOGIN_ENDPOINT,
  USER_CONFIRMATION_ENDPOINT,
  CREATE_TRIP_ENDPOINT,
  USER_GET_TRIPS_ENDPOINT,
  EDIT_TRIP_ENDPOINT,
  ADD_TRIP_FROM_FILE_ENDPOINT,
  EDIT_USER_PHOTO_EDNPOINT,
  DELETE_TRIP_ENDPOINT,
  HIDE_TRIP_ENDPOINT,
  SHARE_TRIP_ENDPOINT,
  USER_PROFILE_ENDPOINT,
  USER_PROFILE_FOR_ADMIN_ENDPOINT,
  USER_GET_PREV_TRIPS_ENDPOINT,
  GET_USER_PHOTO_ENDPOINT,
  EDIT_USER_ENDPOINT,
  GET_TRIP_BY_ENDPOINT,
  GET_TRIPS_ENDPOINT,
  GET_INDUSTRY_ENDPOINT,
  CHANGE_TRIP_STATUS_ENDPOINT,
  USER_SHARE_POST_ENDPOINT,
  DELETE_NOTIFICATION_ENDPOINT,
  JOIN_TRIP_ENDPOINT,
  LEAVE_TRIP_ENDPOINT,
  FOLLOWERS_TRIP_ENDPOINT,
  GET_TRIP_ENDPOINT,
  POST_TRIP_IMAGE_ENDPOINT,
};
