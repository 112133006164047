import { styled, TextField } from '@mui/material';

import palette from 'theme/theme';

const SearchRoot = styled(TextField)(({ theme }) => ({
  width: '20%',
  '&:hover, & .MuiOutlinedInput-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 12,
    height: 40,
    fontWeight: 400,
    padding: '0px 1rem',
    color: palette.black[100],
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderWidth: '1px !important',
  },
  '& .MuiInputBase-root': {
    backgroundColor: palette.white[100],
  },
})) as typeof TextField;

export default SearchRoot;
