import React from 'react';

const NotificationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#E8A442" />
      <path
        d="M14.5588 6.61302C13.0258 6.61302 11.7833 7.8419 11.7833 9.35769C11.7833 10.8735 13.0258 12.1024 14.5588 12.1024C16.0914 12.1024 17.3343 10.8735 17.3343 9.35769C17.3343 7.8419 16.0914 6.61302 14.5588 6.61302ZM14.5588 12.5091C11.4928 12.5091 10.118 13.8688 10.118 16.9006V17.2665C10.1319 17.9875 10.4542 18.0087 11.1357 17.9985H17.9819C18.8506 17.9977 19.0055 17.9994 18.9995 17.2665V16.9006C18.9995 13.8688 17.6244 12.5091 14.5588 12.5091Z"
        fill="white"
      />
      <path
        d="M8.63845 7.64154C7.38265 7.64154 6.36424 8.64865 6.36424 9.8907C6.36424 11.1326 7.38265 12.1393 8.63845 12.1393C9.89426 12.1393 10.9125 11.1326 10.9125 9.89052C10.9125 8.64865 9.89426 7.64154 8.63845 7.64154ZM8.63845 12.4723C6.12666 12.4723 5 13.5867 5 16.0704V16.3703C5.01147 16.961 5.27551 16.9784 5.83375 16.9699H9.3721C9.28181 15.49 9.72385 13.9182 10.8248 12.919C10.2675 12.6178 9.54344 12.4723 8.63845 12.4723Z"
        fill="white"
      />
    </svg>
  );
};

export default NotificationIcon;
