import { Box, styled } from '@mui/material';

const MobileTripDetailsRoot = styled(Box)(() => ({
  width: '100%',
  backgroundColor: '#000000',
  borderRadius: '12px',
  padding: '10px',
  marginBottom: '20px',
  color: '#fff',
}));

export default MobileTripDetailsRoot;
