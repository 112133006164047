import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import EllipsisText from 'react-ellipsis-text';
import { Avatar, Box } from '@mui/material';
import { CSSProperties } from 'react';

import Info from 'features/profile/components/info/Info';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';
import MobileTripDetailsRoot from './styled';

import { TRIP_TYPE_DICTIONARY } from 'core/status';
import { API_URL } from 'api/API';

import { isSubstring } from 'utils/Validation';
import FollowersModule from 'modules/FollowersModule';

const infoStyles: CSSProperties = {
  marginLeft: '0',
  fontSize: '12px',
};

const MobileTripDetails = ({ data }: { data: any }): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const dateRange: string = `${data?.dateFrom} - ${data?.dateTo}`;
  const tripId = data.id;
  const ownerId = data.ownerId;
  const imageApiUrl: string = isSubstring(data?.ownerProfileLink, 'http')
    ? `${data?.ownerProfileLink}`
    : `${API_URL}${data?.ownerProfileLink}`;

  const handleNavigateToTrip = (): void => {
    const url = `/trip/${tripId}`;
    navigate(url);
  };

  const handleNavigateToUser = (): void => {
    const url = `/user/${ownerId}`;
    navigate(url);
  };

  const renderTitle: JSX.Element = (
    <Flexbox alignItems="center" justifyContent="space-between">
      <Flexbox gap="20px">
        <Heading
          as="h4"
          content={
            <EllipsisText title={data?.name} text={data?.name} length="30" />
          }
          fontWeight="400"
          fontSize="15px"
          sx={{ marginTop: '2px', textDecoration: 'underline' }}
          onClick={handleNavigateToTrip}
        />
        <FollowersModule tripId={tripId} />
      </Flexbox>
      <Avatar
        src={imageApiUrl}
        sx={{ width: '35px', height: '35px' }}
        onClick={handleNavigateToUser}
      />
    </Flexbox>
  );

  const renderName =
    data?.type === TRIP_TYPE_DICTIONARY.conference ? (
      <Info
        label="Conference Name"
        value={
          <EllipsisText
            title={data?.name}
            text={data?.conferenceName}
            length="30"
          />
        }
        overrideStyles={infoStyles}
        mt="2px"
      />
    ) : (
      <Info
        label="Activity Name"
        value={
          <EllipsisText
            title={data?.name}
            text={data?.conferenceName}
            length="30"
          />
        }
        overrideStyles={infoStyles}
        mt="2px"
      />
    );

  const renderContent: JSX.Element = (
    <Flexbox mt="5px" justifyContent="space-between" alignItems="center">
      <Box>
        <Info
          label="Owner"
          value={data?.ownerName}
          overrideStyles={infoStyles}
          mt="2px"
        />
        <Info
          label="Company"
          value={data?.ownerCompany}
          overrideStyles={infoStyles}
          mt="2px"
        />
        <Info
          label="Trip Dates"
          value={dateRange}
          overrideStyles={infoStyles}
          mt="2px"
        />
        {renderName}
        <Info
          label="Destination"
          value={data?.address}
          overrideStyles={infoStyles}
          mt="2px"
        />
      </Box>

      <ArrowForwardIosIcon
        sx={{ width: '30px', height: '30px' }}
        onClick={handleNavigateToTrip}
      />
    </Flexbox>
  );

  return (
    <MobileTripDetailsRoot>
      {renderTitle}
      {renderContent}
    </MobileTripDetailsRoot>
  );
};

export default MobileTripDetails;
