import React from 'react';

import LoadButtonRoot from './LoadButton.styled';

import TLoadButtonProps from './types';

const LoadButton = ({
  buttonLabel,
  onUpload,
  onClick,
  sx,
}: TLoadButtonProps) => {
  return (
    <label htmlFor="icon-button-file" style={{ width: '100%' }}>
      <input
        type="file"
        id="icon-button-file"
        onChange={onUpload}
        style={{
          display: 'none',
        }}
      />

      <LoadButtonRoot onClick={onClick} aria-label="upload picture" sx={sx}>
        {buttonLabel}
      </LoadButtonRoot>
    </label>
  );
};

export default LoadButton;
