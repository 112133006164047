import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ArrowButtonRoot from './ArrowButton.styled';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';

import TArrowButtonProps from './types';

const ArrowButton = ({
  label,
  onClick,
  sx,
}: TArrowButtonProps): JSX.Element => {
  return (
    <Flexbox alignItems="center" gap="0 1rem" sx={sx}>
      <ArrowButtonRoot onClick={onClick}>
        <ArrowBackIcon />
      </ArrowButtonRoot>
      <Heading as="h4" content={label} fontWeight="500" color="#fff" />
    </Flexbox>
  );
};

export default ArrowButton;
