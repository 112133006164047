/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';

import FollowButton from 'features/home/components/followButton/FollowButton';
import ListSidebarRoot, { ScrollBarRoot } from './ListSidebar.styled';
import SidebarHeader from '../sidebarHeader/SidebarHeader';
import LoadTripList from '../loadTripList/LoadTripList';
import ListFilters from '../listFilters/ListFilters';
import { useMap, useSidebar } from 'features/home';

import useFilterFetching from 'hooks/useFilterFetching';
import useGeocoding from 'hooks/useGeocoding';

import { SORT_DICTIONARY, TRIP_TYPE_DICTIONARY } from 'core/status';
import INITIAL_TRIP_VALUES from 'features/home/core/initialValues';
import AppConfig from 'config';

const ListSidebar = (): JSX.Element => {
  const {
    open,
    dispatcherHandleSidebar,
    data,
    dispatcherSetListData,
    dispatcherSetRequest,
    isRequest,
  } = useSidebar();

  const [triplist, setTriplist] = useState<Record<string, any> | null>(null);
  const [trips, setTrips] = useState<any>(null);
  const [openSorting, setOpenSorting] = useState<boolean>(false);
  const { getCoord } = useGeocoding();
  const [conferenceState, setConferenceState] = useState<string>(
    TRIP_TYPE_DICTIONARY.conference,
  );

  const { dispatcherGetTripBy, searchAddress } = useMap();

  const InitValues = {
    conferenceName: INITIAL_TRIP_VALUES.conferenceName,
    dateFrom: INITIAL_TRIP_VALUES.dateFrom,
    dateTo: INITIAL_TRIP_VALUES.dateTo,
    address: searchAddress?.address || INITIAL_TRIP_VALUES.address,
    monthCount: 1,
  };

  const [initFormik, setInitFormik] = useState<Record<string, any>>(InitValues);
  const addressRef: any = useRef(null);

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: initFormik,
      enableReinitialize: true,
      onSubmit: (values) => {
        const address = addressRef.current.value;
        const geoAddress = getCoord(address);

        geoAddress
          .then((geo: any) => {
            values.address = address;

            const formattedData = geo?.formatterAddress;
            let geoAddressValue = null;

            if (formattedData) {
              geoAddressValue = formattedData;
            }

            const payload = {
              ...values,
              industries: [],
              address: geoAddressValue || values.address,
              geoData: geo,
            };
            handleFiltering(0, payload, { sort: SORT_DICTIONARY.newest });
            setOpenSorting(false);
          })
          .catch(() => {
            const payload = {
              ...values,
              industries: [],
            };
            handleFiltering(0, payload, { sort: SORT_DICTIONARY.newest });
            setOpenSorting(false);
          });
      },
    });

  const fetcherTripList = (parameters: any) => {
    return dispatcherGetTripBy(parameters)
      .unwrap()
      .then((response: any) => {
        const responseData = response?.data;
        const pagesCount: number = responseData?.pages;
        const currentPage: number = responseData?.page;
        const responseTrips: Array<Record<string, any>> = responseData?.trips;

        const notFirstPage: boolean = currentPage > 0;
        const isNotLastPage: boolean = currentPage < pagesCount;

        if (trips && isNotLastPage && notFirstPage)
          setTrips([...trips, ...responseTrips]);
        else setTrips(responseTrips);

        setTriplist(responseData);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { fetcher, savesFilters, handleFiltering, pageNo, COUNT_LINE } =
    useFilterFetching(fetcherTripList);

  /**
   * *@Handler functions
   */
  const handleCloseSidebar = (): void => {
    dispatcherHandleSidebar(false);
    handleFiltering(
      0,
      { monthCount: 1, industries: [] },
      {
        sort: SORT_DICTIONARY.newest,
      },
    );
    resetForm();
    dispatcherSetRequest(false);
    dispatcherSetListData(null);
  };

  const handleSorting = (): void => {
    setOpenSorting((prevState: boolean) => !prevState);
  };

  const handleChangeConferenceState = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value;
    setConferenceState(value);
  };

  const handleLoadMore = (): void => {
    const searchObj = {
      sort: SORT_DICTIONARY.newest,
    };
    let addressFilter: any =
      typeof searchAddress === 'object'
        ? { ...savesFilters, geoData: searchAddress?.geo }
        : { ...savesFilters, geoData: null };
    fetcher(pageNo + 1, COUNT_LINE, addressFilter, searchObj);
  };

  useEffect(() => {
    const searchObj = {
      sort: SORT_DICTIONARY.newest,
    };
    let addressFilter =
      typeof searchAddress === 'object'
        ? { ...savesFilters, geoData: searchAddress?.geo }
        : { ...savesFilters, geoData: null };
    isRequest && fetcher(0, COUNT_LINE, addressFilter, searchObj);
  }, [isRequest]);

  // console.log('searchaddress: ', searchAddress);

  /**
   * ?Side Effect for immediately reinitialize
   */
  useEffect(() => {
    setInitFormik(InitValues);
  }, [open, searchAddress]);

  /**
   * *@Renders functions
   */

  const renderListFilters: JSX.Element | null = isRequest ? (
    <Fragment>
      <ListFilters
        title="Filters"
        open={openSorting}
        onClose={handleSorting}
        values={values}
        handleChange={handleChange}
        onSubmit={handleSubmit}
        addressRef={addressRef}
        setFieldValue={setFieldValue}
        conferenceState={conferenceState}
        handleChangeConferenceState={handleChangeConferenceState}
      />
    </Fragment>
  ) : null;

  const renderMoreButton: JSX.Element | null =
    pageNo < triplist?.pages - 1 && isRequest ? (
      <FollowButton
        label="Load More"
        success
        onClick={handleLoadMore}
        overrideStyles={{ width: '180px' }}
      />
    ) : null;

  return (
    <ListSidebarRoot
      open={open}
      elevation={0}
      anchor="left"
      onClose={handleCloseSidebar}
    >
      <SidebarHeader
        isRenderFilters={isRequest}
        onClose={handleCloseSidebar}
        onOpenFilters={handleSorting}
      />
      {renderListFilters}

      <ScrollBarRoot>
        {isRequest ? (
          <LoadTripList data={trips} isOpened={open} />
        ) : (
          <LoadTripList data={data?.trips} isOpened={open} />
        )}
        <Box mt={2} textAlign="center" mb={2}>
          {renderMoreButton}
        </Box>
      </ScrollBarRoot>
    </ListSidebarRoot>
  );
};

export default ListSidebar;
