import { LicenseInfo } from '@mui/x-license-pro';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';

import App from './App';

import 'simplebar/dist/simplebar.min.css';
import 'nprogress/nprogress.css';
import './index.css';

import store from 'store/configureStore';

LicenseInfo.setLicenseKey(
  '3c53f7d648b5f89720f6fdd75131e690Tz02NTc2NyxFPTE3MTQ4MTI2MTQ4NjYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
