import { Paper } from '@mui/material';

import Wrapper from 'components/wrapper/Wrapper';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';
import { LinkedinAuth } from 'components/oauth';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

interface IUserLogin {
  title: string;
  onSign: (_event?: any) => void;
}

const UserLogin = ({ title, onSign }: IUserLogin) => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  return (
    <Wrapper xs={12} sm={8} md={5} component={Paper} elevation={3} item square>
      <Flexbox
        flexDirection="column"
        alignItems="center"
        p="2rem 3rem"
        width="100%"
      >
        <Heading
          fontSize={mobileSM ? '24px' : '30px'}
          content={title}
          fontWeight="600"
          as="h2"
        />
        <LinkedinAuth onClick={onSign} />
      </Flexbox>
    </Wrapper>
  );
};

export default UserLogin;
