import { FormControl, Select, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';

import SelectInputRoot from './ObjectSelect.styled';

import TSelectProps, { JSXFunction } from './types';
import { CSSProperties } from 'react';

const MenuItemStyles: CSSProperties = {
  fontSize: 12,
  fontWeight: 500,
};

const EmStyles: CSSProperties = {
  fontSize: '13px',
  fontWeight: 400,
  fontFamily: "'Roboto'",
  color: '#000',
  opacity: 1,
  paddingTop: '2px',
};

const ClearIconStyles: CSSProperties = {
  opacity: 0.38,
  cursor: 'pointer',
};

const ObjectSelection = ({
  value,
  name,
  onChange,
  options,
  placeholder,
  styles,
}: TSelectProps) => {
  const handleClearClick = (): void => {
    onChange('');
  };

  const renderIconComponent: JSXFunction = () => (
    <KeyboardArrowDown color="disabled" />
  );

  const renderEndAdornment: JSX.Element | null = value ? (
    <ClearIcon sx={ClearIconStyles} onClick={handleClearClick} />
  ) : null;

  return (
    <FormControl error fullWidth sx={styles}>
      <Select
        displayEmpty
        value={value || ''}
        name={name}
        onChange={onChange}
        IconComponent={renderIconComponent}
        input={<SelectInputRoot />}
        endAdornment={renderEndAdornment}
        sx={{ height: '40px' }}
      >
        <MenuItem disabled value="" sx={{ ...MenuItemStyles }}>
          <em style={EmStyles}>{placeholder}</em>
        </MenuItem>

        {options?.map((option: Record<string, any>, index: number) => (
          <MenuItem key={index} value={option?.count} sx={MenuItemStyles}>
            {option?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ObjectSelection;
