import { Box, styled } from '@mui/material';

const TripLayoutRoot = styled('div')(() => ({
  maxWidth: '1050px',
  margin: '0 auto',
  padding: '1rem 15px',
  paddingTop: '100px',
  backgroundColor: '#032620',
}));

const DetailHeader = styled('h4')(() => ({
  fontFamily: 'Roboto',
  color: '#96E6B3',
  fontWeight: '400',
  fontSize: '16px',
}));

const TripName = styled('h1')(() => ({
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: '600',
  fontSize: '42px',
  color: '#0e1016',
  letterSpacing: '0.75px',
}));

const Line = styled('div')(() => ({
  background: '#1976d2',
  height: '4px',
  width: '50px',
  borderRadius: '4px',
  marginTop: '10px',
}));

const AuthorText = styled('div')(() => ({
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: '600',
  fontSize: '16px',
  color: '#0e1016',
}));

const StatusText = styled('div')(() => ({
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: '400',
  fontSize: '12px',
  color: '#0e1016',
  letterSpacing: '0.75px',
  textTransform: 'uppercase',
}));

const TextBox = styled('div')(() => ({
  boxShadow: '0px 0px 44px -9px rgba(0,0,0,0.15)',
  padding: '60px',
  borderRadius: '12px',
  backgroundColor: 'rgba(0, 0,0, 0.3)',
  display: 'flex',
  gap: '90px',
}));

const DateBox = styled('div')(() => ({
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: '500',
  fontSize: '24px',
  color: '#0e1016',
  letterSpacing: '1px',
  textTransform: 'uppercase',
}));

const Text = styled('p')(() => ({
  fontFamily: 'Roboto',
  fontWeight: '400',
  fontSize: '16px',
  color: '#ffffff',
  lineHeight: 1.2,
}));

const ImageBox = styled(Box)(() => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '300px',
  opacity: '50%',
  position: 'absolute',
  borderRadius: '15px',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1,
}));

const ImageFile = styled(Box)(() => ({
  color: '#fff',
  textAlign: 'center',
  marginTop: '10px',
}));

export {
  ImageBox,
  DetailHeader,
  TripName,
  Line,
  AuthorText,
  StatusText,
  TextBox,
  DateBox,
  Text,
  ImageFile,
};
export default TripLayoutRoot;
