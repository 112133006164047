import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from 'store/configureStore';

import {
  setClearFilter,
  setCoordsFetching,
  setFetchNotifications,
  setIndustriesRedux,
  setMapFilters,
  setMapStateFetching,
  setSearchAddress,
} from './MapSlice';
import createTripAction, {
  mapAddIndustryAction,
  mapDeleteNotifications,
  mapFollowersTripAction,
  mapGetIndustryAction,
  mapGetNotificationAction,
  mapGetSharePostAction,
  mapGetTripAction,
  mapGetTripByAction,
  mapGetTripsAction,
  mapJoinTripAction,
  mapLeaveTripAction,
  mapReadNotificationAction,
  mapShareTripAction,
} from './MapActions';

const useMap = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    success,
    fetchCoords,
    filters,
    mapState,
    industries,
    fetchNotifications,
    searchAddress,
    clearFilter,
  } = useSelector((state: RootState) => state.map);

  const dispatcherGetTrips = (config: any) =>
    dispatch(mapGetTripsAction(config));

  const dispatcherGetSharePost = (config: any) =>
    dispatch(mapGetSharePostAction(config));

  const dispatcherSetFetchCoords = (state: boolean) =>
    dispatch(setCoordsFetching(state));

  const dispatcherGetTripBy = (payload: any) =>
    dispatch(mapGetTripByAction(payload));

  const dispatcherAddIndustry = (payload: any) =>
    dispatch(mapAddIndustryAction(payload));

  const dispatcherCreateTrip = (payload: Record<string, any>) =>
    dispatch(createTripAction(payload));

  const dispatcherIndustries = (config: any) =>
    dispatch(mapGetIndustryAction(config));

  const dispatcherMapFilters = (parameters: Record<string, any>) =>
    dispatch(setMapFilters(parameters));

  const dispatcherShareTrip = (payload: any) =>
    dispatch(mapShareTripAction(payload));

  const dispatcherJoinTrip = (config: any) =>
    dispatch(mapJoinTripAction(config));

  const dispatcherLeaveTrip = (config: any) =>
    dispatch(mapLeaveTripAction(config));

  const dispatcherFollowersTrip = (config: any) =>
    dispatch(mapFollowersTripAction(config));

  const dispatcherGetTrip = (config: any) => dispatch(mapGetTripAction(config));

  const dispatcherGetNotification = (config: any) =>
    dispatch(mapGetNotificationAction(config));

  const dispatcherMarkNotification = (config: any) =>
    dispatch(mapReadNotificationAction(config));

  const dispatcherMapConfig = (state: any) =>
    dispatch(setMapStateFetching(state));

  const dispatchSetIndustries = (payload: any) => {
    return dispatch(setIndustriesRedux(payload));
  };

  const dispatchDeleteNotifications = (parameters: any) => {
    return dispatch(mapDeleteNotifications(parameters));
  };

  const dispatchSetFetchNotifications = (parameters: any) => {
    return dispatch(setFetchNotifications(parameters));
  };

  const dispatchSetAddress = (parameters: any) => {
    return dispatch(setSearchAddress(parameters));
  };

  const dispatchSetClearFilter = (state: boolean) => {
    return dispatch(setClearFilter(state));
  };

  return {
    reduxIndustries: industries,
    dispatcherGetNotification,
    dispatcherMarkNotification,
    dispatcherSetFetchCoords,
    dispatcherGetTripBy,
    dispatcherGetTrips,
    dispatchSetIndustries,
    dispatcherCreateTrip,
    dispatcherIndustries,
    dispatcherMapFilters,
    dispatcherShareTrip,
    fetchNotifications,
    dispatcherGetSharePost,
    dispatchSetFetchNotifications,
    dispatcherJoinTrip,
    dispatcherLeaveTrip,
    dispatcherFollowersTrip,
    dispatchDeleteNotifications,
    dispatcherGetTrip,
    dispatcherAddIndustry,
    dispatcherMapConfig,
    dispatchSetAddress,
    filters,
    mapState,
    fetchCoords,
    success,
    dispatchSetClearFilter,
    searchAddress,
    clearFilter,
  };
};

export default useMap;
