import { Paper } from '@mui/material';

import { ConfirmationForm } from 'features/userConfirmation';
import Flexbox from 'components/flexbox/Flexbox';
import Wrapper from 'components/wrapper/Wrapper';
import { Heading } from 'components/typography';

import AppConfig from 'config';

const UserConfirmation = (): JSX.Element => {
  return (
    <Wrapper xs={12} sm={8} md={5} component={Paper} elevation={3} item square>
      <Flexbox
        flexDirection="column"
        alignItems="center"
        p="2rem 3rem"
        width="100%"
      >
        <Heading
          as="h2"
          content={AppConfig.NAMES.CONFIRMATION}
          fontWeight="600"
        />
        <ConfirmationForm />
      </Flexbox>
    </Wrapper>
  );
};

export default UserConfirmation;
