import { styled, TextareaAutosize } from '@mui/material';
import React from 'react';

import palette from 'theme/theme';
import TTextarea from './types';

const TextareaStyled = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  marginTop: '0.5rem',
  fontSize: '12px !important',
  backgroundColor: palette.white[100],
  minHeight: 60,
  fontWeight: '500 !important',
  borderRadius: '8px',
  color: palette.black[100],
  borderColor: 'transparent',
  borderWidth: '1px !important',
  font: 'inherit',
  padding: '0.5rem 1rem',
  boxSizing: 'border-box',
  '&:hover, &:focus-visible': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
  },
}));

const Textarea = ({
  name,
  onTextareaChange,
  value,
  readOnly,
  sx,
}: TTextarea) => {
  return (
    <TextareaStyled
      name={name}
      onChange={onTextareaChange}
      value={value}
      readOnly={readOnly}
      sx={{
        resize: 'none',
        '& textarea': {
          resize: 'none',
        },
        ...sx,
      }}
    />
  );
};

export default Textarea;
