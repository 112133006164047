import React from 'react';

const FollowersIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0911 0C10.9918 0 9.29026 1.70176 9.29026 3.80083C9.29026 5.8999 10.9918 7.60165 13.0911 7.60165C15.1899 7.60165 16.8919 5.8999 16.8919 3.80083C16.8919 1.70176 15.1899 0 13.0911 0ZM13.0911 8.16494C8.89244 8.16494 7.00977 10.0479 7.00977 14.2463V14.753C7.02877 15.7514 7.47017 15.7808 8.4034 15.7666H17.7788C18.9684 15.7656 19.1805 15.7679 19.1724 14.753V14.2463C19.1724 10.0479 17.2892 8.16494 13.0911 8.16494Z"
        fill="white"
      />
      <path
        d="M4.98263 1.42429C3.26288 1.42429 1.86823 2.81894 1.86823 4.53894C1.86823 6.25869 3.26288 7.65283 4.98263 7.65283C6.70238 7.65283 8.09678 6.25869 8.09678 4.53869C8.09678 2.81894 6.70238 1.42429 4.98263 1.42429ZM4.98263 8.114C1.54288 8.114 0 9.65714 0 13.0966V13.5119C0.0157101 14.3299 0.377295 14.3539 1.14177 14.3423H5.98732C5.86366 12.2929 6.46901 10.1163 7.97667 8.73252C7.21346 8.31544 6.22195 8.114 4.98263 8.114Z"
        fill="white"
      />
    </svg>
  );
};

export default FollowersIcon;
