import { ButtonBase, IconButton } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CSSProperties } from 'react';

import { ClearIcon } from '../listSidebar/ListSidebar.styled';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

interface ISidebarHeader {
  isRenderFilters: boolean;
  onClose?: (event?: any) => void;
  onOpenFilters?: (event?: any) => void;
}

const filterIconStyles: CSSProperties = {
  color: '#ffffff',
  width: '30px',
  height: '30px',
};

const filterMobileIconStyles: CSSProperties = {
  color: '#ffffff',
  width: '25px',
  height: '25px',
};

const SidebarHeader = ({
  isRenderFilters,
  onClose,
  onOpenFilters,
}: ISidebarHeader): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const handleReload = (): void => {
    window.location.reload();
  };

  const renderTitle = (
    <Heading
      as="h4"
      content="Trips List"
      fontWeight="400"
      fontSize={mobileSM ? '16px' : '20px'}
      color="#ffffff"
    />
  );

  return (
    <Flexbox
      alignItems="center"
      justifyContent="space-between"
      p={mobileSM ? '0px 0px 20px 0px' : '28px 40px'}
    >
      <Flexbox alignItems="center" gap="0 1rem">
        {renderTitle}
        {isRenderFilters && (
          <ButtonBase onClick={onOpenFilters}>
            <FilterListIcon
              sx={mobileSM ? filterMobileIconStyles : filterIconStyles}
            />
          </ButtonBase>
        )}
        {mobileSM && (
          <RotateLeftIcon
            sx={{ cursor: 'pointer', color: '#fff' }}
            onClick={handleReload}
          />
        )}
      </Flexbox>

      {onClose && (
        <IconButton
          onClick={onClose}
          size="medium"
          sx={{
            p: 0,
          }}
        >
          <ClearIcon sx={{ color: '#ffffff' }} />
        </IconButton>
      )}
    </Flexbox>
  );
};

export default SidebarHeader;
