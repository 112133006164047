import { styled } from '@mui/material';

const LoadButtonRoot = styled('span')(() => ({
  display: 'inline-block',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '6px',
  fontWeight: 500,
  padding: '14px 10px',
  marginTop: '20px',
  fontSize: '16px',
  lineHeight: '1.2',
  textTransform: 'none',
  color: '#96E6B3',
  backgroundColor: 'transparent',
  border: `1px solid #96E6B3`,
  boxShadow: 'unset',
  minWidth: '64px',
  width: '100%',
  opacity: 1,
  transition: 'all .3s',
}));

export default LoadButtonRoot;
