import { format } from 'date-fns';

const UTCFormatter = (initialDate: string | null): string | null => {
  let resultDate: string | null = null;

  if (initialDate === '') return '';

  if (initialDate) resultDate = format(new Date(initialDate), 'dd/MM/yyyy');

  return resultDate;
};

const urlWithQueryFormatter = (
  url: string,
  parameters: Record<string, any>,
): string => {
  const query: string = Object.entries(parameters)
    .map(([key, value]: [string, any]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${url}?${query}`;
};

const payloadFilterFormatter = (object: Record<string, any>) => {
  let parameters: any = {};

  for (let key in object) {
    if (object[key] !== '') parameters[key] = object[key];
  }

  return parameters;
};

const getLinkFromInvite = (text: string) => {
  const str = text;
  const findStartStr: string = 'http:';

  const index = str.indexOf(findStartStr);
  return str.substring(index);
};

export {
  UTCFormatter,
  urlWithQueryFormatter,
  payloadFilterFormatter,
  getLinkFromInvite,
};
