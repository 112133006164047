/* eslint-disable react-hooks/exhaustive-deps */
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { components } from 'react-select';
import { useEffect, useMemo, useState } from 'react';

import TSearchDropdown from './types';
import useMediaMatches from 'hooks/useMediaMatches';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

const createOption = (name: any) => ({
  label: name,
  value: name.toLowerCase().replace(/\W/g, ''),
});

const getDropdownOption = (initOptions: any): any => {
  const optionResult = initOptions.map((item: string) => createOption(item));
  return optionResult;
};

const SearchDropdown = ({
  dropdownOptions,
  placeholder,
  dropdownValue,
  dropdownChange,
  loadOptions,
  handleCreateOption,
  async,
}: TSearchDropdown): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const asyncStyles =
    async && !mobileSM ? { position: 'fixed', top: '47%', width: '450px' } : {};

  const selectStylesConfig = {
    control: (provided: any) => ({
      ...provided,
      fontFamily: '"Roboto"',
      fontWeight: '400',
      lineHeight: '1.5',
      fontSize: '14px',
      height: 'auto',
      minHeight: '40px',
      padding: '0 5px',
      color: '#0e1016',
      boxShadow: 'none',
      width: '100%',
      marginTop: '10px',
      border: '1px solid transparent',
      backgroundColor: '#E5EAF2',
      '&:hover': {
        borderColor: '#1976d2',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 110,
      ...asyncStyles,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '200px',
    }),
  };

  const options = useMemo(
    () => getDropdownOption(dropdownOptions),
    [dropdownOptions],
  );

  // const value = useMemo(() => createOption(dropdownValue), [dropdownValue]);

  const [optionState, setOptionState] = useState(options);

  useEffect(() => {
    setOptionState(options);
  }, [dropdownOptions]);

  return !async ? (
    <CreatableSelect
      onInputChange={loadOptions}
      options={optionState}
      placeholder={placeholder}
      value={dropdownValue}
      styles={selectStylesConfig}
      onCreateOption={handleCreateOption}
      onChange={dropdownChange}
      components={{
        Option: InputOption,
      }}
      isClearable={false}
    />
  ) : (
    <Select
      onInputChange={loadOptions}
      isMulti
      options={optionState}
      placeholder={placeholder}
      value={dropdownValue}
      styles={selectStylesConfig}
      onChange={dropdownChange}
      noOptionsMessage={() => 'No options'}
      components={{
        Option: InputOption,
      }}
      isClearable={false}
    />
  );
};

function InputOption({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) {
  const style = {
    fontFamily: '"Roboto"',
    backgroundColor: '#fff',
    fontSize: '12px',
    padding: '8px 18px',
    lineHeight: 1.4,
    color: '#0e1016',
    cursor: 'pointer',
  };

  // prop assignment
  const props = {
    ...innerProps,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {children}
    </components.Option>
  );
}

export default SearchDropdown;
