import { Grid, styled } from '@mui/material';

const Wrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '500px',
  maxWidth: '100%',
  borderRadius: '16px',
  boxShadow: 'none',
  filter: 'drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.4))',
})) as typeof Grid;

export default Wrapper;
