import { styled } from '@mui/material';

const EmptyScreenRoot = styled('div')(() => ({
  backgroundColor: 'rgba(0,0,0, 0.7)',
  justifyContent: 'center',
  position: 'absolute',
  alignItems: 'center',
  color: '#fefefe',
  letterSpacing: '1.2px',
  display: 'flex',
  height: '100vh',
  width: '100vw',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
}));

export default EmptyScreenRoot;
