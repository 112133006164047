import { Avatar, styled } from '@mui/material';

import { Small } from 'components/typography';

const ProfileAvatar = styled(Avatar)(() => ({
  borderColor: 'white',
})) as typeof Avatar;

const StyledSmallButton = styled(Small)(({ theme }) => ({
  display: 'block',
  padding: '0.5rem',
  fontWeight: '500',
  fontSize: '0.85rem',
  cursor: 'pointer',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  '&:hover': {
    backgroundColor: theme.palette.divider,
  },
})) as typeof Small;

export { ProfileAvatar, StyledSmallButton };
