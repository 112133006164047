import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch, RootState } from 'store/configureStore';
import { adminLoginAction } from './AuthActions';

const useAdminAuth = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isAuth } = useSelector((state: RootState) => state.adminAuth);

  return {
    dispatcherAdminLogin: (payload: any) => dispatch(adminLoginAction(payload)),
    isAuth,
  };
};

export default useAdminAuth;
