/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, Fragment, useEffect, useRef, useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Autocomplete } from '@react-google-maps/api';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { Box, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { useDebounce } from '@uidotdev/usehooks';
import { AxiosError } from 'axios';

import PopoverLayout from 'layouts/mainLayout/components/popoverLayout/PopoverLayout';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';
import { ModalWindow } from 'components/window';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Tooltip } from '@mui/material';
import {
  DatePick,
  GridFieldSet,
  ObjectSelection,
  Search,
  SearchDropdown,
} from 'components/controls';

import { useMap, useSidebar } from 'features/home';

import INITIAL_TRIP_VALUES, {
  INITIAL_FILTER_VALUES,
} from 'features/home/core/initialValues';
import AppConfig from 'config';

import { UTCFormatter } from 'utils/Formatter';
import { isNotEqualObjects } from 'utils/Validation';
import dayjs from 'dayjs';
import useGeocoding from 'hooks/useGeocoding';
import { TRIP_TYPE_DICTIONARY } from 'core/status';
import FollowButton from 'features/home/components/followButton/FollowButton';
import useMediaMatches from 'hooks/useMediaMatches';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';

const closeStyles: CSSProperties & Record<string, any> = {
  color: '#fff',
  marginLeft: 'auto',
  svg: { width: '30px', height: '30px' },
};

const MONTH_OPTION = [
  {
    name: 'All',
    count: 1,
  },
  {
    name: 'Next 7 Days',
    count: 7,
  },
  {
    name: 'Next 30 Days',
    count: 30,
  },
];

const OWNERS_OPTION = [
  {
    name: 'All',
    count: 'ALL',
  },
  {
    name: 'User Only',
    count: 'BASIC',
  },
  {
    name: 'Admin Only',
    count: 'ADMIN',
  },
];

type TFilterProps = {
  title: string;
};

const clearIconStyles = {
  color: '#0e1016',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  opacity: '1',
  backgroundColor: '#e5eaf2',
};

const Filters = ({ title }: TFilterProps): JSX.Element => {
  const {
    dispatcherIndustries,
    dispatcherMapFilters,
    dispatcherMapConfig,
    dispatchSetIndustries,
    dispatchSetAddress,
    clearFilter,
    dispatchSetClearFilter,
  } = useMap();

  const { dispatcherHandleAddTrip, dispatcherSetRequest } = useSidebar();

  const FilterValues = {
    conferenceName: INITIAL_TRIP_VALUES.conferenceName,
    industries: INITIAL_FILTER_VALUES.industries,
    dateFrom: INITIAL_TRIP_VALUES.dateFrom,
    dateTo: INITIAL_TRIP_VALUES.dateTo,
    address: INITIAL_TRIP_VALUES.address,
    ownerRole: OWNERS_OPTION[0].count,
    daysCount: MONTH_OPTION[0].count,
  };

  const addressRef: any = useRef(null);
  const searchAddressRef: any = useRef(null);

  const [mobileSM, laptopM] = useMediaMatches(
    MEDIA_QUERIES_DICTIONARY.MOBILE_SM,
    MEDIA_QUERIES_DICTIONARY.LAPTOP_M,
  );

  /**
   * *@States
   */
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [industryOptions, setIndustryOptions] = useState<Array<string>>(['']);
  const [filtersOldState, setFiltersOldState] = useState<any>({});
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [industryValue, setIndustryValue] = useState(['']);
  const [monthCountValue, setMonthCountValue] = useState<number | string>(
    MONTH_OPTION[0].count,
  );
  const [ownerValueState, setOwnerValueState] = useState<number | string>(
    OWNERS_OPTION[0].count,
  );
  const [initFormik, setInitFormik] =
    useState<Record<string, any>>(FilterValues);

  const [conferenceState, setConferenceState] = useState<string>(
    TRIP_TYPE_DICTIONARY.conference,
  );

  const anchorRef = useRef<HTMLButtonElement>(null);

  const [dateRange, setDateRange] = useState(['', '']);
  const { getCoord } = useGeocoding();

  const handleOpenFilter = (): void => {
    setOpenFilters((prevState: boolean) => !prevState);
  };

  const handleCloseFilter = (): void => {
    setOpenFilters(false);
  };

  /**
   * *@Formik for filters
   */
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initFormik,
    enableReinitialize: true,
    onSubmit: () => {
      let coordinatesFromGeo = null;

      const searchRef = searchAddressRef?.current?.value;
      const filterRef = addressRef?.current?.value;

      let addressValue = '';

      if (searchRef.length > filterRef.length) {
        addressValue = searchRef || values.address;
      } else {
        addressValue = filterRef || values.address;
      }

      setFieldValue('address', addressValue);

      if (addressValue) {
        dispatcherMapConfig({
          filterCoordinates: null,
          filterZooming: null,
          fetching: true,
          industries: null,
        });
      }

      let geoAddressValue: any = null;

      const address = addressValue;
      const geoAddress = getCoord(address);
      geoAddress
        .then((geo: any) => {
          values.address = address;

          coordinatesFromGeo = geo?.coord;

          const city = geo?.city;
          // const country = geo?.country;
          const formattedData = geo?.formatterAddress;

          if (formattedData) {
            geoAddressValue = formattedData;
          }
          dispatchSetAddress({ geo, address });
          dispatcherSetRequest(true);

          if (city) {
            dispatcherMapConfig({
              filterCoordinates: geo?.coord,
              filterZooming: 12,
              fetching: false,
            });
          } else {
            dispatcherMapConfig({
              filterCoordinates: geo?.coord,
              filterZooming: mobileSM ? 5 : 7,
              fetching: false,
            });
          }

          const ownerRole = values.ownerRole === 'ALL' ? '' : values.ownerRole;

          const industryArray = [...values.industries];
          const serverIndustryArray = industryArray[0]
            ? industryArray.map((industry: any) => industry?.label)
            : [];

          let conferenceType = null;
          conferenceType = conferenceState;

          const payload: any = {
            ...values,
            daysCount: '',
            address: geoAddressValue || values.address,
            ownerRole: ownerRole,
            industries: serverIndustryArray,
            geoData: geo,
          };

          if (values.daysCount && values.daysCount !== MONTH_OPTION[0].count) {
            payload.daysCount = values.daysCount;
          }

          localStorage.setItem('industry', JSON.stringify(serverIndustryArray));

          setFiltersOldState(values);
          dispatchSetClearFilter(false);
          dispatcherMapFilters(payload);
          dispatchSetIndustries(serverIndustryArray);
          handleCloseFilter();

          addressRef.current.value = addressValue;
          searchAddressRef.current.value = addressValue;
        })
        .catch(() => {
          console.log('Please enter correct address!');
        });

      if (!addressRef.current.value && !searchAddressRef.current.value) {
        const ownerRole = values.ownerRole === 'ALL' ? '' : values.ownerRole;

        const industryArray = [...values.industries];
        const serverIndustryArray = industryArray[0]
          ? industryArray.map((industry: any) => industry?.label)
          : [];

        let conferenceType = null;

        if (values.conferenceName) conferenceType = conferenceState;

        const payload: any = {
          ...values,
          daysCount: '',
          address: geoAddressValue || values.address,
          coordinates: coordinatesFromGeo,
          ownerRole: ownerRole,
          industries: serverIndustryArray,
        };

        if (values.daysCount && values.daysCount !== MONTH_OPTION[0].count) {
          payload.daysCount = values.daysCount;
        }

        localStorage.setItem('industry', JSON.stringify(serverIndustryArray));

        setFiltersOldState(values);
        dispatcherMapFilters(payload);
        dispatchSetIndustries(serverIndustryArray);
        handleCloseFilter();

        addressRef.current.value = addressValue;
        searchAddressRef.current.value = addressValue;
      }
    },
  });

  const handleCancelEvent = () => {
    for (let key in values) {
      values[key] = filtersOldState[key];
    }

    addressRef.current.value = filtersOldState?.address;
    searchAddressRef.current.value = filtersOldState?.address;
    setFieldValue('address', addressRef.current.value);

    handleCloseFilter();
  };

  // useEffect(() => {
  //   const dateFrom: any = values.dateFrom
  //     ? dayjs(values.dateFrom, 'DD/MM/YYYY')
  //     : '';

  //   const dateTo: any = values.dateTo ? dayjs(values.dateTo, 'DD/MM/YYYY') : '';

  //   setDateRange([dateFrom, dateTo]);
  // }, [values]);

  /**
   * *@Fetcher functions
   */
  const fetcherIndustries = (config: any) => {
    dispatcherIndustries(config)
      .unwrap()
      .then((response) => {
        setIndustryOptions(response?.data);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  /**
   * *@Handler functions
   */
  const handleSearch = (): void => {
    handleSubmit();
  };

  const handleChangeDateFrom = (date: any): void => {
    let start: any = '';
    let end: any = '';

    if (date[0]) {
      const utcStart: any = UTCFormatter(date[0]);
      setFieldValue('dateFrom', utcStart);
      values.dateFrom = utcStart;
      start = dayjs(utcStart, 'DD/MM/YYYY');
    }

    if (date[1]) {
      const utcEnd: any = UTCFormatter(date[1]);
      setFieldValue('dateTo', utcEnd);
      values.dateTo = utcEnd;
      end = dayjs(utcEnd, 'DD/MM/YYYY');
    }

    setFieldValue('daysCount', MONTH_OPTION[0].count);
    setMonthCountValue('');
    setDateRange([start, end]);

    if (!date[1] && !date[2]) {
      setFieldValue('dateFrom', '');
      values.dateFrom = '';
      setFieldValue('dateTo', '');
      values.dateTo = '';
      setDateRange(['', '']);
    }
  };

  const handleIndustry = (arrayValue: any): void => {
    const value = [...arrayValue];
    values.industries = value;
    setIndustryValue(value);
    setFieldValue('industries', value);
  };

  const handleMonthCount = (event: any) => {
    let newValue = null;
    if (event.target && event.target.value) newValue = event.target.value;
    else newValue = event;

    setFieldValue('daysCount', newValue);
    setFieldValue('dateFrom', '');
    setFieldValue('dateTo', '');

    setMonthCountValue(newValue);
  };

  const handleOwner = (event: any) => {
    let newValue = null;
    if (event.target && event.target.value) newValue = event.target.value;
    else newValue = event;

    setFieldValue('ownerRole', newValue);

    setOwnerValueState(newValue);
  };

  const handlePopover = (): void => {
    setOpenPopover((prevState: boolean) => !prevState);
  };

  const handleAddressChange = () => {
    if (addressRef.current && addressRef.current.value) {
      setFieldValue('address', addressRef?.current?.value);
      values.address = addressRef?.current?.value;
      searchAddressRef.current.value = addressRef?.current?.value;
    }

    if (addressRef.current.value === '') {
      setFieldValue('address', addressRef?.current?.value);
      values.address = addressRef?.current?.value;
      searchAddressRef.current.value = addressRef?.current?.value;
    }
  };

  const handleSearchAddressChange = () => {
    if (searchAddressRef.current && searchAddressRef.current.value) {
      const address = searchAddressRef?.current?.value;
      setFieldValue('address', searchAddressRef?.current?.value);
      addressRef.current.value = searchAddressRef?.current?.value;
    }

    if (searchAddressRef.current.value === '') {
      setFieldValue('address', searchAddressRef?.current?.value);
      addressRef.current.value = searchAddressRef?.current?.value;
    }
  };

  const handleClearFilters = (): void => {
    setOwnerValueState(OWNERS_OPTION[0].count);
    setFieldValue('ownerRole', OWNERS_OPTION[0].count);
    setFieldValue('conferenceName', ``);
    setFieldValue('dateFrom', ``);
    setFieldValue('dateTo', ``);
    setFieldValue('industries', ['']);
    setIndustryValue(['']);
    setFieldValue('daysCount', MONTH_OPTION[0].count);
    setMonthCountValue(MONTH_OPTION[0].count);
    setDateRange(['', '']);
    setInitFormik(FilterValues);
    values.dateFrom = '';
    values.dateTo = '';
    localStorage.removeItem('industry');
    dispatchSetAddress(null);
    handleSubmit();
  };

  useEffect(() => {
    if (clearFilter) {
      setOwnerValueState(OWNERS_OPTION[0].count);
      setFieldValue('ownerRole', OWNERS_OPTION[0].count);
      setFieldValue('conferenceName', ``);
      setFieldValue('dateFrom', ``);
      setFieldValue('dateTo', ``);
      setFieldValue('industries', ['']);
      setIndustryValue(['']);
      setFieldValue('daysCount', MONTH_OPTION[0].count);
      setMonthCountValue(MONTH_OPTION[0].count);
      setDateRange(['', '']);
      setInitFormik(FilterValues);
      values.dateFrom = '';
      values.dateTo = '';
      localStorage.removeItem('industry');
      values.address = '';
      setFieldValue('address', ``);
      if (addressRef && addressRef.current) addressRef.current.value = '';
      if (searchAddressRef && searchAddressRef.current)
        searchAddressRef.current.value = '';
      setTimeout(() => handleSubmit(), 100);
    }
  }, [clearFilter]);

  const handleClearAddress = (): void => {
    values.address = '';
    setFieldValue('address', ``);
    dispatchSetAddress(null);

    if (addressRef && addressRef.current) addressRef.current.value = '';
    if (searchAddressRef && searchAddressRef.current)
      searchAddressRef.current.value = '';
    handleSubmit();
  };

  const handleLoadOptions = (inputValue: string, callback: any) => {
    if (inputValue) {
      const config = {
        params: {
          query: inputValue,
        },
      };
      fetcherIndustries(config);
    } else {
      fetcherIndustries({});
    }
  };

  const handleAddTrip = (): void => {
    handleCancelEvent();
    dispatcherHandleAddTrip(true);
  };

  /**
   * *@Side Effects
   */
  useEffect(() => {
    fetcherIndustries({});
  }, []);

  /**
   * ?Side Effect for immediately reinitialize
   */
  useEffect(() => {
    handleClearFilters();
    setInitFormik(FilterValues);
  }, []);

  // useEffect(() => {
  //   handleSubmit();
  // }, [debouncedSearchTerm]);

  /**
   * *@Renders functions
   */
  const renderPopoverMenu: JSX.Element = (
    <Flexbox
      flexDirection="column"
      gap="50px"
      mt={mobileSM ? '10px' : 1}
      mb="-20px"
    >
      <ObjectSelection
        name="daysCount"
        placeholder="Time Range"
        value={monthCountValue}
        onChange={handleMonthCount}
        options={MONTH_OPTION}
      />

      <Flexbox
        alignItems="center"
        mt={mobileSM ? '-15px' : '0'}
        flexDirection="column"
        gap="1rem 0rem"
      >
        <DatePick
          value={dateRange}
          onChange={handleChangeDateFrom}
          pickerStyles={{ marginTop: 0, width: '100%' }}
          error={false}
          startLabel="Start"
          endLabel="End"
        />
      </Flexbox>
    </Flexbox>
  );

  const renderPopoverTitle: JSX.Element = (
    <Flexbox alignItems="center">
      <Box ml={0} p={1}>
        <Heading as="h4" content="Choose date filter" />
      </Box>
    </Flexbox>
  );

  const renderDatePopover: JSX.Element = (
    <PopoverLayout
      open={openPopover}
      onClose={handlePopover}
      anchorRef={anchorRef}
      maxWidth={220}
      minWidth={180}
      title={renderPopoverTitle}
    >
      {renderPopoverMenu}
    </PopoverLayout>
  );

  const renderClearButton = isNotEqualObjects(values, FilterValues) ? (
    <FollowButton
      label="Clear Filters"
      success
      onClick={handleClearFilters}
      overrideStyles={{
        minWidth: '120px',
        maxWidth: '130px',
        margin: 0,
        padding: '6px 8px',
        fontSize: '13px',
      }}
    />
  ) : null;

  const renderFilters: JSX.Element = (
    <Flexbox
      alignItems="center"
      gap="0 1rem"
      sx={{ marginLeft: laptopM ? '0px' : '0px', width: '100%' }}
    >
      {renderDatePopover}
      <Autocomplete
        className="autocomplete-wrapper-filter"
        onPlaceChanged={handleSearch}
      >
        <div
          style={{ position: 'relative', width: mobileSM ? '100%' : 'unset' }}
        >
          <input
            className="autocomplete-input-filter"
            placeholder="Search location"
            onChange={handleSearchAddressChange}
            value={
              searchAddressRef?.current?.value ||
              addressRef?.current?.value ||
              values?.address
            }
            ref={searchAddressRef}
            type="text"
          />
          <Flexbox sx={{ backgroundColor: '#e5eaf2' }}>
            {searchAddressRef?.current?.value || values?.address ? (
              <CloseIcon
                sx={{ ...clearIconStyles, right: '10px' }}
                onClick={handleClearAddress}
              />
            ) : null}
            {/* <SearchIcon
              sx={{ ...clearIconStyles, right: '10px' }}
              onClick={handleSearch}
            /> */}
          </Flexbox>
        </div>
      </Autocomplete>

      <FilterListIcon
        onClick={handleOpenFilter}
        sx={{
          color: '#fff',
          width: laptopM ? '30px' : '50px',
          height: laptopM ? '30px' : '50px',
          cursor: 'pointer',
        }}
      />

      {mobileSM && (
        <Tooltip title="Search">
          <IconButton onClick={handleSearch} sx={{ color: '#fff' }}>
            <SearchIcon sx={{ width: '26px', height: '26px' }} />
          </IconButton>
        </Tooltip>
      )}

      {isNotEqualObjects(values, FilterValues) && mobileSM && (
        <Tooltip title="Clear Filters">
          <IconButton onClick={handleClearFilters} sx={{ color: '#fff' }}>
            <SearchOffIcon sx={{ width: '26px', height: '26px' }} />
          </IconButton>
        </Tooltip>
      )}

      {!mobileSM && (
        <FollowButton
          label="Search"
          success
          onClick={handleSearch}
          overrideStyles={{
            minWidth: '90px',
            maxWidth: '100px',
            margin: 0,
            padding: '6px 8px',
            fontSize: '13px',
          }}
        />
      )}

      {!mobileSM && renderClearButton}
    </Flexbox>
  );

  const renderFilterFields = (
    <Fragment>
      {mobileSM && <div style={{ height: '10px', width: '100%' }} />}
      <GridFieldSet
        label={AppConfig.PLACEHOLDERS.ADDRESS}
        className="autocomplete-relative"
      >
        <Autocomplete>
          <input
            className="autocomplete-input"
            placeholder=""
            ref={addressRef}
            type="text"
            onChange={handleAddressChange}
            value={
              addressRef?.current?.value ||
              searchAddressRef?.current?.value ||
              values?.address
            }
          />
        </Autocomplete>
      </GridFieldSet>

      <GridFieldSet label="Conference Name">
        {/* <ActivityRadioGroup
          activeState={conferenceState}
          onChange={handleConferenceState}
        /> */}
        <Search
          name="conferenceName"
          value={values.conferenceName}
          onChange={handleChange}
          styles={{
            width: '100%',
            marginTop: '8px',
            marginBottom: '5px',
            height: '40px',
          }}
        />
      </GridFieldSet>

      <GridFieldSet label="Industry">
        <SearchDropdown
          dropdownOptions={industryOptions}
          placeholder="Type to search"
          dropdownValue={industryValue}
          dropdownChange={handleIndustry}
          loadOptions={handleLoadOptions}
          async
        />
      </GridFieldSet>

      <GridFieldSet label="Date Filter">{renderPopoverMenu}</GridFieldSet>

      <GridFieldSet label="Owners">
        <ObjectSelection
          name="ownerRole"
          placeholder="Owners"
          value={ownerValueState}
          onChange={handleOwner}
          options={OWNERS_OPTION}
          styles={{ marginTop: '10px' }}
        />
      </GridFieldSet>
    </Fragment>
  );

  const renderHelpIcon = (
    <Tooltip title="The exact location of your destination is optional. Minimum requirement is choosing a country">
      <HelpOutlineIcon
        sx={{
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          color: '#fff',
        }}
      />
    </Tooltip>
  );

  const renderDesktopFilterButtons = (
    <Fragment>
      <FollowButton label="Cancel" success onClick={handleCancelEvent} />
      <FollowButton label="Search" onClick={handleSearch} />
    </Fragment>
  );

  const renderMobileFilterButtons = (
    <Fragment>
      <Flexbox flexDirection="column" width="100%">
        <FollowButton
          overrideStyles={{
            fontSize: '15px',
            padding: '8px',
          }}
          label="Search"
          onClick={handleSearch}
        />
        <FollowButton
          overrideStyles={{
            fontSize: '15px',
            padding: '8px',
            marginTop: '12px',
          }}
          label="Create a Trip"
          success
          onClick={handleAddTrip}
        />
      </Flexbox>
    </Fragment>
  );

  return (
    <Fragment>
      {renderFilters}
      <ModalWindow
        title={title}
        open={openFilters}
        onClose={handleCancelEvent}
        onSubmit={handleSearch}
        fields={renderFilterFields}
        supportIcon={
          <IconButton sx={closeStyles} onClick={handleCancelEvent}>
            <CloseIcon />
          </IconButton>
        }
        buttons={
          mobileSM ? renderMobileFilterButtons : renderDesktopFilterButtons
        }
        icon={renderHelpIcon}
        keepMounted
      />
    </Fragment>
  );
};

export default Filters;
