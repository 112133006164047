import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { useNavigate, useParams } from 'react-router-dom';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useState } from 'react';

import { DetailHeader } from 'features/trip/components/tripLayout/TripLayout';
import TColumnsShape from 'features/adminDashboard/models/Columns';
import { NewTripPopup, useMap } from 'features/home';

import { IteractiveCell } from 'components/dataTable';
import Flexbox from 'components/flexbox/Flexbox';
import { ModalWindow } from 'components/window';

import palette from 'theme/theme';
import FollowButton from 'features/home/components/followButton/FollowButton';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import AppConfig from 'config';
import LocaleStorage from 'api/LocaleStorage';
import { UNREAD } from 'core/roles';

const conferenceColumns: (id?: string, editable?: boolean) => TColumnsShape = (
  id,
  editable = true,
) => [
  {
    minWidth: 10,
    Header: '',
    accessor: 'owner',
    Cell: (props) => {
      const data = props.row.original;
      const ownerId = data?.ownerId;
      const isOnwer = ownerId === id;

      return (
        <Flexbox alignItems="center" justifyContent="center">
          {isOnwer ? <LocalPoliceIcon sx={{ color: '#E8A442' }} /> : null}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Trip name',
    accessor: 'name',
    Cell: (props) => {
      const data = props.row.original;
      const tripId = data?.id;
      const name = data?.name;

      const navigate = useNavigate();

      const handleNavigateToProfile = (): void => {
        const url: string = `/trip/${tripId}`;
        navigate(url);
      };

      return (
        <Flexbox
          alignItems="center"
          justifyContent="left"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleNavigateToProfile}
        >
          {name}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Conference name',
    accessor: 'conferenceName',
  },
  {
    minWidth: 50,
    Header: 'Industries',
    accessor: 'industries',
    Cell: (props) => {
      const { industries } = props.row.original;

      const [openModal, setOpenModal] = useState(false);

      const handleOpenModal = () => {
        setOpenModal((prevState) => !prevState);
      };

      const renderIndustries = (
        <Flexbox alignItems="center" flexDirection="column" width="100%">
          {industries.map((industry: string) => (
            <DetailHeader>{industry}</DetailHeader>
          ))}
        </Flexbox>
      );

      return (
        <Flexbox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Read More">
            <IconButton onClick={handleOpenModal}>
              <ReadMoreIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>

          <ModalWindow
            title="Industries"
            open={openModal}
            onClose={handleOpenModal}
            fields={renderIndustries}
            buttons={null}
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Start date',
    accessor: 'dateFrom',
  },
  {
    minWidth: 120,
    Header: 'End date',
    accessor: 'dateTo',
  },
  {
    minWidth: 120,
    Header: 'Destination',
    accessor: 'address',
  },
  {
    minWidth: 10,
    Header: 'Joined',
    accessor: 'joined',
    Cell: (props) => {
      const { ownerId } = props.row.original;
      const { id } = useParams();

      const isTripOwner = id === ownerId;

      return (
        <Flexbox alignItems="center" justifyContent="center">
          {!isTripOwner ? (
            <CheckIcon sx={{ color: palette.green[100] }} />
          ) : null}
        </Flexbox>
      );
    },
  },
  {
    Header: 'Edit',
    accessor: 'edit',
    Cell: (props) => {
      const { ownerId } = props.row.original;
      const { id } = useParams();

      const isTripOwner = id === ownerId;

      return isTripOwner && editable ? (
        <IteractiveCell
          label="Edit Conference"
          row={props.row.original}
          IconComponent={Edit}
          ModalComponent={NewTripPopup}
          edit
        />
      ) : (
        <></>
      );
    },
  },
];

const prevConferenceColumns: (
  id?: string,
  editable?: boolean,
) => TColumnsShape = (id, editable) => [
  {
    minWidth: 10,
    Header: '',
    accessor: 'owner',
    Cell: (props) => {
      const data = props.row.original;
      const ownerId = data?.ownerId;
      const isOnwer = ownerId === id;

      return (
        <Flexbox alignItems="center" justifyContent="center">
          {isOnwer ? <LocalPoliceIcon sx={{ color: '#E8A442' }} /> : null}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Trip name',
    accessor: 'name',
    Cell: (props) => {
      const data = props.row.original;
      const tripId = data?.id;
      const name = data?.name;

      const navigate = useNavigate();

      const handleNavigateToProfile = (): void => {
        const url: string = `/trip/${tripId}`;
        navigate(url);
      };

      return (
        <Flexbox
          alignItems="center"
          justifyContent="left"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleNavigateToProfile}
        >
          {name}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Conference name',
    accessor: 'conferenceName',
  },
  {
    minWidth: 50,
    Header: 'Industries',
    accessor: 'industries',
    Cell: (props) => {
      const { industries } = props.row.original;

      const [openModal, setOpenModal] = useState(false);

      const handleOpenModal = () => {
        setOpenModal((prevState) => !prevState);
      };

      const renderIndustries = (
        <Flexbox alignItems="center" flexDirection="column" width="100%">
          {industries.map((industry: string) => (
            <DetailHeader>{industry}</DetailHeader>
          ))}
        </Flexbox>
      );

      return (
        <Flexbox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Read More">
            <IconButton onClick={handleOpenModal}>
              <ReadMoreIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>

          <ModalWindow
            title="Industries"
            open={openModal}
            onClose={handleOpenModal}
            fields={renderIndustries}
            buttons={null}
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Start date',
    accessor: 'dateFrom',
  },
  {
    minWidth: 120,
    Header: 'End date',
    accessor: 'dateTo',
  },
  {
    minWidth: 120,
    Header: 'Destination',
    accessor: 'address',
  },
  {
    minWidth: 10,
    Header: 'Joined',
    accessor: 'joined',
    Cell: (props) => {
      const { isCurrentUserFollowing } = props.row.original;

      return (
        <Flexbox alignItems="center" justifyContent="center">
          {isCurrentUserFollowing ? (
            <CheckIcon sx={{ color: palette.green[100] }} />
          ) : null}
        </Flexbox>
      );
    },
  },
];

const conferencePreviewColumns: (
  id?: string,
  editable?: boolean,
) => TColumnsShape = (id, editable = true) => [
  {
    minWidth: 10,
    Header: '',
    accessor: 'owner',
    Cell: (props) => {
      const data = props.row.original;
      const ownerId = data?.ownerId;
      const isOnwer = ownerId === id;

      return (
        <Flexbox alignItems="center" justifyContent="center">
          {isOnwer ? <LocalPoliceIcon sx={{ color: '#E8A442' }} /> : null}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Trip name',
    accessor: 'name',
    Cell: (props) => {
      const data = props.row.original;
      const tripId = data?.id;
      const name = data?.name;

      const navigate = useNavigate();

      const handleNavigateToProfile = (): void => {
        const url: string = `/trip/${tripId}`;
        navigate(url);
      };

      return (
        <Flexbox
          alignItems="center"
          justifyContent="left"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleNavigateToProfile}
        >
          {name}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Conference name',
    accessor: 'conferenceName',
  },
  {
    minWidth: 50,
    Header: 'Industries',
    accessor: 'industries',
    Cell: (props) => {
      const { industries } = props.row.original;

      const [openModal, setOpenModal] = useState(false);

      const handleOpenModal = () => {
        setOpenModal((prevState) => !prevState);
      };

      const renderIndustries = (
        <Flexbox alignItems="center" flexDirection="column" width="100%">
          {industries.map((industry: string) => (
            <DetailHeader>{industry}</DetailHeader>
          ))}
        </Flexbox>
      );

      return (
        <Flexbox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Read More">
            <IconButton onClick={handleOpenModal}>
              <ReadMoreIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>

          <ModalWindow
            title="Industries"
            open={openModal}
            onClose={handleOpenModal}
            fields={renderIndustries}
            buttons={null}
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Start date',
    accessor: 'dateFrom',
  },
  {
    minWidth: 120,
    Header: 'End date',
    accessor: 'dateTo',
  },
  {
    minWidth: 120,
    Header: 'Destination',
    accessor: 'address',
  },
  {
    minWidth: 100,
    Header: 'Follow Trip',
    accessor: 'follow',
    textAlign: 'center',
    Cell: (props) => {
      const { isCurrentUserFollowing, ownerName } = props.row.original;
      const { ownerId } = props.row.original;

      const { id } = useParams();

      const isTripOwner = id === ownerId;
      // console.log('isTripOwner', editable);

      const { dispatcherJoinTrip, dispatcherLeaveTrip } = useMap();
      const isAuth: string = LocaleStorage.get('auth') || UNREAD;
      const isGuest = isAuth === UNREAD;
      const isAdmin = ownerName === 'Admin';

      const joinedTrip: boolean = isCurrentUserFollowing;

      const [joinedTripState, setJoinedTripState] =
        useState<boolean>(joinedTrip);

      const fetcherJoinTrip = (config: any) => {
        dispatcherJoinTrip(config)
          .unwrap()
          .then((response: any) => {
            setJoinedTripState((prevState: boolean) => !prevState);
          })
          .catch((error: AxiosError) => {
            if (error && error.response && error.response.data) {
              const message = error.response.data;
              toast.error(message.toString());
            } else {
              toast.error(AppConfig.NOTIFICATION.FAILED);
            }
          });
      };

      const fetcherLeaveTrip = (config: any) => {
        dispatcherLeaveTrip(config)
          .unwrap()
          .then((response: any) => {
            setJoinedTripState(false);
          })
          .catch((error: AxiosError) => {
            if (error && error.response && error.response.data) {
              const message = error.response.data;
              toast.error(message.toString());
            } else {
              toast.error(AppConfig.NOTIFICATION.FAILED);
            }
          });
      };

      const handleJoinTrip = (): void => {
        const config = {
          params: {
            tripId: props.row.original?.id,
          },
        };

        if (joinedTripState) {
          fetcherLeaveTrip(config);
        } else {
          fetcherJoinTrip(config);
        }
      };

      return (
        <Flexbox
          flexDirection="column"
          alignItems={editable ? 'flex-start' : 'center'}
          justifyContent="center"
          p={editable ? '0 20px' : '0'}
        >
          {!isGuest && !editable && (
            <FollowButton
              label={joinedTripState ? 'Unfollow Trip' : 'Follow Trip'}
              active={joinedTripState}
              onClick={handleJoinTrip}
              overrideStyles={{
                margin: 0,
                width: '100px',
                fontSize: '13px',
                padding: '5px 10px',
              }}
            />
          )}
          {isTripOwner && editable && (
            <IteractiveCell
              label="Edit Conference"
              row={props.row.original}
              IconComponent={Edit}
              ModalComponent={NewTripPopup}
              edit
            />
          )}
        </Flexbox>
      );
    },
  },
];

export { prevConferenceColumns, conferencePreviewColumns };
export default conferenceColumns;
