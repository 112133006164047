import { useJsApiLoader } from '@react-google-maps/api';

import Map from 'features/home/components/map/Map';

const HomePage = (): JSX.Element => {
  const MAP_KEY: string = 'AIzaSyDOtfqd9NKCDOlyyLJo6GJSQh7AM97oU4Q';

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
    id: 'script-loader',
    libraries: ['places'],
    language: 'en',
    region: 'United States',
  });

  if (!isLoaded) return <div>Loading...</div>;

  return <Map />;
};

export default HomePage;
