/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, useRef, CSSProperties } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { AxiosError, AxiosResponse } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import dayjs from 'dayjs';

import LinkedinShareModule from 'features/home/modules/LinkedinShareModule';

import ActivityRadioGroup from '../activityRadioGroup/ActivityRadioGroup';
import FollowButton from '../followButton/FollowButton';
import LoadButton from '../loadButton/LoadButton';
import Flexbox from 'components/flexbox/Flexbox';
import { ModalWindow } from 'components/window';
import {
  DatePick,
  ErrorText,
  FormButton,
  GridFieldSet,
  Textarea,
  Textfield,
} from 'components/controls';

import TInitialTripValues from 'features/home/models/initialValues';
import TNewTripProps from 'features/home/models/Popups';

import useSidebar from 'features/home/redux/sidebar/SidebarHook';
import { useAdminDashboard } from 'features/adminDashboard';
import useMap from 'features/home/redux/map/MapHook';
import useMediaMatches from 'hooks/useMediaMatches';
import useGeocoding from 'hooks/useGeocoding';
import { useProfile } from 'features/profile';

import {
  ADD_TRIP_FROM_FILE_ENDPOINT,
  POST_TRIP_IMAGE_ENDPOINT,
} from 'api/Endpoints';
import LocaleStorage from 'api/LocaleStorage';
import { axiosInstance } from 'api/API';

import INITIAL_TRIP_VALUES from '../../core/initialValues';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import AppConfig from 'config';
import {
  ACTIVE_STATUS,
  DELETE_STATUS,
  HIDE_STATUS,
  TRIP_TYPE_DICTIONARY,
} from 'core/status';

import NewTripSchema from 'features/home/validation/Schema';

import { addClass, removeClass } from 'utils/Generic';
import { UTCFormatter } from 'utils/Formatter';
import ImageUploader from 'components/imageUploader/ImageUploader';
import { ImageFile } from 'features/trip/components/tripLayout/TripLayout';
import { ImagePexelModule } from 'modules/pexelApiModule.jsx/ImagePexelModule';

const closeStyles: CSSProperties & Record<string, any> = {
  color: '#fff',
  marginLeft: 'auto',
  svg: { width: '30px', height: '30px' },
};

const NewTripPopup = ({
  title,
  open,
  onClose,
  data,
  edit,
}: TNewTripProps): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const isHideTrip: boolean = data?.isStatusHide;
  const [openShareWindow, setOpenShareWindow] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<string>(``);
  const [tripIDState, setTripIDState] = useState<string>('');
  const [dateRange, setDateRange] = useState(['', '']);

  const addressRef: any = useRef(null);

  const { dispatcherCreateTrip, dispatcherSetFetchCoords } = useMap();
  const { dispatcherEditTrips, dispatchSetFetcherUser, info } = useProfile();
  const { dispatcherSetRequest } = useSidebar();

  const { getCoord } = useGeocoding();

  const {
    dispatcherAdminRemoveTrip,
    dispatcherSetTripsFetching,
    dispatcherAdminChangeStatus,
  } = useAdminDashboard();

  const role = LocaleStorage.get('role');
  const isAdmin = role === 'admin';
  const userIndustry = info?.data?.industry || null;
  const defaultIndustry = userIndustry ? `${userIndustry}` : '';

  const InitValues: TInitialTripValues = {
    name: data?.name || INITIAL_TRIP_VALUES.name,
    industries: data?.industries || defaultIndustry,
    conferenceName: data?.conferenceName || INITIAL_TRIP_VALUES.conferenceName,
    dateFrom: data?.dateFrom || INITIAL_TRIP_VALUES.dateFrom,
    dateTo: data?.dateTo || INITIAL_TRIP_VALUES.dateTo,
    address: data?.address || INITIAL_TRIP_VALUES.address,
    coordinates: data?.coordinates || INITIAL_TRIP_VALUES.coordinates,
    description: data?.description || INITIAL_TRIP_VALUES.description,
    type: data?.type || TRIP_TYPE_DICTIONARY.other,
    eventLink: data?.eventLink || '',
    city: data?.city || '',
    country: data?.city || '',
  };

  const [tripImage, setTripImage] = useState<any>(null);
  const [tripImageFile, setTripImageFile] = useState<any>(null);
  const [pexelName, setPexelName] = useState(null);

  const [initFormik, setInitFormik] = useState<TInitialTripValues>(InitValues);

  useEffect(() => {
    const dateFrom: any = values.dateFrom
      ? dayjs(values.dateFrom, 'DD/MM/YYYY')
      : '';

    const dateTo: any = values.dateTo ? dayjs(values.dateTo, 'DD/MM/YYYY') : '';

    setDateRange([dateFrom, dateTo]);
  }, [data]);

  /**
   * *@Fetcher functions
   */
  const fetcherCreateTrip = (formData: any) => {
    const payload = {
      data: { ...formData },
    };

    dispatcherSetFetchCoords(false);

    dispatcherCreateTrip(payload)
      .unwrap()
      .then((response: AxiosResponse) => {
        toast.success(AppConfig.NOTIFICATION.SUCCESS_TRIP, {
          duration: 3000,
        });
        const tripId = response.data?.tripId;
        dispatcherSetFetchCoords(true);
        resetForm();
        setFieldValue('dateFrom', '');
        values.dateFrom = '';
        setFieldValue('dateTo', '');
        values.dateTo = '';
        setDateRange(['', '']);
        onClose();
        setTripIDState(tripId);
        setOpenShareWindow(true);
      })
      .catch((error: AxiosError) => {
        console.log(error);
        if (error && error.response && error.response.data) {
          const message: any = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetcherEditTrip = (formData: any) => {
    const payload = {
      data: { ...formData, id: data?.id },
    };

    dispatcherEditTrips(payload)
      .unwrap()
      .then((response: AxiosResponse) => {
        toast.success(AppConfig.NOTIFICATION.SUCCESS_TRIP_EDIT, {
          duration: 3000,
        });
        dispatchSetFetcherUser(true);
        dispatcherSetRequest(false);

        setTimeout(() => {
          dispatcherSetRequest(true);
        }, 500);

        if (isAdmin && edit) {
          dispatcherSetTripsFetching(true);
        }
        resetForm();
        onClose();
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message: any = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    touched,
    errors,
  } = useFormik({
    initialValues: initFormik,
    validationSchema: NewTripSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const address = addressRef.current.value;
      const input = addressRef.current;
      const geoAddress = getCoord(address);
      geoAddress
        .then((geo: any) => {
          values.address = address;
          values.coordinates = geo?.coord;
          values.city = geo?.city;
          values.country = geo?.country;
          const industryArray = Array.isArray(values.industries)
            ? values.industries
            : [values.industries];
          const payload = {
            ...values,
            industries: industryArray,
            geoData: geo,
            image: tripImage,
          };
          edit ? fetcherEditTrip(payload) : fetcherCreateTrip(payload);
          setAddressError('');
          removeClass(input, '_error');
        })
        .catch(() => {
          setAddressError('Please enter correct address!');
          addClass(input, '_error');
        });
    },
  });

  const fetcherRemoveTrip = (config: any) => {
    dispatcherAdminRemoveTrip(config)
      .unwrap()
      .then((response: any) => {
        toast.success(response.data, { duration: 3000 });
        onClose();
        dispatchSetFetcherUser(true);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  /**
   * ?Side Effect for immediately reinitialize
   */
  useEffect(() => {
    setInitFormik(InitValues);
  }, [open]);

  /**
   * *@Handler functions
   */
  const handleChangeDateFrom = (date: any): void => {
    setDateRange(date);
    if (date[0]) {
      const utcStart: any = UTCFormatter(date[0]);
      setFieldValue('dateFrom', utcStart);
      values.dateFrom = utcStart;
    }

    if (date[1]) {
      const utcEnd: string | null = UTCFormatter(date[1]);
      setFieldValue('dateTo', utcEnd);
      values.dateTo = utcEnd;
    }

    if (!date[1] && !date[2]) {
      setFieldValue('dateFrom', '');
      values.dateFrom = '';
      setFieldValue('dateTo', '');
      values.dateTo = '';
    }
  };

  const handleAddressChange = () => {
    setFieldValue('address', addressRef?.current?.value);
  };

  const handleDeleteTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
      },
    };
    fetcherRemoveTrip(config);
  };

  const handleShareWindow = (): void => {
    setOpenShareWindow((prevState: boolean) => !prevState);
  };

  const handleCloseShareWindow = (): void => {
    handleShareWindow();
  };

  const fetcherChangeStatusTrip = (config: any) => {
    dispatcherAdminChangeStatus(config)
      .unwrap()
      .then((response: any) => {
        toast.success(response.data, { duration: 3000 });
        onClose();
        dispatcherSetTripsFetching(true);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const fetchTripFile = async (data: FormData, type: string) => {
    await axiosInstance
      .post(ADD_TRIP_FROM_FILE_ENDPOINT, data, {
        headers: {
          'Content-Type': type,
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          toast.success('File successfuly uploaded!', { duration: 2000 });
          onClose();
          dispatcherSetFetchCoords(true);
        }
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const handleIploadCSV = (event: any): void => {
    event.preventDefault();
    const files: any = event.target.files;
    const contentType: string = files[0].type;
    const formData: FormData = new FormData();

    formData.append('file', files[0]);
    fetchTripFile(formData, contentType);
  };

  const handleHideTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
        status: HIDE_STATUS,
      },
    };
    fetcherChangeStatusTrip(config);
  };

  const handleRemoveTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
        status: DELETE_STATUS,
      },
    };
    fetcherChangeStatusTrip(config);
  };

  const handleShowTrip = (): void => {
    const config = {
      params: {
        id: data?.id,
        status: ACTIVE_STATUS,
      },
    };
    fetcherChangeStatusTrip(config);
  };

  const handleConferenceState = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value;
    values.type = value;
    setFieldValue('type', value);
  };

  useEffect(() => {
    if (!open) {
      resetForm();
      values.name = '';
      values.conferenceName = '';
      setFieldValue('name', '');
      setFieldValue('conferenceName', '');
      setFieldValue('dateFrom', '');
      values.dateFrom = '';
      setFieldValue('dateTo', '');
      values.dateTo = '';
      setDateRange(['', '']);
      setInitFormik(InitValues);
      setTripImage(null);
      setTripImageFile(null);
      setPexelName(null);
    }
  }, [open]);

  const bindOnClose = () => {
    if (!edit) {
      resetForm();
      values.name = '';
      values.conferenceName = '';
      setFieldValue('name', '');
      setFieldValue('conferenceName', '');
      setFieldValue('dateFrom', '');
      values.dateFrom = '';
      setFieldValue('dateTo', '');
      values.dateTo = '';
      setDateRange(['', '']);
      setInitFormik(InitValues);
      setTripImage(null);
      setTripImageFile(null);
      setPexelName(null);
    }
    onClose();
  };

  const fetchTripImage = async (data: FormData, type: string) => {
    await axiosInstance
      .post(POST_TRIP_IMAGE_ENDPOINT, data, {
        headers: {
          'Content-Type': type,
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setTripImage(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const handleUploadImage = (event: any): void => {
    event.preventDefault();
    const files: any = event.target.files;
    const contentType: string = files[0].type;
    const formData: FormData = new FormData();

    formData.append('file', files[0]);
    setTripImageFile(files[0]);
    fetchTripImage(formData, contentType);
  };

  const handlePlacedChanged = () => {
    setPexelName(addressRef?.current?.value);
    console.log('address', addressRef?.current?.value);
  };

  const handleAddPexelImage = (pexelImage: any) => (_event: any) => {
    console.log(pexelImage);
    const url = pexelImage?.src?.original;
    setTripImage(url);
  };

  /**
   * *@Renders functions
   */
  const renderAddTripFields: JSX.Element = (
    <Fragment>
      {mobileSM && <div style={{ width: '100%', height: '20px' }} />}
      <GridFieldSet label={AppConfig.PLACEHOLDERS.TRIP_NAME}>
        <Textfield
          name="name"
          value={values.name}
          onChange={handleChange}
          type="text"
          error={Boolean(errors.name && touched.name)}
          helperText={touched.name && errors.name}
          sx={{ marginTop: '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label={AppConfig.PLACEHOLDERS.INDUSTRY}>
        <div style={{ margin: '0.5rem 0' }}>
          <Textfield
            name="industries"
            value={values.industries}
            onChange={handleChange}
            type="text"
            error={Boolean(errors.industries && touched.industries)}
            helperText={touched.industries && errors.industries}
            sx={{ marginTop: '10px' }}
          />
        </div>
      </GridFieldSet>

      <GridFieldSet label="Event Link">
        <Textfield
          name="eventLink"
          value={values.eventLink}
          onChange={handleChange}
          type="text"
          error={Boolean(errors.eventLink && touched.eventLink)}
          helperText={touched.eventLink && errors.eventLink}
          sx={{ marginTop: '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label="">
        <ActivityRadioGroup
          activeState={values.type}
          onChange={handleConferenceState}
        />
        <Textfield
          name="conferenceName"
          placeholder={
            values.type === TRIP_TYPE_DICTIONARY.conference
              ? 'Conference Name'
              : 'Other Activity'
          }
          value={values.conferenceName}
          onChange={handleChange}
          type="text"
          error={Boolean(errors.conferenceName && touched.conferenceName)}
          helperText={touched.conferenceName && errors.conferenceName}
          sx={{ marginBottom: '20px' }}
        />
      </GridFieldSet>

      <GridFieldSet label="">
        <DatePick
          value={dateRange}
          onChange={handleChangeDateFrom}
          error={
            Boolean(errors.dateFrom && touched.dateFrom) ||
            Boolean(errors.dateTo && touched.dateTo)
          }
          helperText={
            (touched.dateFrom && errors.dateFrom) ||
            (touched.dateTo && errors.dateTo)
          }
        />
      </GridFieldSet>

      <GridFieldSet
        label={AppConfig.PLACEHOLDERS.ADDRESS}
        className="autocomplete-relative"
        help
      >
        <Autocomplete onPlaceChanged={handlePlacedChanged}>
          <input
            className="autocomplete-input"
            ref={addressRef}
            type="text"
            onChange={handleAddressChange}
            value={addressRef?.current?.value || data?.address}
          />
        </Autocomplete>
        <ErrorText>{addressError}</ErrorText>
      </GridFieldSet>

      <GridFieldSet label="Comment">
        <Textarea
          name="description"
          onTextareaChange={handleChange}
          value={values.description}
        />
      </GridFieldSet>

      <GridFieldSet label="Trip Image">
        <ImagePexelModule
          location={pexelName || data?.address}
          onAddImage={handleAddPexelImage}
          currentImageUrl={tripImage}
        />
        {(pexelName || data?.address) && (
          <div
            style={{
              textAlign: 'center',
              color: '#fff',
              fontSize: '18px',
              marginTop: '10px',
            }}
          >
            Or
          </div>
        )}
        <ImageUploader onUpload={handleUploadImage} />
        {tripImageFile && <ImageFile>File: {tripImageFile?.name}</ImageFile>}
      </GridFieldSet>
    </Fragment>
  );

  const renderFormButtons: JSX.Element = (
    <Flexbox justifyContent="space-between" mt="10px" gap="0 20px">
      {!isHideTrip ? (
        <FollowButton label="Hide Trip" onClick={handleHideTrip} />
      ) : null}

      {isHideTrip ? (
        <FollowButton label="Show Trip" onClick={handleShowTrip} />
      ) : null}

      <FormButton
        as="simple"
        variant="contained"
        label="Remove Trip"
        onClick={handleRemoveTrip}
        sx={{ width: '100%' }}
      />
    </Flexbox>
  );

  const renderButtons = mobileSM ? (
    <Flexbox flexDirection="column" justifyContent="space-between" mt="10px">
      <FollowButton
        onClick={handleSubmit}
        label={edit ? 'Update Trip' : 'Create Trip'}
        overrideStyles={{ fontSize: '13px' }}
      />
      <FollowButton
        label="Cancel"
        onClick={onClose}
        success
        overrideStyles={{ fontSize: '13px', marginTop: '10px' }}
      />
    </Flexbox>
  ) : (
    <Flexbox justifyContent="space-between" mt="10px" gap="0 20px">
      <FollowButton label="Cancel" onClick={onClose} success />
      <FollowButton
        onClick={handleSubmit}
        label={edit ? 'Update Trip' : 'Create Trip'}
      />
    </Flexbox>
  );

  const renderAddTripWindowBtns: JSX.Element = (
    <Fragment>
      <Flexbox
        flexDirection="column"
        gap="0.5rem 0"
        sx={{ width: '100%', marginTop: '-20px' }}
      >
        {!edit && isAdmin ? (
          <LoadButton
            buttonLabel="Upload Conferences"
            onUpload={handleIploadCSV}
          />
        ) : null}

        {renderButtons}

        {edit && !isAdmin ? (
          <FollowButton
            onClick={handleDeleteTrip}
            label="Delete Trip"
            success
            overrideStyles={{
              fontSize: mobileSM ? '13px' : '16px',
              marginTop: mobileSM ? '5px' : '20px',
            }}
          />
        ) : null}
        {isAdmin && edit ? renderFormButtons : null}
      </Flexbox>
    </Fragment>
  );

  return (
    <Fragment>
      <ModalWindow
        title={title}
        open={open}
        onClose={bindOnClose}
        onSubmit={handleSubmit}
        fields={renderAddTripFields}
        buttons={renderAddTripWindowBtns}
        supportIcon={
          <IconButton sx={closeStyles} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />

      {!edit && (
        <LinkedinShareModule
          open={openShareWindow}
          onCancel={handleCloseShareWindow}
          shareTripId={tripIDState}
        />
      )}
    </Fragment>
  );
};

export default NewTripPopup;
