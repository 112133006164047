import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { AxiosResponse } from 'axios';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Form, FormButton, GridFieldSet } from 'components/controls';
import Textfield from 'components/controls/textfield/Textfield';

import useUserConfirmation from 'features/userConfirmation/redux/ConfirmationHook';

import TUserConfirmationValues from 'features/userConfirmation/models/initialValuesModel';

import INITIAL_CONFIRMATION_VALUES from 'features/userConfirmation/core/initialValues';
import ConfirmationSchema from 'features/userConfirmation/validation/Schema';
import { HOME_ROUTE } from 'routes/core/RouterConstant';
import AppConfig from 'config';

const ConfirmationFormComponent = (): JSX.Element => {
  const [initFormik, setInitFormik] = useState<TUserConfirmationValues>(
    INITIAL_CONFIRMATION_VALUES,
  );

  const navigate: NavigateFunction = useNavigate();
  const { dispatcherConfirmation } = useUserConfirmation();
  /**
   * *@Fetcher functions
   */
  const fetcherConfirmation = (formData: TUserConfirmationValues) => {
    const config: Record<string, any> = {
      params: {
        ...formData,
      },
    };
    dispatcherConfirmation(config)
      .unwrap()
      .then((response: AxiosResponse) => {
        toast.success(AppConfig.NOTIFICATION.SUCCESS_CONFIRM, {
          duration: 3000,
        });
        navigate(HOME_ROUTE);
      })
      .catch((error: unknown) => {
        toast.error(AppConfig.NOTIFICATION.FAILED);
      });
  };

  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: initFormik,
    enableReinitialize: true,
    validationSchema: ConfirmationSchema,
    onSubmit: (values) => {
      fetcherConfirmation(values);
    },
  });

  useEffect(() => {
    setInitFormik(INITIAL_CONFIRMATION_VALUES);
  }, []);

  const renderFields: JSX.Element = (
    <>
      <GridFieldSet label={AppConfig.PLACEHOLDERS.INDUSTRY_FILL} light>
        <Textfield
          name="industry"
          value={values.industry}
          onChange={handleChange}
          error={Boolean(errors.industry && touched.industry)}
          helperText={touched.industry && errors.industry}
        />
      </GridFieldSet>

      <GridFieldSet label={AppConfig.PLACEHOLDERS.COMPANY_FILL} light>
        <Textfield
          name="currentCompany"
          value={values.currentCompany}
          onChange={handleChange}
          error={Boolean(errors.currentCompany && touched.currentCompany)}
          helperText={touched.currentCompany && errors.currentCompany}
        />
      </GridFieldSet>

      <GridFieldSet label={AppConfig.PLACEHOLDERS.POSITION_FILL} light>
        <Textfield
          name="currentPosition"
          value={values.currentPosition}
          onChange={handleChange}
          error={Boolean(errors.currentPosition && touched.currentPosition)}
          helperText={touched.currentPosition && errors.currentPosition}
        />
      </GridFieldSet>
    </>
  );

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {renderFields}
      <Box mt={4} width="100%">
        <FormButton
          label="Save"
          as="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            width: '100%',
          }}
        />
      </Box>
    </Form>
  );
};

const ConfirmationForm = React.memo(ConfirmationFormComponent);
export default ConfirmationForm;
