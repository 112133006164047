class LocaleStorage {
  static set = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  static get = (key: string) => {
    try {
      const data: string | null = localStorage.getItem(key);
      return data;
    } catch (e: unknown) {
      return undefined;
    }
  };

  static getAll = () => {
    let data: { [key: string]: any } = {};

    for (let index: number = 0; index < localStorage.length; index++) {
      const key: string | null = localStorage.key(index) || 'not_found';
      data[key] = localStorage.get(key);
    }

    return data;
  };

  static remove = (key: string) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e: unknown) {
      return false;
    }
  };

  static clear = () => {
    try {
      localStorage.clear();
    } catch (e: unknown) {
      return false;
    }
  };
}

export default LocaleStorage;
