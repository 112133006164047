import { NavigateFunction, useNavigate } from 'react-router-dom';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import LaunchIcon from '@mui/icons-material/Launch';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Edit } from '@mui/icons-material';
import { useState } from 'react';

import { DetailHeader } from 'features/trip/components/tripLayout/TripLayout';
import TColumnsShape from 'features/adminDashboard/models/Columns';
import { NewTripPopup } from 'features/home';

import { FlexCell, ModalWindow } from 'components/window';
import { IteractiveCell } from 'components/dataTable';
import Flexbox from 'components/flexbox/Flexbox';
import UsersPopup from '../popups/UsersPopup';

import {
  PROFILE_PREVIEW_ROUTE,
  PROFILE_WID_ROUTE,
} from 'routes/core/RouterConstant';
import { ADMIN_USER } from 'core/roles';

const columns: TColumnsShape = [
  {
    minWidth: 10,
    Header: '',
    accessor: 'owner',
    Cell: (props) => {
      const data = props.row.original;
      const role = data?.ownerRole;

      const isAdmin = role === ADMIN_USER;

      return (
        <Flexbox alignItems="center" gap="0 10px">
          {isAdmin ? <LocalPoliceIcon sx={{ color: '#ffc300' }} /> : null}
        </Flexbox>
      );
    },
  },
  {
    minWidth: 200,
    Header: 'User',
    accessor: 'ownerName',
    Cell: (props) => {
      const { ownerName, ownerRole } = props.row.original;

      const isAdmin = ownerRole === ADMIN_USER;

      return isAdmin ? (
        <div style={{ color: '#1976d2' }}>{ownerName}</div>
      ) : (
        <div>{ownerName}</div>
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Trip name',
    accessor: 'name',
    Cell: (props) => {
      const { name, ownerRole } = props.row.original;

      const isAdmin = ownerRole === ADMIN_USER;

      const tripId = props.row.original?.id;

      const navigate = useNavigate();

      const handleNavigateToProfile = (): void => {
        const url: string = `/trip/${tripId}`;
        navigate(url);
      };

      return isAdmin ? (
        <div
          style={{
            color: '#1976d2',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleNavigateToProfile}
        >
          {name}
        </div>
      ) : (
        <div
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleNavigateToProfile}
        >
          {name}
        </div>
      );
    },
  },
  {
    minWidth: 170,
    Header: 'Conference name',
    accessor: 'conferenceName',
    Cell: (props) => {
      const { conferenceName, ownerRole } = props.row.original;

      const isAdmin = ownerRole === ADMIN_USER;

      return isAdmin ? (
        <div style={{ color: '#1976d2' }}>{conferenceName}</div>
      ) : (
        <div>{conferenceName}</div>
      );
    },
  },
  {
    minWidth: 50,
    Header: 'Industries',
    accessor: 'industries',
    Cell: (props) => {
      const { industries } = props.row.original;

      const [openModal, setOpenModal] = useState(false);

      const handleOpenModal = () => {
        setOpenModal((prevState) => !prevState);
      };

      const renderIndustries = (
        <Flexbox alignItems="center" flexDirection="column" width="100%">
          {industries.map((industry: string) => (
            <DetailHeader>{industry}</DetailHeader>
          ))}
        </Flexbox>
      );

      return (
        <Flexbox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Read More">
            <IconButton onClick={handleOpenModal}>
              <ReadMoreIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>

          <ModalWindow
            title="Industries"
            open={openModal}
            onClose={handleOpenModal}
            fields={renderIndustries}
            buttons={null}
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'Start date',
    accessor: 'dateFrom',
    Cell: (props) => {
      const { dateFrom, ownerRole } = props.row.original;

      const isAdmin = ownerRole === ADMIN_USER;

      return isAdmin ? (
        <div style={{ color: '#1976d2' }}>{dateFrom}</div>
      ) : (
        <div>{dateFrom}</div>
      );
    },
  },
  {
    minWidth: 120,
    Header: 'End date',
    accessor: 'dateTo',
    Cell: (props) => {
      const { dateTo, ownerRole } = props.row.original;

      const isAdmin = ownerRole === ADMIN_USER;

      return isAdmin ? (
        <div style={{ color: '#1976d2' }}>{dateTo}</div>
      ) : (
        <div>{dateTo}</div>
      );
    },
  },
  {
    minWidth: 170,
    Header: 'Destination',
    accessor: 'address',
    Cell: (props) => {
      const { address, ownerRole } = props.row.original;

      const isAdmin = ownerRole === ADMIN_USER;

      return isAdmin ? (
        <div style={{ color: '#1976d2' }}>{address}</div>
      ) : (
        <div>{address}</div>
      );
    },
  },
  {
    minWidth: 60,
    Header: 'Hidden',
    accessor: 'isStatusHide',
    Cell: (props) => {
      const data = props.row.original;
      const isHide = data?.isStatusHide;

      return (
        <FlexCell>
          {isHide ? <AddIcon sx={{ color: '#ff5e6c' }} /> : null}
        </FlexCell>
      );
    },
  },
  {
    Header: 'Edit',
    accessor: 'edit',
    Cell: (props) => {
      const { ownerRole } = props.row.original;
      const isAdmin = ownerRole === 'ADMIN';
      return isAdmin ? (
        <IteractiveCell
          label="Edit Conference"
          row={props.row.original}
          IconComponent={Edit}
          ModalComponent={NewTripPopup}
          edit
        />
      ) : (
        <IteractiveCell
          label="Edit User Trip"
          row={props.row.original}
          IconComponent={Edit}
          ModalComponent={UsersPopup}
          edit
        />
      );
    },
  },
  {
    Header: 'Profile',
    accessor: 'profile',
    Cell: (props) => {
      const data = props.row.original;
      const url = `/user/${data.ownerId}`;
      const navigate: NavigateFunction = useNavigate();

      const onLaunch = (): void => {
        navigate(url);
      };

      return (
        <IteractiveCell
          label="Profile"
          row={data}
          IconComponent={LaunchIcon}
          onLaunch={onLaunch}
        />
      );
    },
  },
];

export default columns;
