/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, Stack, Table, TableRow, TableCell } from '@mui/material';
import ScrollBar from 'simplebar-react';
import React, { useMemo } from 'react';
import {
  Cell,
  HeaderGroup,
  Row,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';

import { Heading } from 'components/typography';
import GridDataTableRoot, {
  GridDataTablePagination,
  TableBodyRoot,
  TableHeadRoot,
} from './GridDataTable.styled';

import TGridTableProps from './types';

import { isEmptyArray } from 'utils/Validation';

const GridDataTableComponent = ({
  data,
  columns,
  pageCount,
  pageNo,
  onPageChange,
}: TGridTableProps) => {
  const tableData: any = useMemo(() => data, [data]);
  const columnShape: any = useMemo(() => columns, []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns: columnShape,
        data: tableData,
      },
      usePagination,
      useRowSelect,
    );

  const handleChange = (_event: any, currentPageNo: any) => {
    onPageChange && onPageChange(currentPageNo - 1);
  };

  /**
   * *@Renders functions
   */
  const renderTableHead: JSX.Element = (
    <TableHeadRoot>
      {headerGroups.map((headerGroup: HeaderGroup<Object>, index: number) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column: any, index: number) => (
            <TableCell
              key={index}
              sx={{
                minWidth: column.minWidth,
                textAlign: column?.textAlign,
              }}
            >
              {column.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHeadRoot>
  );

  const renderTableBody: JSX.Element = (
    <TableBodyRoot {...getTableBodyProps()}>
      {rows.map((row: Row<Object>, index: number) => {
        prepareRow(row);
        return (
          <TableRow {...row.getRowProps()} key={index}>
            {row.cells.map((cell: Cell<Object, any>, index: number) => (
              <TableCell {...cell.getCellProps()} key={index}>
                {cell.render('Cell')}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBodyRoot>
  );

  const renderTablePagination: JSX.Element = (
    <Stack alignItems="right" marginY="1rem">
      <GridDataTablePagination
        shape="rounded"
        onChange={handleChange}
        count={pageCount}
        page={pageNo}
        defaultPage={1}
      />
    </Stack>
  );

  return (
    <GridDataTableRoot>
      <Grid container>
        <Grid item xs={12}>
          {isEmptyArray(data) ? (
            <Box p={4}>
              <Heading
                as="h4"
                content="Table is Empty"
                textAlign="center"
                sx={{ color: '#fff' }}
              />
            </Box>
          ) : (
            <Box>
              <ScrollBar>
                <Table {...getTableProps()}>
                  {renderTableHead}
                  {renderTableBody}
                </Table>
              </ScrollBar>

              {renderTablePagination}
            </Box>
          )}
        </Grid>
      </Grid>
    </GridDataTableRoot>
  );
};

const GridDataTable = React.memo(GridDataTableComponent);
export default GridDataTable;
