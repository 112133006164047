import { get_thunk, post_thunk } from 'api/API';

import {
  EDIT_TRIP_ENDPOINT,
  EDIT_USER_ENDPOINT,
  EDIT_USER_PHOTO_EDNPOINT,
  GET_USER_PHOTO_ENDPOINT,
  USER_GET_PREV_TRIPS_ENDPOINT,
  USER_GET_TRIPS_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'api/Endpoints';
import {
  EDIT_USER_PROFILE,
  GET_USER_PHOTO,
  GET_USER_PREV_TRIPS,
  GET_USER_TRIPS,
  USER_EDIT_PHOTO,
  USER_EDIT_TRIP,
  USER_PROFILE,
} from './ProfileConstants';

const userProfileAction = get_thunk(USER_PROFILE, USER_PROFILE_ENDPOINT, false);

const userEditProfileAction = post_thunk(
  EDIT_USER_PROFILE,
  EDIT_USER_ENDPOINT,
  false,
);

const userEditTripAction = post_thunk(
  USER_EDIT_TRIP,
  EDIT_TRIP_ENDPOINT,
  false,
);

const userGetTripsAction = get_thunk(
  GET_USER_TRIPS,
  USER_GET_TRIPS_ENDPOINT,
  false,
);

const userGetPrevTripsAction = get_thunk(
  GET_USER_PREV_TRIPS,
  USER_GET_PREV_TRIPS_ENDPOINT,
  false,
);

const userEditPhotoActions = post_thunk(
  USER_EDIT_PHOTO,
  EDIT_USER_PHOTO_EDNPOINT,
  false,
);

const userGetPhotoActions = get_thunk(
  GET_USER_PHOTO_ENDPOINT,
  GET_USER_PHOTO,
  false,
);

export {
  userEditProfileAction,
  userGetTripsAction,
  userGetPrevTripsAction,
  userEditTripAction,
  userEditPhotoActions,
  userGetPhotoActions,
};
export default userProfileAction;
