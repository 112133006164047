import {
  alpha,
  Card,
  Pagination,
  styled,
  TableBody,
  TableHead,
} from '@mui/material';

import palette from 'theme/theme';

const GridDataTableRoot = styled(Card)(() => ({
  marginTop: '1rem',
  width: '100%',
  color: palette.blue[200],
  boxShadow: palette.shadows.primary,
  border: `1px solid ${alpha(palette.blue[200], 0.1)}`,
  backgroundColor: 'rgba(0, 0, 0, 0.15)',
})) as typeof Card;

const GridDataTablePagination = styled(Pagination)(({ theme }) => ({
  padding: '0 1rem',
  '& .MuiPaginationItem-root': {
    fontSize: 12,
    fontWeight: 500,
    color: '#fff',
  },
  '& .MuiPagination-ul': {
    justifyContent: 'right',
  },
  '& .MuiPaginationItem-page:hover': {
    borderRadius: 50,
    backgroundColor: 'transparent',
    color: '#fff',
    border: `1px solid #fff`,
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    borderRadius: 50,
    backgroundColor: 'transparent',
    color: '#fff',
    border: `1px solid #fff`,
  },
  '& .MuiPaginationItem-previousNext': {
    margin: 10,
    borderRadius: 50,
    color: '#fff',
    border: `1px solid #fff`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})) as typeof Pagination;

const TableHeadRoot = styled(TableHead)(() => ({
  backgroundColor: alpha(palette.black[100], 0.03),
  th: {
    border: 'none',
    lineHeight: '1.5',
    borderBottom: '1px solid rgba(251, 252, 253, 1)',
    textAlign: 'left',
    padding: '1.2rem 1rem',
    color: palette.black[100],
    fontSize: '0.85rem',
    fontWeight: '600',
  },
})) as typeof TableHead;

const TableBodyRoot = styled(TableBody)(() => ({
  td: {
    lineHeight: '1.4',
    textAlign: 'left',
    borderBottom: '1px solid rgba(251, 252, 253, 1)',
    padding: '1rem',
    color: palette.black[100],
    fontSize: '0.8rem',
    fontWeight: '500',
  },
})) as typeof TableBody;

export { GridDataTablePagination, TableHeadRoot, TableBodyRoot };
export default GridDataTableRoot;
