import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { CSSProperties, Fragment, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SvgIcon } from '@mui/material';

import NotificationModule from 'layouts/homeLayout/modules/NotificationModule/NotificationModule';

import PopoverLayout from 'layouts/mainLayout/components/popoverLayout/PopoverLayout';
import NotificationItem from '../notificationItem/NotificationItem';
import Flexbox from 'components/flexbox/Flexbox';
import StyledNotificateCounter from './styled';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import { ModalWindow } from 'components/window';

const closeStyles: CSSProperties & Record<string, any> = {
  color: '#fff',
  marginLeft: 'auto',
  svg: { width: '30px', height: '30px' },
};

const NotificationsIcon = (props: any) => {
  return (
    <SvgIcon viewBox="0 0 32 37" {...props}>
      <path
        d="M31.3273 25.5457C31.1891 25.3805 31.0533 25.2153 30.9201 25.0559C29.0883 22.8582 27.9801 21.5319 27.9801 15.3104C27.9801 12.0894 27.2033 9.44656 25.672 7.46442C24.543 6.00011 23.0168 4.88929 21.0051 4.06835C20.9793 4.05406 20.9561 4.03533 20.9369 4.01301C20.2133 1.60966 18.2333 0 16.0002 0C13.7671 0 11.7879 1.60966 11.0644 4.01054C11.0451 4.03203 11.0223 4.05017 10.9969 4.06422C6.30255 5.98112 4.02114 9.65882 4.02114 15.3079C4.02114 21.5319 2.91458 22.8582 1.08112 25.0535C0.947903 25.2129 0.812185 25.3747 0.673968 25.5432C0.316936 25.9703 0.0907274 26.4899 0.0221123 27.0405C-0.0465029 27.5912 0.0453478 28.1498 0.286794 28.6502C0.800528 29.7239 1.89544 30.3904 3.14522 30.3904H28.8644C30.1083 30.3904 31.1957 29.7247 31.7111 28.656C31.9536 28.1554 32.0463 27.5964 31.9783 27.0451C31.9103 26.4938 31.6843 25.9735 31.3273 25.5457ZM16.0002 37C17.2034 36.999 18.3839 36.6751 19.4165 36.0625C20.449 35.4499 21.2952 34.5715 21.8653 33.5205C21.8921 33.4701 21.9054 33.4137 21.9038 33.3568C21.9021 33.2998 21.8857 33.2443 21.856 33.1955C21.8264 33.1467 21.7845 33.1063 21.7345 33.0783C21.6844 33.0503 21.628 33.0357 21.5705 33.0357H10.4316C10.3741 33.0355 10.3175 33.0501 10.2673 33.078C10.2172 33.106 10.1752 33.1463 10.1454 33.1951C10.1157 33.2439 10.0991 33.2996 10.0975 33.3566C10.0958 33.4136 10.1091 33.4701 10.136 33.5205C10.706 34.5714 11.552 35.4497 12.5844 36.0623C13.6169 36.6749 14.7972 36.9989 16.0002 37Z"
        fill="white"
      />
    </SvgIcon>
  );
};

const NotificationPopover = ({
  notifications,
  popoverParameters = '32px',
}: any) => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const anchorRef = useRef(null);
  const { onClearAll, onCloseNotificate, open, onOpenNotificate } =
    NotificationModule();

  const renderClearAllIcon: JSX.Element = (
    <Tooltip
      title="Clear All"
      onClick={onClearAll}
      sx={mobileSM ? { marginLeft: 'auto', marginRight: '-100px' } : {}}
    >
      <IconButton>
        <ClearAllOutlinedIcon sx={{ color: '#fff' }} />
      </IconButton>
    </Tooltip>
  );

  const renderClose: JSX.Element = (
    <IconButton sx={closeStyles} onClick={onCloseNotificate}>
      <CloseIcon />
    </IconButton>
  );

  const renderDesktopVersion: JSX.Element = (
    <PopoverLayout
      title="Notifications"
      open={open}
      anchorRef={anchorRef}
      onClose={onCloseNotificate}
      maxWidth={350}
      injectIcon={notifications?.resource?.length > 0 && renderClearAllIcon}
      sx={{
        h4: {
          padding: '20px',
        },
        maxHeight: '500px',
      }}
      isNotification
    >
      {notifications?.resource?.length > 0 ? (
        notifications.resource.map((notification: any) => (
          <NotificationItem
            notification={notification}
            key={notification.postedAt}
          />
        ))
      ) : (
        <Flexbox margin="20px 20px 15px 20px">No new notifications</Flexbox>
      )}
    </PopoverLayout>
  );

  const renderMobileVersion: JSX.Element = (
    <ModalWindow
      title="Notifications"
      open={open}
      onClose={onCloseNotificate}
      fields={
        notifications?.resource?.length > 0 ? (
          notifications.resource.map((notification: any) => (
            <NotificationItem
              notification={notification}
              key={notification.postedAt}
            />
          ))
        ) : (
          <Flexbox margin="10px" sx={{ color: '#fff' }}>
            No new notifications
          </Flexbox>
        )
      }
      supportIcon={renderClose}
      buttons={null}
      icon={notifications?.resource?.length > 0 && renderClearAllIcon}
    />
  );

  return (
    <Fragment>
      <IconButton ref={anchorRef} onClick={onOpenNotificate}>
        <Badge color="error" badgeContent={0} sx={{ position: 'relative' }}>
          <NotificationsIcon
            fontSize="small"
            sx={{
              color: '#0e1016',
              width: popoverParameters,
              height: popoverParameters,
            }}
          />
          <StyledNotificateCounter>
            {notifications.unread}
          </StyledNotificateCounter>
        </Badge>
      </IconButton>

      {mobileSM ? renderMobileVersion : renderDesktopVersion}
    </Fragment>
  );
};

export default NotificationPopover;
