/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, CSSProperties } from 'react';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';

import CloseIcon from '@mui/icons-material/Close';

import { GridFieldSet, Textfield } from 'components/controls';
import { ModalWindow } from 'components/window';

import TInfoValues from 'features/profile/models/initialValues';
import useProfile from 'features/profile/redux/ProfileHook';

import INITIAL_INFO_VALUES from 'features/profile/core/initialValues';
import { TPopupProps } from 'core/types';
import AppConfig from 'config';
import ProfileSchema from 'features/profile/validation/Schema';
import FollowButton from 'features/home/components/followButton/FollowButton';
import useMediaMatches from 'hooks/useMediaMatches';
import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import Flexbox from 'components/flexbox/Flexbox';
import { IconButton } from '@mui/material';

const closeStyles: CSSProperties & Record<string, any> = {
  color: '#fff',
  marginLeft: 'auto',
  svg: { width: '30px', height: '30px' },
};

const InfoPopups = ({
  title,
  data,
  open,
  onClose,
}: TPopupProps): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);
  const InitValues: TInfoValues = {
    name: data?.name || INITIAL_INFO_VALUES.name,
    currentPosition:
      data?.currentPosition || INITIAL_INFO_VALUES.currentPosition,
    email: data?.email || '',
    linkedLink: data?.linkedLink || INITIAL_INFO_VALUES.linkedLink,
    industry: data?.industry || INITIAL_INFO_VALUES.industry,
    phoneNumber: data?.phoneNumber || INITIAL_INFO_VALUES.phoneNumber,
    currentCompany: data?.currentCompany || INITIAL_INFO_VALUES.currentCompany,
  };

  const [initFormik, setInitFormik] = useState<TInfoValues>(InitValues);
  const { dispatcherEditUserProfile, dispatchSetFetcherUser } = useProfile();

  /**
   * *@Fetcher functions
   */
  const fetcherEditProfile = (formData: TInfoValues) => {
    const payload: any = {
      data: formData,
    };

    dispatcherEditUserProfile(payload)
      .unwrap()
      .then((response: any) => {
        toast.success(AppConfig.NOTIFICATION.SUCCESS_EDIT, { duration: 3000 });
        resetForm();
        onClose();
        dispatchSetFetcherUser(true);
      })
      .catch((error: AxiosError) => {
        if (error && error.response && error.response.data) {
          const message = error.response.data;
          toast.error(message.toString());
        } else {
          toast.error(AppConfig.NOTIFICATION.FAILED);
        }
      });
  };

  const { values, handleChange, handleSubmit, resetForm, errors, touched } =
    useFormik({
      initialValues: initFormik,
      enableReinitialize: true,
      validationSchema: ProfileSchema,
      onSubmit: (values) => {
        fetcherEditProfile(values);
      },
    });

  /**
   * ?Side Effect for immediately reinitialize
   */
  useEffect(() => {
    setInitFormik(InitValues);
  }, [open, data]);

  /**
   * *@Renders functions
   */
  const renderFields: JSX.Element = (
    <Fragment>
      {mobileSM && <div style={{ width: '100%', height: '20px' }} />}
      <GridFieldSet label="Full Name">
        <Textfield
          value={values.name}
          name="name"
          onChange={handleChange}
          error={Boolean(errors.name && touched.name)}
          helperText={touched.name && errors.name}
          type="text"
          sx={{ marginTop: mobileSM ? '5px' : '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label="Job Title">
        <Textfield
          value={values.currentPosition}
          name="currentPosition"
          onChange={handleChange}
          type="text"
          error={Boolean(errors.currentPosition && touched.currentPosition)}
          helperText={touched.currentPosition && errors.currentPosition}
          sx={{ marginTop: mobileSM ? '5px' : '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label={AppConfig.PROFILE.FIELDS.INDUSTRY}>
        <Textfield
          value={values.industry}
          name="industry"
          onChange={handleChange}
          error={Boolean(errors.industry && touched.industry)}
          helperText={touched.industry && errors.industry}
          sx={{ marginTop: mobileSM ? '5px' : '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label="Email">
        <Textfield
          value={values.email}
          name="email"
          onChange={handleChange}
          error={Boolean(errors.email && touched.email)}
          helperText={touched.email && errors.email}
          sx={{ marginTop: mobileSM ? '5px' : '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label="Phone No (optional)">
        <Textfield
          value={values.phoneNumber}
          name="phoneNumber"
          onChange={handleChange}
          // error={Boolean(errors.phoneNumber && touched.phoneNumber)}
          // helperText={touched.phoneNumber && 'Field must contain only numbers'}
          type="text"
          sx={{ marginTop: mobileSM ? '5px' : '10px' }}
        />
      </GridFieldSet>

      <GridFieldSet label={AppConfig.PROFILE.FIELDS.COMPANY}>
        <Textfield
          value={values.currentCompany}
          name="currentCompany"
          onChange={handleChange}
          type="text"
          error={Boolean(errors.currentCompany && touched.currentCompany)}
          helperText={touched.currentCompany && errors.currentCompany}
          sx={{ marginTop: mobileSM ? '5px' : '10px' }}
        />
      </GridFieldSet>
    </Fragment>
  );

  const renderFormButtons: JSX.Element = mobileSM ? (
    <Flexbox width="100%" flexDirection="column" mt="-10px">
      <FollowButton
        label="Save"
        onClick={handleSubmit}
        overrideStyles={{ fontSize: '12px' }}
      />
      <FollowButton
        label="Cancel"
        onClick={onClose}
        success
        overrideStyles={{ fontSize: '12px' }}
      />
    </Flexbox>
  ) : (
    <Fragment>
      <FollowButton label="Cancel" onClick={onClose} success />
      <FollowButton label="Save" onClick={handleSubmit} />
    </Fragment>
  );

  return (
    <ModalWindow
      title={title}
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      fields={renderFields}
      buttons={renderFormButtons}
      supportIcon={
        <IconButton sx={closeStyles} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export default InfoPopups;
