import { Modal, Grid } from '@mui/material';

import Flexbox from 'components/flexbox/Flexbox';
import ModalCardRoot, { StyledScrollbar } from './ModalWindow.styled';
import { Heading } from 'components/typography';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import TModalWindowProps from './types';

import useMediaMatches from 'hooks/useMediaMatches';

const getWindowHeight = (sm: boolean, m: boolean, lg: boolean): string => {
  if (sm) return '400px';
  if (m) return '470px';
  if (lg) return '500px';

  return 'unset';
};

const ModalWindow = ({
  title,
  open,
  onSubmit,
  onClose,
  fields,
  buttons,
  disablePortal,
  keepMounted,
  icon,
  supportIcon,
}: TModalWindowProps): JSX.Element => {
  const [mobileSM, laptopHeight, desktopHeight] = useMediaMatches(
    MEDIA_QUERIES_DICTIONARY.MOBILE_SM,
    MEDIA_QUERIES_DICTIONARY.LAPTOP_HEIGHT,
    MEDIA_QUERIES_DICTIONARY.DESKTOP_HEIGHT,
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal={disablePortal}
      keepMounted={keepMounted}
    >
      <ModalCardRoot
        sx={{
          display: !open ? 'none' : 'block',
          width: mobileSM ? '90%' : '100%',
          padding: mobileSM ? '20px 15px' : '1.5rem',
        }}
      >
        <Flexbox alignItems="center" gap="10px">
          <Heading
            as="h2"
            content={title}
            sx={{ color: '#fff', fontSize: mobileSM ? '18px' : '30px' }}
          />
          {icon}
          {supportIcon}
        </Flexbox>

        <form onSubmit={onSubmit}>
          <StyledScrollbar
            className="simplebar"
            autoHide
            style={{
              maxHeight: getWindowHeight(mobileSM, laptopHeight, desktopHeight),
            }}
          >
            <Grid container mt={mobileSM ? 0 : 4}>
              {fields}
            </Grid>
          </StyledScrollbar>

          <Flexbox justifyContent="space-between" marginTop="0" gap="20px">
            {buttons}
          </Flexbox>
        </form>
      </ModalCardRoot>
    </Modal>
  );
};

export default ModalWindow;
