import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';
import { Box } from '@mui/material';

import MobileTripDetailsRoot from 'features/home/components/mobileTripDetail/styled';
import { Text } from 'features/trip/components/tripLayout/TripLayout';
import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';
import Info from '../info/Info';

import { TRIP_TYPE_DICTIONARY } from 'core/status';

import FollowersIcon from 'assets/icons/FollowersIcon';

const infoStyles: CSSProperties = {
  marginLeft: '0',
  fontSize: '12px',
};

const DataSetItem = ({ data }: { data: any }): JSX.Element => {
  const dateRange: string = `${data?.dateFrom} - ${data?.dateTo}`;
  const tripId = data.id;

  const navigate: NavigateFunction = useNavigate();
  const handleNavigateToTrip = (): void => {
    const url = `/trip/${tripId}`;
    navigate(url);
  };

  const renderName =
    data?.type === TRIP_TYPE_DICTIONARY.conference ? (
      <Info
        label="Conference Name"
        value={data?.conferenceName}
        overrideStyles={infoStyles}
        mt="2px"
      />
    ) : (
      <Info
        label="Activity Name"
        value={data?.conferenceName}
        overrideStyles={infoStyles}
        mt="2px"
      />
    );

  const renderTitle: JSX.Element = (
    <Flexbox alignItems="center" gap="20px">
      <Heading as="h4" content={data?.name} fontWeight="400" fontSize="15px" />
      <Flexbox
        alignItems="center"
        gap="6px"
        sx={{ svg: { width: '15px', height: '15px' } }}
      >
        <FollowersIcon />
        <Text sx={{ color: '#E8A442', cursor: 'pointer', fontSize: '12px' }}>
          ({data?.followersCount})
        </Text>
      </Flexbox>
    </Flexbox>
  );

  const renderContent: JSX.Element = (
    <Flexbox mt="5px" justifyContent="space-between" alignItems="center">
      <Box>
        <Info
          label="Trip Dates"
          value={dateRange}
          overrideStyles={infoStyles}
          mt="2px"
        />
        {renderName}
      </Box>

      <ArrowForwardIosIcon
        sx={{ width: '30px', height: '30px' }}
        onClick={handleNavigateToTrip}
      />
    </Flexbox>
  );

  return (
    <MobileTripDetailsRoot>
      {renderTitle}
      {renderContent}
    </MobileTripDetailsRoot>
  );
};

export default DataSetItem;
