import { createSlice } from '@reduxjs/toolkit';

import userProfileAction, { userGetTripsAction } from './ProfileActions';

import { PROFILE } from './ProfileConstants';

import TInitUserProfile from './ProfileTypes';

const initialState: TInitUserProfile = {
  info: null,
  fetchUser: false,
  trips: null,
};

const profileSlice = createSlice({
  name: PROFILE,
  initialState,
  reducers: {
    setUserFetching: (
      state: TInitUserProfile,
      action: { payload: boolean; type: string },
    ) => {
      state.fetchUser = action.payload;
    },
  },
  extraReducers: {
    [userProfileAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [userProfileAction.fulfilled.type]: (state, action) => {
      state.info = action.payload;
    },
    [userProfileAction.rejected.type]: (state) => {
      state.info = null;
    },
    [userGetTripsAction.pending.type]: (state) => {
      state.trips = null;
    },
    [userGetTripsAction.fulfilled.type]: (state, action) => {
      state.trips = action.payload;
    },
    [userGetTripsAction.rejected.type]: (state) => {
      state.trips = null;
    },
  },
});

export const { setUserFetching } = profileSlice.actions;
export default profileSlice.reducer;
