import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip } from '@mui/material';
import { Fragment } from 'react';

import Flexbox from 'components/flexbox/Flexbox';
import { Heading } from 'components/typography';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import TGridFieldSetProps from './types';

import useMediaMatches from 'hooks/useMediaMatches';

const GridFieldSet = ({
  label,
  children,
  className,
  help,
  light,
}: TGridFieldSetProps) => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  return (
    <Grid item xs={12} mb={mobileSM ? '10px' : '20px'} className={className}>
      <Fragment>
        <Flexbox alignItems="center" gap="0 6px">
          <Heading
            as="h4"
            content={label}
            fontWeight="500"
            fontSize={mobileSM ? '13px' : '16px'}
            color={light ? '#000' : '#fff'}
          />
          {help ? (
            <Tooltip title="The exact location of your destination is optional. Minimum requirement is choosing a country">
              <HelpOutlineIcon
                sx={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                  color: '#fff',
                }}
              />
            </Tooltip>
          ) : null}
        </Flexbox>
        {children}
      </Fragment>
    </Grid>
  );
};

export default GridFieldSet;
