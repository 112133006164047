import { AuthLayout, HomeLayout, MainLayout } from '../layouts';

import UserConfirmation from 'pages/UserConfirmation';
import LinkedInCallback from 'pages/LinkedInCallback';
import AdminDashboard from 'pages/AdminDashboard';
import PreviewProfile from 'pages/PreviewProfile';
import AdminLogin from 'pages/AdminLogin';
import UserLogin from 'pages/UserLogin';
import Profile from 'pages/Profile';
import Home from '../pages/Home';
import Trip from 'pages/Trip';

import * as Routes from './core/RouterConstant';

import TRouter from './models/RouterTypes';

const routes: Array<TRouter> = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: Routes.USER_LOGIN_ROUTE,
        element: <UserLogin />,
      },
      {
        path: Routes.USER_CONFIRMATION_ROUTE,
        element: <UserConfirmation />,
      },
      {
        path: Routes.USER_LINKEDIN_LOGIN_ROUTE,
        element: <LinkedInCallback />,
      },
      {
        path: Routes.ADMIN_LOGIN_ROUTE,
        element: <AdminLogin />,
      },
    ],
  },
  {
    path: Routes.HOME_ROUTE,
    element: (
      <MainLayout>
        <HomeLayout isFiltering isTripMapping>
          <Home />
        </HomeLayout>
      </MainLayout>
    ),
  },
  {
    path: Routes.TRIP_ROUTE,
    element: (
      <MainLayout>
        <HomeLayout isFiltering={false} isTripMapping={false} isReturnHome>
          <Trip />
        </HomeLayout>
      </MainLayout>
    ),
  },
  {
    path: Routes.PROFILE_ROUTE,
    element: (
      <MainLayout>
        <HomeLayout isFiltering={false} isTripMapping={false} isReturnHome>
          <Profile />
        </HomeLayout>
      </MainLayout>
    ),
  },
  {
    path: Routes.PROFILE_PREVIEW_ROUTE,
    element: (
      <MainLayout>
        <HomeLayout isFiltering={false} isTripMapping={false} isReturnHome>
          <PreviewProfile />
        </HomeLayout>
      </MainLayout>
    ),
  },
  {
    path: Routes.ADMIN_DASHBOARD_ROUTE,
    element: (
      <MainLayout>
        <HomeLayout isFiltering={false} isTripMapping={false} isReturnHome>
          <AdminDashboard />
        </HomeLayout>
      </MainLayout>
    ),
  },
];

export default routes;
