import { Box, styled } from '@mui/material';

const Fontbox = styled(Box)(() => ({
  textTransform: 'none',
  whiteSpace: 'normal',
  marginTop: 0,
  marginBottom: 0,
})) as typeof Box;

export default Fontbox;
