import { Grid } from '@mui/material';

import StyledForm from './Form.styled';

import TForm from './types';

const Form = ({
  children,
  onSubmit,
  noValidate = false,
}: TForm): JSX.Element => {
  return (
    <StyledForm onSubmit={onSubmit} noValidate={noValidate}>
      <Grid container>{children}</Grid>
    </StyledForm>
  );
};

export default Form;
