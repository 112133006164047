import { Autocomplete } from '@react-google-maps/api';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { CSSProperties, Fragment } from 'react';
import dayjs from 'dayjs';

import ActivityRadioGroup from 'features/home/components/activityRadioGroup/ActivityRadioGroup';
import FollowButton from 'features/home/components/followButton/FollowButton';
import { DatePick, GridFieldSet, Textfield } from 'components/controls';
import Flexbox from 'components/flexbox/Flexbox';
import { ModalWindow } from 'components/window';

import useMediaMatches from 'hooks/useMediaMatches';
import { useSidebar } from 'features/home';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import TSortingsProps from './types';
import AppConfig from 'config';

import { UTCFormatter } from 'utils/Formatter';
import { TRIP_TYPE_DICTIONARY } from 'core/status';

const closeStyles: CSSProperties & Record<string, any> = {
  color: '#fff',
  marginLeft: 'auto',
  svg: { width: '30px', height: '30px' },
};

const ListFilters = ({
  values,
  title,
  open,
  onClose,
  handleChange,
  onSubmit,
  setFieldValue,
  addressRef,
  conferenceState,
  handleChangeConferenceState,
}: TSortingsProps): JSX.Element => {
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  const { dispatcherHandleAddTrip } = useSidebar();

  const handleChangeDateFrom = (date: any): void => {
    if (date[0]) {
      const utcStart: any = UTCFormatter(date[0]);
      setFieldValue('dateFrom', utcStart);
      values.dateFrom = utcStart;
    }

    if (date[1]) {
      const utcEnd: string | null = UTCFormatter(date[1]);
      setFieldValue('dateTo', utcEnd);
      values.dateTo = utcEnd;
    }

    if (!date[1] && !date[2]) {
      setFieldValue('dateFrom', '');
      values.dateFrom = '';
      setFieldValue('dateTo', '');
      values.dateTo = '';
    }
  };

  const handleAddressChange = () => {
    setFieldValue('address', addressRef?.current?.value);
  };

  const handleAddTrip = (): void => {
    onClose();
    dispatcherHandleAddTrip(true);
  };

  const renderSortingFields: JSX.Element = (
    <Fragment>
      <Box mb="20px" mt={mobileSM ? '30px' : '0px'}>
        <DatePick
          value={[
            values.dateFrom ? dayjs(values.dateFrom, 'DD/MM/YYYY') : '',
            values.dateTo ? dayjs(values.dateTo, 'DD/MM/YYYY') : '',
          ]}
          onChange={handleChangeDateFrom}
          error={false}
        />
      </Box>

      <GridFieldSet label="">
        <ActivityRadioGroup
          activeState={conferenceState}
          onChange={handleChangeConferenceState}
        />
        <Textfield
          name="conferenceName"
          placeholder={
            conferenceState === TRIP_TYPE_DICTIONARY.conference
              ? 'Conference Name'
              : 'Other Activity'
          }
          value={values.conferenceName}
          onChange={handleChange}
          type="text"
        />
      </GridFieldSet>

      <GridFieldSet
        label={AppConfig.PLACEHOLDERS.ADDRESS}
        className="autocomplete-relative"
      >
        <Autocomplete>
          <input
            className="autocomplete-input"
            onChange={handleAddressChange}
            value={addressRef?.current?.value || values?.address}
            placeholder="Type to Search"
            ref={addressRef}
            type="text"
          />
        </Autocomplete>
      </GridFieldSet>
    </Fragment>
  );

  const renderMobileFilterButtons: JSX.Element = (
    <Fragment>
      <Flexbox flexDirection="column" width="100%">
        <FollowButton
          overrideStyles={{
            fontSize: '15px',
            padding: '8px',
          }}
          label="Search"
          onClick={onSubmit}
        />
        <FollowButton
          overrideStyles={{
            fontSize: '15px',
            padding: '8px',
            marginTop: '12px',
          }}
          label="Create a Trip"
          success
          onClick={handleAddTrip}
        />
      </Flexbox>
    </Fragment>
  );

  const renderFormButtons: JSX.Element = (
    <Flexbox alignItems="center" gap="20px">
      <FollowButton label="Cancel" onClick={onClose} success />
      <FollowButton onClick={onSubmit} label="Apply" />
    </Flexbox>
  );

  const renderDesktopVersion: JSX.Element = (
    <Box p="14px 40px 14px 40px">
      {renderSortingFields}
      {renderFormButtons}
    </Box>
  );

  const renderMobileVersion: JSX.Element = (
    <ModalWindow
      open={open}
      onClose={onClose}
      title="Trip list filters"
      buttons={renderMobileFilterButtons}
      fields={renderSortingFields}
      supportIcon={
        <IconButton sx={closeStyles} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    />
  );

  return open ? mobileSM ? renderMobileVersion : renderDesktopVersion : <div />;
};

export default ListFilters;
