import { get_thunk, post_thunk } from 'api/API';

import {
  ADMIN_CHANGE_STATUS,
  ADMIN_DASHBOARD_INFO,
  ADMIN_DOWNLOAD_REPORT,
  ADMIN_GET_TRIPS,
  ADMIN_GET_USER_PROFILE,
  ADMIN_HIDE_TRIP,
  ADMIN_REMOVE_TRIP,
} from './AdminDashboardConstants';
import {
  ADMIN_PROFILE_ENDPOINT,
  ADMIN_REPORT_ENDPOINT,
  ADMIN_TRIPS_ENDPOINT,
  CHANGE_TRIP_STATUS_ENDPOINT,
  DELETE_TRIP_ENDPOINT,
  HIDE_TRIP_ENDPOINT,
  USER_PROFILE_FOR_ADMIN_ENDPOINT,
} from 'api/Endpoints';

const adminProfileAction = get_thunk(
  ADMIN_DASHBOARD_INFO,
  ADMIN_PROFILE_ENDPOINT,
  false,
);

const adminHideTripAction = get_thunk(
  ADMIN_HIDE_TRIP,
  HIDE_TRIP_ENDPOINT,
  false,
);

const adminRemoveTripAction = get_thunk(
  ADMIN_REMOVE_TRIP,
  DELETE_TRIP_ENDPOINT,
  false,
);

const adminGetTripsAction = get_thunk(
  ADMIN_GET_TRIPS,
  ADMIN_TRIPS_ENDPOINT,
  false,
);

const adminDownloadReportAction = get_thunk(
  ADMIN_DOWNLOAD_REPORT,
  ADMIN_REPORT_ENDPOINT,
  false,
);

const adminGetUserProfile = get_thunk(
  ADMIN_GET_USER_PROFILE,
  USER_PROFILE_FOR_ADMIN_ENDPOINT,
  false,
);

const adminChangeStatusActions = get_thunk(
  ADMIN_CHANGE_STATUS,
  CHANGE_TRIP_STATUS_ENDPOINT,
  false,
);

export {
  adminHideTripAction,
  adminRemoveTripAction,
  adminGetTripsAction,
  adminDownloadReportAction,
  adminGetUserProfile,
  adminChangeStatusActions,
};
export default adminProfileAction;
