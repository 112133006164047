import UploadButton from './styled';

const ImageUploader = ({ onUpload }: any): JSX.Element => {
  return (
    <label htmlFor="icon-button-file">
      <input
        type="file"
        accept="image/*"
        id="icon-button-file"
        onChange={onUpload}
        style={{
          display: 'none',
        }}
      />

      <UploadButton>Upload Image</UploadButton>
    </label>
  );
};

export default ImageUploader;
