import { createSlice } from '@reduxjs/toolkit';

import TInitState from './MapTypes';

import { MAP } from './MapConstants';

const initialState: TInitState = {
  success: null,
  fetchCoords: false,
  clearFilter: false,
  filters: { monthCount: 1 },
  mapState: {
    filterCoordinates: null,
    filterZooming: null,
    fetching: null,
  },
  searchAddress: null,
  industries: null,
  fetchNotifications: false,
};

const mapSlice = createSlice({
  name: MAP,
  initialState,
  reducers: {
    setCoordsFetching: (
      state: TInitState,
      action: { payload: boolean; type: string },
    ) => {
      state.fetchCoords = action.payload;
    },
    setMapStateFetching: (
      state: TInitState,
      action: { payload: any; type: string },
    ) => {
      state.mapState = {
        filterCoordinates: action.payload?.filterCoordinates,
        filterZooming: action.payload?.filterZooming,
        fetching: action.payload?.fetching,
      };
    },
    setIndustriesRedux: (
      state: TInitState,
      action: { payload: any; type: string },
    ) => {
      state.industries = action.payload?.industries;
    },
    setFetchNotifications: (
      state: TInitState,
      action: { payload: boolean; type: string },
    ) => {
      state.fetchNotifications = action.payload;
    },
    setMapFilters: (
      state: TInitState,
      action: { payload: Record<string, any>; type: string },
    ) => {
      state.filters = action.payload;
    },
    setSearchAddress: (
      state: TInitState,
      action: { payload: any; type: string },
    ) => {
      state.searchAddress = action.payload;
    },
    setClearFilter: (
      state: TInitState,
      action: { payload: any; type: string },
    ) => {
      state.clearFilter = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setCoordsFetching,
  setMapFilters,
  setMapStateFetching,
  setIndustriesRedux,
  setFetchNotifications,
  setSearchAddress,
  setClearFilter,
} = mapSlice.actions;
export default mapSlice.reducer;
