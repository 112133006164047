import { Avatar, styled, Badge, IconButton } from '@mui/material';

import palette from 'theme/theme';

const ImageAvatarRoot = styled(Avatar)(() => ({
  height: '100px',
  width: '100px',
})) as typeof Avatar;

const MobilemageAvatarRoot = styled(Avatar)(() => ({
  width: '100%',
  height: '175px',
  borderRadius: '6px',
})) as typeof Avatar;

const AvatarBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    width: 35,
    height: 35,
    borderRadius: '50%',
    backgroundColor: palette.black[100],
    boxShadow: '0 0 0 4px #fff',
  },
})) as typeof Badge;

const AvatarIconButton = styled(IconButton)(() => ({
  height: '3rem',
  width: '3rem',
})) as typeof IconButton;

export { AvatarBadge, AvatarIconButton, MobilemageAvatarRoot };
export default ImageAvatarRoot;
