import { Navigate } from 'react-router-dom';
import { Paper } from '@mui/material';

import Wrapper from 'components/wrapper/Wrapper';
import Flexbox from 'components/flexbox/Flexbox';
import { LoginForm } from 'features/adminLogin';
import { Heading } from 'components/typography';

import useMediaMatches from 'hooks/useMediaMatches';

import MEDIA_QUERIES_DICTIONARY from 'core/mediaQueries';
import { HOME_ROUTE } from 'routes/core/RouterConstant';
import LocaleStorage from 'api/LocaleStorage';
import AppConfig from 'config';

const AdminLogin = (): JSX.Element => {
  const accessToken = LocaleStorage.get('accessToken');
  const [mobileSM] = useMediaMatches(MEDIA_QUERIES_DICTIONARY.MOBILE_SM);

  return !accessToken ? (
    <Wrapper xs={12} sm={8} md={5} component={Paper} elevation={3} item square>
      <Flexbox
        flexDirection="column"
        alignItems="center"
        p={mobileSM ? '20px' : '2rem 3rem'}
        width="100%"
      >
        <Heading as="h2" content={AppConfig.NAMES.LOGIN} fontWeight="600" />
        <LoginForm />
      </Flexbox>
    </Wrapper>
  ) : (
    <Navigate to={HOME_ROUTE} />
  );
};

export default AdminLogin;
